import { useEffect, useMemo, useCallback } from 'react';
import {
	Autocomplete,
	InputAdornment,
	TextField,
	ListItem,
	ListItemIcon,
	ListSubheader,
	CircularProgress,
	Divider,
	ListItemText,
	Popper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import {
	looksLikeCoordinates,
	convertCoordinatesToMapboxLocation,
	isValidCoordinates
} from 'utils/mapHelpers';
import { searchMapBox } from 'apis/mapbox.api';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PinMarkerIcon } from 'assets/pin-marker-black.svg';
import { locationDetailsSelector, userCoordinatesSelector } from 'slices/mapSlice';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';

const LocationSearchBox = ({
	onSelect,
	inputPlaceholder = '',
	recentSearches,
	variant = 'standard',
	height = 32,
	bottomBorder = false,
	width = 'inherit'
}) => {
	const styles = {
		autoComplete: {
			height: `${height}px`,
			display: 'flex',
			alignItems: 'center'
		}
	};

	const CustomPopper = function (props) {
		return <Popper {...props} style={{ width }} />;
	};

	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [
		currentLocationAndRecentSearchOptions,
		setCurrentLocationAndRecentSearchOptions
	] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [loading, setLoading] = useState(false);

	const options = searchResults.length > 0 ? searchResults : currentLocationAndRecentSearchOptions;

	const userLocation = useSelector(userCoordinatesSelector);
	const locationDetails = useSelector(locationDetailsSelector);
	const lat = locationDetails?.geometry?.coordinates[1] || null;
	const long = locationDetails?.geometry?.coordinates[0] || null;

	const handleOpen = () => {
		setOpen(true);
		handleClearValues();
	};

	const handleClose = () => {
		setOpen(false);
		handleClearValues();
	};

	const handleClearValues = () => {
		setSearchValue('');
		setSearchResults([]);
	};

	const handleSelect = (e, value) => {
		handleClearValues();
		if (value) {
			onSelect(value);
			setCurrentLocationAndRecentSearchOptions([]);
		}
	};

	const handleSearchMapBox = useCallback(
		async value => {
			setLoading(true);
			const res = await dispatch(searchMapBox({ searchTerm: value, lat, long }));
			if (res?.features) {
				setSearchResults(res.features);
				setLoading(false);
			}
		},
		[dispatch, lat, long]
	);

	//Debounce the search so that it doesn't call the Mapbox endpoint on every keydown event
	const mapBoxSearchDebounced = useMemo(() => debounce(handleSearchMapBox, 300), [
		handleSearchMapBox
	]);

	const getSearchResults = useCallback(
		searchTerm => {
			const userSearchedCoordinates = looksLikeCoordinates(searchTerm);
			//Display lat & long as a search result if the user enters coordinates
			if (userSearchedCoordinates) {
				const lat = searchTerm.split(',')[0];
				const long = searchTerm.split(',')[1];
				if (isValidCoordinates(lat, long)) {
					const coordinatesOption = convertCoordinatesToMapboxLocation({
						lat,
						long,
						optionType: 'userEnteredCoordinates'
					});
					setSearchResults([coordinatesOption]);
				}
			} else {
				//Otherwise search Mapbox for the text entered
				mapBoxSearchDebounced(searchTerm);
			}
		},
		[mapBoxSearchDebounced]
	);

	const onSearch = e => {
		setSearchValue(e.target.value);
	};

	const renderOption = (props, option) => {
		let icon;
		switch (option.optionType) {
			case 'userLocation':
			default:
				icon = <PinMarkerIcon />;
				break;
		}
		return (
			<ListItem dense key={option.id} {...props}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText>{option.place_name}</ListItemText>
			</ListItem>
		);
	};

	const renderGroup = params => {
		let label = '';
		switch (params.group) {
			case 'userLocation':
				label = 'CURRENT LOCATION';
				break;
			default:
				label = 'SEARCH RESULTS';
				break;
		}
		return [<ListSubheader key={params.key}>{label}:</ListSubheader>, params.children];
	};

	useEffect(() => {
		//Listen for changes to userLocation or recentSearches and update the list of options in local state
		let updatedOptions = [];
		if (userLocation) {
			updatedOptions = [userLocation];
		}
		if (recentSearches) {
			updatedOptions = [...updatedOptions, ...recentSearches];
		}
		setCurrentLocationAndRecentSearchOptions(updatedOptions);
	}, [userLocation, recentSearches]);

	useEffect(() => {
		//Perform search when the searchValue changes
		if (searchValue) {
			getSearchResults(searchValue);
		}
	}, [searchValue, getSearchResults]);

	return (
		<>
			<Autocomplete
				sx={styles.autoComplete}
				id='mapbox-search'
				autoComplete
				blurOnSelect
				clearOnBlur
				loading={loading}
				open={open}
				onOpen={handleOpen}
				onClose={handleClose}
				getOptionLabel={option => option.place_name || ''}
				onChange={handleSelect}
				options={options}
				noOptionsText={'No options'}
				filterOptions={option => option}
				groupBy={option => option.optionType}
				renderGroup={renderGroup}
				PopperComponent={CustomPopper}
				renderInput={params => {
					return (
						<TextField
							{...params}
							onClick={handleOpen}
							variant={variant}
							fullWidth
							placeholder={inputPlaceholder}
							onChange={onSearch}
							InputProps={{
								...params.InputProps,
								inputProps: {
									...params.inputProps,
									value: searchValue
								},
								startAdornment: (
									<InputAdornment position='start' sx={{ paddingLeft: '10px' }}>
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: bottomBorder && true,
								endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>
							}}
						/>
					);
				}}
				renderOption={renderOption}
			/>
			{bottomBorder && <Divider />}
		</>
	);
};

LocationSearchBox.propTypes = {
	onSelect: PropTypes.func.isRequired,
	inputPlaceholder: PropTypes.string,
	recentSearches: PropTypes.arrayOf(PropTypes.object),
	variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
	height: PropTypes.number,
	width: PropTypes.number,
	bottomBorder: PropTypes.bool
};

export default LocationSearchBox;
