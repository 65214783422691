import { Mapbox } from 'services/mapboxConfig';
import { useEffect } from 'react';
import {
	addMapControls,
	createTenthMileBboxFromPoint,
	featureCollectionFeaturesAreDefined,
	fillPaint,
	lineLayout,
	linePaint
} from 'utils/mapHelpers';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { getMapStyleFromLS, saveMapStyleToLS } from 'actions/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import {
	centerMapOnLocationDetailsSelector,
	featureCollectionSelector,
	locationDetailsSelector,
	mapCenterSelector,
	setCenterMapOnLocationDetails,
	setFeatureCollection,
	setMapCenter,
	setRadiusObject,
	setSelectedFeatures,
	setUpdateMapFromFile,
	updateMapFromFileSelector,
	userCoordinatesSelector,
	userLocationLoadingSelector
} from 'slices/mapSlice';
import { LinearProgress } from '@mui/material';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { editingAdvisorySelector } from 'slices/advisoriesSlice';
import { Layer, Source, Marker } from 'react-mapbox-gl';
import { directSelectMode } from 'constants/generalConstants';
import mapPin from 'assets/mapPin.png';

const MapEditable = ({ map, setMap, setDrawMode, drawObject, drawMode }) => {
	const dispatch = useDispatch();
	const mapStyle = getMapStyleFromLS();
	const mapContainerStyle = { height: '100%', width: '100%' };
	const userCoordinates = useSelector(userCoordinatesSelector);
	const userLocationLoading = useSelector(userLocationLoadingSelector);
	const mapCenter = useSelector(mapCenterSelector);
	const featureCollection = useSelector(featureCollectionSelector);
	const featuresAreDefined = featureCollectionFeaturesAreDefined(featureCollection);
	const updateMapFromFile = useSelector(updateMapFromFileSelector);
	const centerMapOnLocationDetails = useSelector(centerMapOnLocationDetailsSelector);
	const editingAdvisory = useSelector(editingAdvisorySelector);
	const sourceId = 'advisorySource';
	const locationDetails = useSelector(locationDetailsSelector);

	const handleUpdateRadius = fc => {
		const firstSelectedFeature = fc.features[0];
		const isCircle = firstSelectedFeature?.properties?.isCircle;
		if (isCircle) {
			//Update radius in redux store for display in radius input
			dispatch(
				setRadiusObject({
					[firstSelectedFeature.id]: firstSelectedFeature.properties.radiusInFeet
				})
			);
		}
	};

	const updateFeatures = (e, drawObj) => {
		const updated = e;
		handleUpdateRadius(updated);
		const all = drawObj.getAll();
		dispatch(setFeatureCollection(all));
	};

	const createFeatures = (e, drawObj) => {
		const newFeatureCollection = e;
		handleUpdateRadius(newFeatureCollection);
		const all = drawObj.getAll();
		dispatch(setFeatureCollection(all));
	};

	const changeSelection = async (e, drawObj) => {
		const selected = drawObj.getSelected();
		if (selected.features.length > 0) {
			handleUpdateRadius(selected);
		}
		dispatch(setSelectedFeatures(e.features));
	};

	const onModeChange = (e, drawObj) => {
		if (e.mode !== directSelectMode) {
			//Exclude directSelectMode because it is not one of the draw tool buttons. It will only ever be set by clicking a drawing.
			setDrawMode(e.mode);
		}
	};

	const onStyleLoad = map => {
		addMapControls({ map, mapboxgl });
		map.addControl(drawObject, 'top-right');
		map.on('draw.create', e => createFeatures(e, drawObject));
		map.on('draw.update', e => updateFeatures(e, drawObject));
		map.on('draw.selectionchange', e => changeSelection(e, drawObject));
		map.on('draw.modechange', e => onModeChange(e, drawObject));
		setMap(map);
	};

	const onDragEnd = async map => {
		const center = map.getCenter();
		const { lat, lng } = center;
		const newCenter = [lng, lat];
		if (newCenter !== mapCenter) {
			await dispatch(setMapCenter(newCenter));
		}
	};

	useEffect(() => {
		return () => {
			//Clear map in state when this component unmounts so that when this component re-mounts
			// it will not trigger the useEffects that listen to map until after onStyleLoad sets the new map in state
			setMap(null);
		};
	}, [setMap]);

	useEffect(() => {
		//Syncs featureCollection from redux store and drawings on map
		//Also syncs drawings between the small and large maps
		//Render as mapbox draw editable layer only if the feature was drawn by the user, not uploaded (id is fromFile if it was uploaded)
		if (map && featureCollection && drawObject && featureCollection.id !== 'fromFile') {
			drawObject.set(featureCollection);
		}
	}, [featureCollection, map, drawObject]);

	useEffect(() => {
		//Moves map to the user's location once geolocation returns
		if (
			map &&
			userCoordinates?.lat &&
			userCoordinates?.long &&
			!featuresAreDefined &&
			!locationDetails &&
			!editingAdvisory
		) {
			const { lat, long } = userCoordinates;
			dispatch(setMapCenter([long, lat]));
			const userLocationBBox = createTenthMileBboxFromPoint([long, lat]);
			map.fitBounds(userLocationBBox, { padding: 50, duration: 0 });
		}
	}, [userCoordinates, dispatch, featuresAreDefined, map, editingAdvisory, locationDetails]);

	useEffect(() => {
		//Moves map the to location of the uploaded feature
		if (featuresAreDefined && map && updateMapFromFile) {
			const featureCollectionCenter = center(featureCollection);
			dispatch(setMapCenter(featureCollectionCenter.geometry.coordinates));
			const bounds = bbox(featureCollection);
			map.fitBounds(bounds, { padding: 50, duration: 0 });
			dispatch(setUpdateMapFromFile(false));
		}
	}, [featuresAreDefined, featureCollection, map, dispatch, updateMapFromFile]);

	useEffect(() => {
		//Moves map the to location of the uploaded feature
		if (featuresAreDefined && map) {
			const featureCollectionCenter = center(featureCollection);
			dispatch(setMapCenter(featureCollectionCenter.geometry.coordinates));
			const bounds = bbox(featureCollection);
			map.fitBounds(bounds, { padding: 50, duration: 0 });
		}
	}, [featuresAreDefined, featureCollection, map, dispatch]);

	useEffect(() => {
		//Moves map to the location of locationDetails (user's location or the location in the search box)
		//Only when centerMapOnLocationDetails is true so that otherwise the map will be centered on the featureCollection
		if (map && locationDetails && centerMapOnLocationDetails) {
			const lat = locationDetails.geometry.coordinates[1];
			const long = locationDetails.geometry.coordinates[0];
			const center = [long, lat];
			dispatch(setMapCenter(center));
			dispatch(setCenterMapOnLocationDetails(false));
		}
	}, [map, locationDetails, dispatch, centerMapOnLocationDetails]);

	useEffect(() => {
		//Ensures the the map drawing state matches the drawing tools button state
		//Sets the map's draw mode (drawObject.mode) to equal the drawMode from redux (which controls the draw tools button state)
		if (map && drawObject) {
			drawObject.changeMode(drawMode);
		}
	}, [drawMode, map, drawObject]);

	return (
		<>
			{userLocationLoading && <LinearProgress />}
			<Mapbox
				onStyleData={saveMapStyleToLS}
				style={`mapbox://styles/mapbox/${mapStyle}`}
				containerStyle={mapContainerStyle}
				center={mapCenter}
				movingMethod='jumpTo'
				onStyleLoad={onStyleLoad}
				onDragEnd={onDragEnd}
			>
				{featureCollection.id === 'fromFile' && (
					<>
						<Source
							id={sourceId}
							geoJsonSource={{
								type: 'geojson',
								data: featureCollection
							}}
						/>
						<Layer
							type='line'
							id='boundary'
							sourceId={sourceId}
							layout={lineLayout}
							paint={linePaint}
						/>
						<Layer type='fill' id='area' sourceId={sourceId} paint={fillPaint} />
					</>
				)}
				{locationDetails && (
					<Marker coordinates={locationDetails.geometry.coordinates}>
						<img src={mapPin} alt='map-pin' />
					</Marker>
				)}
			</Mapbox>
		</>
	);
};

MapEditable.propTypes = {
	map: PropTypes.object,
	setMap: PropTypes.func.isRequired,
	setDrawMode: PropTypes.func.isRequired,
	drawObject: PropTypes.object.isRequired,
	drawMode: PropTypes.string.isRequired
};

export default MapEditable;
