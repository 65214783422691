import { MAPBOX_KEY } from 'constants/environmentVariables';
import axiosInstance from 'services/axiosConfig';
import { setLocationDetails, setLocationDetailsLoading } from 'slices/mapSlice';
import { convertMapboxLocationToPointFeature } from 'utils/mapHelpers';

// fetch marker location details
export const fetchLocationByCoord = location => async dispatch => {
	dispatch(setLocationDetailsLoading(true));
	const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=${MAPBOX_KEY}`;
	try {
		const response = await axiosInstance.get(url);
		dispatch(setLocationDetailsLoading(false));
		const locationDetails = convertMapboxLocationToPointFeature(response?.data.features[0]);
		dispatch(setLocationDetails(locationDetails));
	} catch (errors) {
		console.error('errors', errors);
	}
};

export const searchMapBox = ({ searchTerm, long, lat }) => async dispatch => {
	let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
		searchTerm
	)}.json?access_token=${MAPBOX_KEY}`;
	if (lat && long) {
		url = url.concat(`&proximity=${long},${lat}`);
	}
	try {
		const response = await axiosInstance.get(url);
		return response.data;
	} catch (errors) {
		console.error('errors', errors);
	}
};
