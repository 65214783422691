import { useState } from 'react';
import { TextField, Button, LinearProgress, Typography, Link, Grid } from '@mui/material';
import { authenticationPagesStyles } from 'constants/styles';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { validateEmailRegex, validateSixDigitCode } from 'utils/validateForm';
import ErrorMessage from 'utils/errorMessages';
import { objectHasKeys } from 'utils/helpers';
import { setAlertMessage } from 'slices/globalSlice';
import AloftLogo from 'assets/aloft_logo_white.png';
import { signUpEmailSelector } from 'slices/authenticationSlice';
import ResendCodeEmailInput from 'components/authentication/ResendCodeEmailInput';
import AuthenticationFooter from 'components/authentication/AuthenticationFooter';
import CompanyLogos from 'components/authentication/CompanyLogos';
import { confirmSignUp, resendSignUpCode } from 'apis/authentication.api';
import { TOKEN } from 'constants/localStorageConstants';

const styles = theme => ({
	...authenticationPagesStyles(theme),
	resendButton: {
		height: 19,
		fontSize: 16,
		textTransform: 'none'
	},
	resendSection: {
		marginTop: 40,
		'& > div:first-child': {
			marginBottom: 10
		}
	}
});

const ConfirmSignUp = () => {
	const history = useHistory();
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showLoader, setShowLoader] = useState(false);
	const [showResendCodeLoader, setShowResendCodeLoader] = useState(false);
	const signUpEmail = useSelector(signUpEmailSelector);
	const [showResendCodeEmailInput, setShowResendCodeEmailInput] = useState(false);
	const [showResendCodeButton, setShowResendCodeButton] = useState(true);

	const token = localStorage.getItem(TOKEN);
	if (token) {
		//Redirect to root if user is logged in. This will then redirect to /portal or /admin
		history.push('/');
	}

	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues
	} = useForm({
		defaultValues: {
			code: '',
			email: signUpEmail
		}
	});

	const handleConfirmSignUp = async () => {
		dispatch(setAlertMessage({}));
		setShowLoader(true);
		const { email, code } = getValues();
		const payload = {
			email: email,
			verification_code: code
		};
		const res = await dispatch(confirmSignUp(payload));
		// The response was successful redirect to signup
		if (res !== undefined) {
			setShowLoader(false);
			history.push('sign_in');
		} else {
			setShowLoader(false);
		}
	};

	const hasErrors = objectHasKeys(errors);

	const handleClickLogin = () => {
		history.push(`/sign_in`);
	};

	const handleResendCode = async () => {
		setShowResendCodeLoader(true);
		const { email } = getValues();
		const payload = {
			email
		};
		await dispatch(resendSignUpCode(payload));
		setShowResendCodeLoader(false);
	};

	const handleRequestResend = async () => {
		setShowResendCodeButton(false);
		const { email } = getValues();
		if (email) {
			await handleResendCode();
		} else {
			setShowResendCodeEmailInput(true);
		}
	};

	return (
		<div className={classes.main}>
			<div className={classes.headerContainer}>
				<img src={AloftLogo} alt='' onClick={handleClickLogin} className={classes.logo} />
				<Link
					href='https://www.aloft.ai/support/geo-portal/'
					target='_blank'
					rel='noopener noreferrer'
					underline='none'
				>
					<Button className={classes.outlineButton} variant='outlined'>
						Frequently Asked Questions
					</Button>
				</Link>
			</div>
			<div className={classes.mainContainer}>
				<div className={classes.authLogosContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} className={classes.leftContainer}>
							<div>
								{/* <div className={classes.signIn}>Verification for</div> */}
								<div className={classes.geoPortal}>Aloft Geo Portal</div>
								<Typography className={classes.authCopy}>
									Almost done! Before you can sign in,
									<br />
									your new account needs to be verified.
								</Typography>
							</div>
							<div>
								<Typography className={classes.authCopy}>
									Need a new verification code?
									{showResendCodeButton && (
										<span className={classes.actionLink} onClick={handleRequestResend}>
											Resend code!
										</span>
									)}
								</Typography>
								{showResendCodeLoader && (
									<>
										<Typography className={classes.authCopy}>Resending code</Typography>
										<LinearProgress />
									</>
								)}
								{showResendCodeEmailInput && (
									<ResendCodeEmailInput
										setShowResendCodeLoader={setShowResendCodeLoader}
										showResendCodeLoader={showResendCodeLoader}
									/>
								)}
							</div>
							<Typography className={classes.authCopy}>Trusted by:</Typography>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.rightContainer}>
							<form onSubmit={handleSubmit(handleConfirmSignUp)}>
								<div elevation={0} className={classes.outlineContainer}>
									<div className={classes.formTitle}>
										Verify Account
										{showLoader && <LinearProgress />}
									</div>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Email'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												autoFocus
											/>
										)}
										name='email'
										control={control}
										rules={{
											required: true,
											validate: {
												emailAddress: validateEmailRegex
											}
										}}
									/>
									<ErrorMessage name='email' error={errors.email} className={classes.error} />

									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Code'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												autoFocus
											/>
										)}
										name='code'
										control={control}
										rules={{
											required: true,
											validate: {
												sixDigitCode: validateSixDigitCode
											}
										}}
									/>
									<ErrorMessage name='code' error={errors.code} className={classes.error} />
									<Button
										color='primary'
										className={classes.button}
										variant='contained'
										id='signUp'
										type='submit'
										disabled={showLoader || hasErrors}
									>
										Submit
									</Button>
								</div>
								<div className={classes.belowSignIn}>
									<Typography sx={{ textAlign: 'center' }}>
										If you already have an account you can
										<span className={classes.actionLink} onClick={handleClickLogin}>
											Sign in here!
										</span>
									</Typography>
								</div>
							</form>
						</Grid>
					</Grid>
					<CompanyLogos />
				</div>
			</div>
			<AuthenticationFooter />
		</div>
	);
};

export default ConfirmSignUp;
