import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	confirmDeleteMessage,
	confirmFlagMessage,
	confirmPublishMessage,
	confirmUnflagMessage,
	confirmUnpublishMessage,
	verifiedStatus
} from 'constants/generalConstants';
import { every } from 'lodash-es';
import TableActionsEl from 'components/elements/TableActionsEl';
import ConfirmPopover from 'components/elements/ConfirmPopover';
import PropTypes from 'prop-types';
import { deleteAdvisory, toggleFlagAdvisory, togglePublishAdvisory } from 'apis/advisories.api';
import { profileSelector } from 'slices/profileSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';

//The anchor ref needs to be in the same file as the TableActionsEl and the ConfirmPopover in order for the popover to be placed correctly

const AdvisoryTableActions = ({ afterAction, selectedItems, admin }) => {
	const [confirmActionAnchorEl, setConfirmActionAnchorEl] = useState(null);
	const confirmActionPopupOpen = Boolean(confirmActionAnchorEl);
	const [actionCallback, setActionCallback] = useState(null);
	const [actionText, setActionText] = useState({ title: '', subtitle: '', confirmText: '' });
	const [actionLoading, setActionLoading] = useState(false);
	const [actionsAnchorEl, setActionsAnchorEl] = useState(false);
	const anchor = useRef();
	const dispatch = useDispatch();
	const profile = useSelector(profileSelector);
	const accountVerified = profile?.account_status === verifiedStatus;

	const handleBulkTogglePublish = async () => {
		setActionLoading(true);
		for (const advisory of selectedItems) {
			await dispatch(togglePublishAdvisory(advisory.id, !advisory.is_published));
		}
		setActionLoading(false);
		closeConfirmPopupAndActionsMenu();
		afterAction();
	};

	const handleBulkToggleFlag = async () => {
		setActionLoading(true);
		for (const advisory of selectedItems) {
			await dispatch(toggleFlagAdvisory(advisory.id, !advisory.flag_status));
		}
		setActionLoading(false);
		closeConfirmPopupAndActionsMenu();
		afterAction();
	};

	const handleBulkDelete = async () => {
		setActionLoading(true);
		for (const advisory of selectedItems) {
			await dispatch(deleteAdvisory(advisory.id));
		}
		setActionLoading(false);
		closeConfirmPopupAndActionsMenu();
		afterAction();
	};

	const closeConfirmPopupAndActionsMenu = () => {
		setConfirmActionAnchorEl(null);
		handleCloseActionsMenu();
	};

	let advisoryTableActions = [];
	if (accountVerified) {
		advisoryTableActions.push({
			label: 'Publish',
			icon: <VisibilityIcon />,
			onClick: e => {
				setConfirmActionAnchorEl(e.currentTarget);
				setActionText({
					title: 'Publish Advisories',
					subtitle: confirmPublishMessage,
					confirmText: 'Publish'
				});
				setActionCallback(() => handleBulkTogglePublish);
			},
			disabled:
				selectedItems.length === 0 || !every(selectedItems, a => !a.is_published && !a.flag_status),
			disabledLabel: '(select 1 or more unpublished and unflagged advisories)'
		});
		advisoryTableActions.push({
			label: 'Unpublish',
			icon: <VisibilityOffIcon />,
			onClick: e => {
				setConfirmActionAnchorEl(e.currentTarget);
				setActionText({
					title: 'Unpublish Advisories',
					subtitle: confirmUnpublishMessage,
					confirmText: 'Unpublish'
				});
				setActionCallback(() => handleBulkTogglePublish);
			},
			disabled: selectedItems.length === 0 || !every(selectedItems, a => a.is_published),
			disabledLabel: '(select 1 or more published advisories)'
		});
	}

	if (admin) {
		advisoryTableActions.push({
			label: 'Flag',
			icon: <FlagIcon />,
			onClick: e => {
				setConfirmActionAnchorEl(e.currentTarget);
				setActionText({
					title: 'Flag Advisories',
					subtitle: confirmFlagMessage,
					confirmText: 'Flag'
				});
				setActionCallback(() => handleBulkToggleFlag);
			},
			disabled: selectedItems.length === 0 || !every(selectedItems, a => !a.flag_status),
			disabledLabel: '(select 1 or more unflagged advisories)'
		});
		advisoryTableActions.push({
			label: 'Unflag',
			icon: <FlagIcon sx={{ transform: 'rotate(90deg)' }} />,
			onClick: e => {
				setConfirmActionAnchorEl(e.currentTarget);
				setActionText({
					title: 'Unflag Advisories',
					subtitle: confirmUnflagMessage,
					confirmText: 'Unflag'
				});
				setActionCallback(() => handleBulkToggleFlag);
			},
			disabled: selectedItems.length === 0 || !every(selectedItems, a => a.flag_status),
			disabledLabel: '(select 1 or more flagged advisories)'
		});
	}

	advisoryTableActions.push({
		label: 'Delete',
		icon: <DeleteIcon />,
		onClick: e => {
			setConfirmActionAnchorEl(e.currentTarget);
			setActionText({
				title: 'Delete Advisories',
				subtitle: confirmDeleteMessage,
				confirmText: 'Delete'
			});
			setActionCallback(() => handleBulkDelete);
		},
		disabled: selectedItems.length === 0,
		disabledLabel: '(select 1 or more advisories)'
	});

	const handleClickActionsButton = () => {
		setActionsAnchorEl(anchor.current);
	};

	const handleCloseActionsMenu = () => {
		setActionsAnchorEl(null);
	};

	return (
		<>
			<TableActionsEl
				actions={advisoryTableActions}
				anchor={anchor}
				handleClick={handleClickActionsButton}
				handleClose={handleCloseActionsMenu}
				open={Boolean(actionsAnchorEl)}
				anchorEl={actionsAnchorEl}
			/>
			<ConfirmPopover
				open={confirmActionPopupOpen}
				anchorEl={confirmActionAnchorEl}
				title={actionText.title}
				subTitle={actionText.subtitle}
				onCancel={closeConfirmPopupAndActionsMenu}
				onConfirm={actionCallback}
				cancelText={'Cancel'}
				confirmText={actionText.confirmText}
				loading={actionLoading}
				list
				items={selectedItems.map(a => ({ primary: a.title, secondary: a.advisory_details }))}
			/>
		</>
	);
};

AdvisoryTableActions.propTypes = {
	selectedItems: PropTypes.array.isRequired,
	afterAction: PropTypes.func.isRequired,
	admin: PropTypes.bool
};

export default AdvisoryTableActions;
