import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const classStyles = {
	contentStyle: {
		fontSize: '24px',
		lineHeight: '26px',
		textAlign: 'left',
		fontWeight: 600
	}
};

function LargeTitle(props) {
	const { content, textColor, gutterBottom = false, styles = {} } = props;
	return (
		<Typography
			color={textColor}
			sx={classStyles.contentStyle}
			gutterBottom={gutterBottom}
			style={styles}
		>
			{content}
		</Typography>
	);
}

LargeTitle.propTypes = {
	content: PropTypes.string,
	textColor: PropTypes.string,
	gutterBottom: PropTypes.bool,
	styles: PropTypes.object
};

export default LargeTitle;
