export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// Mapbox
export const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY;

export const MUI_KEY = process.env.REACT_APP_MUI_KEY;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
