import { Box, Button, Input, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import AddIcon from '@mui/icons-material/Add';
import PanToolIcon from '@mui/icons-material/PanTool';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
	featureCollectionSelector,
	radiusObjectSelector,
	selectedFeaturesSelector,
	setFeatureCollection,
	setRadiusObject,
	setSelectedFeatures
} from 'slices/mapSlice';
import InputLabel from 'components/elements/InputLabel';
import {
	circleDrawMode,
	directSelectMode,
	polygonDrawMode,
	rectangleDrawMode,
	simpleSelectMode
} from 'constants/generalConstants';
import { clearFeatureLayer, convertFeetToMiles, emptyFeatureCollection } from 'utils/mapHelpers';
import circle from '@turf/circle';
import { filter, round } from 'lodash-es';
import * as turf from '@turf/helpers';
import { useState } from 'react';
import ConfirmPopover from 'components/elements/ConfirmPopover';
import PropTypes from 'prop-types';

const MapDrawControls = ({ drawMode, setDrawMode, map, drawObject, id }) => {
	const dispatch = useDispatch();
	const selectedFeatures = useSelector(selectedFeaturesSelector);
	const singleFeatureSelected = selectedFeatures.length === 1;
	const firstSelectedFeature = selectedFeatures[0];
	const circleSelected = singleFeatureSelected && firstSelectedFeature.properties.isCircle;
	const featureCollection = useSelector(featureCollectionSelector);
	const featuresAreDefined = featureCollection?.features?.length > 0;
	const radiusObject = useSelector(radiusObjectSelector);
	const [warningAnchorEl, setWarningAnchorEl] = useState(null);
	const [onConfirm, setOnConfirm] = useState(() => {});

	const styles = {
		buttonsContainer: {
			display: 'flex',
			paddingBottom: '20px',
			paddingTop: '20px',
			alignItems: 'center',
			justifyContent: 'space-between',
			gridGap: '20px'
		},
		stackedIcons: {
			height: '24px',
			width: '24px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		hexagonIcon: {
			position: 'relative',
			left: '8px'
		},
		addIcon: {
			fontSize: '16px',
			position: 'relative',
			left: '-12px'
		},
		radiusInputGroup: {
			width: '153px',
			display: 'flex',
			gridGap: '10px',
			alignItems: 'center'
		},
		radiusInput: {
			width: '75px',
			'& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
				appearance: 'none',
				margin: 0
			}
		}
	};

	const handleCircleClick = () => {
		setDrawMode(circleDrawMode);
		drawObject.changeMode(circleDrawMode);
	};

	const handleSquareClick = () => {
		setDrawMode(rectangleDrawMode);
		drawObject.changeMode(rectangleDrawMode);
	};

	const handlePolygonClick = () => {
		setDrawMode(polygonDrawMode);
		drawObject.changeMode(polygonDrawMode);
	};

	const handleSelectClick = () => {
		setDrawMode(simpleSelectMode);
		drawObject.changeMode(simpleSelectMode);
	};

	const handleRequestDrawMode = (e, callback) => {
		const confirmAction = () => {
			setWarningAnchorEl(null);
			dispatch(setSelectedFeatures([]));
			dispatch(setFeatureCollection(emptyFeatureCollection));
			clearFeatureLayer(map);
			callback();
		};
		if (featureCollection.id === 'fromFile') {
			setWarningAnchorEl(e.currentTarget);
			setOnConfirm(() => confirmAction);
		} else {
			callback();
		}
	};

	const handleChangeRadius = e => {
		const updatedRadiusInFeet = Number(e.target.value);
		const currentCenter = firstSelectedFeature.properties?.center;
		const radiusInMiles = convertFeetToMiles(updatedRadiusInFeet);
		const updatedWithNewRadius = circle(currentCenter, radiusInMiles, {
			units: 'miles',
			properties: { isCircle: true, center: currentCenter, radiusInFeet: updatedRadiusInFeet }
		});
		const updatedFeature = {
			...updatedWithNewRadius,
			id: firstSelectedFeature.id
		};
		let currentFeaturesWithoutUpdated;
		if (featureCollection?.features?.length > 1) {
			currentFeaturesWithoutUpdated = filter(featureCollection.features, x => {
				return x.id !== updatedFeature.id;
			});
		} else {
			currentFeaturesWithoutUpdated = [];
		}
		const updatedFeatureCollection = turf.featureCollection([
			...currentFeaturesWithoutUpdated,
			updatedFeature
		]);
		dispatch(setFeatureCollection(updatedFeatureCollection));
		dispatch(
			setRadiusObject({
				[firstSelectedFeature.id]: updatedRadiusInFeet
			})
		);
	};

	const handleDeleteSelectedFeature = () => {
		setDrawMode(simpleSelectMode);
		const selected = drawObject.getSelectedIds();
		drawObject.delete(selected);
		const updated = drawObject.getSelected();
		dispatch(setSelectedFeatures(updated));
		dispatch(setFeatureCollection(drawObject.getAll()));
		drawObject.changeMode(simpleSelectMode);
	};

	const handleDeleteAllFeatures = () => {
		setDrawMode(simpleSelectMode);
		drawObject.deleteAll();
		dispatch(setSelectedFeatures([]));
		dispatch(setFeatureCollection(emptyFeatureCollection));
	};

	return (
		<>
			<Box sx={styles.buttonsContainer}>
				<ConfirmPopover
					open={Boolean(warningAnchorEl)}
					anchorEl={warningAnchorEl}
					title={'Changing to draw mode will remove uploaded shapes.'}
					subTitle={'Do you want to continue?'}
					onCancel={() => setWarningAnchorEl(null)}
					onConfirm={onConfirm}
					cancelText={'Cancel'}
					confirmText={'Continue'}
				/>
				<ToggleButtonGroup exclusive value={drawMode}>
					<ToggleButton
						value={circleDrawMode}
						color='primary'
						onClick={e => handleRequestDrawMode(e, handleCircleClick)}
					>
						<AddCircleOutlineOutlinedIcon />
					</ToggleButton>
					<ToggleButton
						value={rectangleDrawMode}
						color='primary'
						onClick={e => handleRequestDrawMode(e, handleSquareClick)}
					>
						<AddBoxOutlinedIcon />
					</ToggleButton>

					<ToggleButton
						value={polygonDrawMode}
						color='primary'
						onClick={e => handleRequestDrawMode(e, handlePolygonClick)}
					>
						<Box sx={styles.stackedIcons}>
							<HexagonOutlinedIcon sx={styles.hexagonIcon} />
							<AddIcon sx={styles.addIcon} />
						</Box>
					</ToggleButton>
					<ToggleButton
						color='primary'
						value={simpleSelectMode || directSelectMode}
						onClick={handleSelectClick}
					>
						<PanToolIcon />
					</ToggleButton>
				</ToggleButtonGroup>

				<Box sx={styles.radiusInputGroup}>
					{circleSelected && (
						<>
							<InputLabel title={'RADIUS (FT)'} />
							<Input
								sx={styles.radiusInput}
								fullWidth
								value={round(radiusObject[firstSelectedFeature.id], 2)}
								type='number'
								min={0}
								max={100}
								onChange={handleChangeRadius}
							/>
						</>
					)}
				</Box>

				{selectedFeatures.length > 0 && featureCollection.is !== 'fromFile' && (
					<Button
						variant='outlined'
						onClick={handleDeleteSelectedFeature}
						startIcon={<DeleteIcon />}
					>
						Selected
					</Button>
				)}
				{featuresAreDefined && (
					<Button variant='outlined' onClick={handleDeleteAllFeatures} startIcon={<DeleteIcon />}>
						All
					</Button>
				)}
			</Box>
		</>
	);
};

MapDrawControls.propTypes = {
	drawMode: PropTypes.string.isRequired,
	setDrawMode: PropTypes.func.isRequired,
	map: PropTypes.object,
	drawObject: PropTypes.object.isRequired
};

export default MapDrawControls;
