import { createRoot } from 'react-dom/client';
import Root from 'components/Root';
import Routes from 'components/Routes';
import 'constants/styles/main.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { initSentry } from 'services/sentry';

initSentry();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Root>
		<Routes />
	</Root>
);
