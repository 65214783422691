import InputLabel from 'components/elements/InputLabel';
import { $black, $primary } from 'constants/styles';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const SectionListItem = ({
	labelLink,
	valueLink,
	emailLink,
	label,
	headerLabel = false,
	content,
	loading,
	plainTextTitle,
	valueColor
}) => {
	const styles = {
		common: {
			fontSize: '16px',
			lineHeight: '18px',
			wordBreak: 'break-word',
			width: '100%'
		},
		headerLabel: {
			color: $primary,
			fontSize: '19px',
			lineHeight: '22px',
			fontWeight: 700,
			textDecoration: 'underline',
			cursor: 'pointer',
			marginLeft: '30px',
			margin: '20px auto 5px'
		},
		content: {
			color: $black,
			margin: '5px 0px 10px 0px',
			minHeight: '18px'
		},
		linkElement: {
			textDecoration: 'none',
			cursor: 'pointer',
			color: $primary
		},
		valueColor: {
			color: valueColor ? valueColor : $black
		},
		skeleton2: {
			height: '30px'
		},
		loader: {
			width: '90%'
		}
	};

	const goToLink = (link, e) => {
		if (emailLink) {
			window.location = `mailto:${link}`;
		} else {
			window.open(link);
		}
	};

	const linkElement = (linkText, link, headerLabel) => {
		return (
			<Box
				sx={headerLabel ? styles.headerLabel : styles.linkElement}
				onClick={e => {
					e.persist();
					goToLink(link, e);
				}}
			>
				{linkText}
			</Box>
		);
	};

	return (
		<Box sx={styles.common}>
			{!loading && (
				<>
					{labelLink && !plainTextTitle && linkElement(label, labelLink, headerLabel)}
					{!labelLink && !plainTextTitle && <InputLabel title={label} />}
					{plainTextTitle && <Box>{label}</Box>}
					{!headerLabel && (
						<Box id='content' sx={styles.content}>
							{valueLink && linkElement(content, valueLink)}
							{!valueLink && <Box sx={styles.valueColor}>{content}</Box>}
						</Box>
					)}
				</>
			)}
			{loading && (
				<Box sx={styles.loader}>
					<Skeleton variant='text' animation='wave' />
					<Skeleton variant='text' animation='wave' sx={styles.skeleton2} />
				</Box>
			)}
		</Box>
	);
};

SectionListItem.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	labelLink: PropTypes.string,
	content: PropTypes.any,
	valueLink: PropTypes.string,
	headerLabel: PropTypes.bool,
	emailLink: PropTypes.bool,
	loading: PropTypes.bool,
	plainTextTitle: PropTypes.bool,
	valueColor: PropTypes.string
};

export default SectionListItem;
