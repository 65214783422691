import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { $btnBlack, $white } from 'constants/styles';

function ButtonContained(props) {
	const { onClick, children, fitContent, type, tabIndex, disabled, ...rest } = props;

	const btnStyles = {
		button: {
			fontSize: '14px',
			height: '40px',
			width: fitContent ? 'fit-content' : '150px',
			textTransform: 'none',
			fontWeight: 700,
			color: $white,
			backgroundColor: $btnBlack,
			border: `solid 1px ${$btnBlack}`,
			borderRadius: 0,
			'&:hover': {
				backgroundColor: $btnBlack,
				opacity: '.9',
				transition: 'opacity .2s ease-out'
			},
			'&.Mui-disabled': {
				border: 'none'
			}
		}
	};

	return (
		<Button
			onClick={onClick}
			sx={btnStyles.button}
			variant='contained'
			disabled={disabled}
			type={type}
			tabIndex={tabIndex}
			{...rest}
		>
			{children}
		</Button>
	);
}

ButtonContained.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.any,
	fitContent: PropTypes.bool,
	type: PropTypes.string,
	tabIndex: PropTypes.number,
	disabled: PropTypes.bool
};

export default ButtonContained;
