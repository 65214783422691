import {
	Box,
	Button,
	Divider,
	LinearProgress,
	Tooltip,
	Typography,
	Badge,
	Checkbox,
	Alert
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectedAdvisorySelector,
	setAdvisoriesLoaded,
	setEditingAdvisory,
	setSelectedAdvisory
} from 'slices/advisoriesSlice';
import LargeTitle from 'components/elements/LargeTitle';
import { $bluishGray60, $danger } from 'constants/styles';
import {
	getAdvisoryRestrictionLevelColor,
	getAdvisoryStatusColor,
	utcToLocalTime
} from 'utils/helpers';
import SectionListItem from 'components/elements/SectionListItem';
import SectionHeader from 'components/elements/SectionHeader';
import MapView from 'components/advisory/MapView';
import PropTypes from 'prop-types';
import { deleteAdvisory, toggleFlagAdvisory, togglePublishAdvisory } from 'apis/advisories.api';
import { closeAdvisoryDrawer } from 'actions/advisories.actions';
import ButtonRegular from 'components/elements/ButtonRegular';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import ButtonContained from 'components/elements/ButtonContained';
import { drawerLoadingSelector } from 'slices/globalSlice';
import ConfirmPopover from 'components/elements/ConfirmPopover';
import { useState } from 'react';
import { setFeatureCollection } from 'slices/mapSlice';
import { verifiedStatus } from 'constants/generalConstants';
import { profileSelector } from 'slices/profileSlice';
import LaunchIcon from '@mui/icons-material/Launch';
import center from '@turf/center';
import FlagIcon from '@mui/icons-material/Flag';
import InputLabel from 'components/elements/InputLabel';
import { confirmDeleteMessage } from 'constants/generalConstants';

const AdvisoryView = ({ admin = false }) => {
	const styles = {
		main: {
			height: '100vh',
			width: '100%',
			overflow: 'auto'
		},
		header: {
			padding: '25px'
		},
		statusContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		statusAndFlagsContainer: {
			display: 'flex',
			alignItems: 'center',
			gridGap: '20px',
			paddingTop: '10px'
		},
		statusLabel: {
			color: $bluishGray60,
			textTransform: 'uppercase',
			marginRight: '10px'
		},
		statusValue: {},
		buttonParentRow: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		buttonRow: {
			display: 'flex',
			justifyContent: 'flex-end',
			gridGap: '10px',
			marginBottom: '20px'
		},
		leftButton: {
			justifySelf: 'flex-start'
		},
		body: {
			padding: '25px',
			paddingBottom: '20px'
		},
		mapContainer: {
			paddingLeft: '25px',
			paddingRight: '25px',
			paddingBottom: '25px',
			height: 400,
			width: '100%'
		},
		loaderPlaceholder: {
			height: '4px'
		},
		closeButton: {
			textTransform: 'none'
		},
		section: {
			marginBottom: '30px'
		},
		times: {
			display: 'flex',
			gridGap: '20px'
		},
		b4uflyButton: {
			borderRadius: 0,
			marginBottom: '25px'
		},
		checkboxContainer: {
			display: 'flex',
			alignItems: 'center'
		}
	};

	const dispatch = useDispatch();

	const selectedAdvisory = useSelector(selectedAdvisorySelector);
	const drawerLoading = useSelector(drawerLoadingSelector);
	const profile = useSelector(profileSelector);
	const accountIsVerified = profile?.account_status === verifiedStatus;

	const [confirmFlagAnchorEl, setConfirmAnchorEl] = useState(null);
	const [confirmDeleteAnchorEl, setConfirmDeleteAnchorEl] = useState(null);
	const [confirmPopupLoading, setConfirmPopupLoading] = useState(false);
	const [confirmPublishAnchorEl, setConfirmPublishAnchorEl] = useState(null);
	const hasDates = selectedAdvisory?.start_time || selectedAdvisory?.end_time;

	const featureCollectionToDisplay = selectedAdvisory?.feature_collection_oversized
		? selectedAdvisory?.feature_collection_bounding_box
		: selectedAdvisory?.feature_collection;

	const handleClickEditAdvisory = () => {
		dispatch(setEditingAdvisory(true));
		dispatch(setFeatureCollection(featureCollectionToDisplay));
	};

	const handleRequestPublish = e => {
		setConfirmPublishAnchorEl(e.currentTarget);
	};

	const handleTogglePublish = async () => {
		setConfirmPopupLoading(true);
		const res = await dispatch(
			togglePublishAdvisory(selectedAdvisory?.id, !selectedAdvisory?.is_published)
		);
		setConfirmPopupLoading(false);
		if (res) {
			dispatch(setSelectedAdvisory(res));
			setConfirmPublishAnchorEl(null);
		}

		dispatch(setAdvisoriesLoaded(false)); //Trigger reload of table
	};

	const handleRequestDeleteAdvisory = e => {
		setConfirmDeleteAnchorEl(e.currentTarget);
	};

	const handleDeleteAdvisory = async () => {
		setConfirmPopupLoading(true);
		const res = await dispatch(deleteAdvisory(selectedAdvisory?.id));
		setConfirmPopupLoading(false);
		if (res) {
			dispatch(closeAdvisoryDrawer());
			dispatch(setAdvisoriesLoaded(false)); //Trigger reload of table
		}
	};

	const handleClickClose = () => {
		dispatch(closeAdvisoryDrawer());
	};

	const handleRequestFlag = e => {
		setConfirmAnchorEl(e.currentTarget);
	};

	const handleToggleFlag = async () => {
		setConfirmPopupLoading(true);
		const res = await dispatch(
			toggleFlagAdvisory(selectedAdvisory?.id, !selectedAdvisory?.flag_status)
		);
		setConfirmPopupLoading(false);
		if (res) {
			dispatch(setSelectedAdvisory(res));
		}
		setConfirmAnchorEl(null);
		dispatch(setAdvisoriesLoaded(false)); //Trigger reload of table
	};

	const goToB4UFLY = () => {
		const featureCenter = center(featureCollectionToDisplay);
		const featureCenterCoords = featureCenter.geometry.coordinates;
		const long = featureCenterCoords[0];
		const lat = featureCenterCoords[1];
		const url = `https://b4ufly.aloft.ai?lat=${lat}&long=${long}`;
		window.open(url);
	};

	const confirmFlagMessage =
		'Confirm status change from active to flagged, which will remove the advisory from view and notify the account holder.';
	const confirmUnflagMessage = 'Confirm status change from flagged to active.';

	const confirmUnpublishMessage =
		'Confirm status change from published to unpublished, which will remove the advisory from view.';
	const confirmPublishMessage =
		'Confirm status change from unpublished to published, which will cause the advisory to be viewable on the Aloft data network.';

	return (
		<Box sx={styles.main}>
			{drawerLoading ? <LinearProgress /> : <Box sx={styles.loaderPlaceholder} />}
			{!drawerLoading && (
				<>
					<Box sx={styles.header}>
						<Box sx={styles.buttonParentRow}>
							<Box sx={styles.buttonRow}>
								<ButtonOutlined
									styles={{ color: $danger, borderColor: $danger }}
									tabIndex={-1}
									fitContent
									onClick={handleRequestDeleteAdvisory}
								>
									DELETE
								</ButtonOutlined>
								<ButtonOutlined tabIndex={-1} fitContent onClick={handleClickEditAdvisory}>
									EDIT
								</ButtonOutlined>
								<Tooltip
									title={
										accountIsVerified
											? selectedAdvisory.flag_status
												? 'Advisory is flagged'
												: ''
											: 'Account not verified'
									}
								>
									<span>
										<ButtonContained
											tabIndex={-1}
											fitContent
											onClick={handleRequestPublish}
											disabled={!accountIsVerified || selectedAdvisory.flag_status}
										>
											{selectedAdvisory?.is_published ? 'UNPUBLISH' : 'PUBLISH'}
										</ButtonContained>
									</span>
								</Tooltip>
								{admin && (
									<ButtonContained tabIndex={-1} fitContent onClick={handleRequestFlag}>
										{selectedAdvisory?.flag_status ? 'UNFLAG' : 'FLAG'}
									</ButtonContained>
								)}
							</Box>

							<ButtonRegular tabIndex={-1} fitContent onClick={handleClickClose}>
								Close
							</ButtonRegular>
						</Box>
						<Box>
							<LargeTitle content={selectedAdvisory?.title} />
							<Box sx={styles.statusAndFlagsContainer}>
								{selectedAdvisory?.flags > 0 && (
									<Badge
										badgeContent={selectedAdvisory?.flags}
										color='error'
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right'
										}}
									>
										<FlagIcon />
									</Badge>
								)}
								<Box sx={styles.statusContainer}>
									<Typography variant='h6' sx={styles.statusLabel}>
										Status:
									</Typography>
									<Typography
										variant='h6'
										sx={{
											...styles.statusValue,
											color: getAdvisoryStatusColor(selectedAdvisory?.status)
										}}
									>
										{selectedAdvisory?.status}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					<Divider />
					<Box sx={styles.body}>
						<Box sx={styles.section}>
							<SectionHeader content={'Advisory Details'} />
							<SectionListItem
								label={'SUMMARY DETAIL'}
								content={selectedAdvisory?.advisory_details}
							/>
							<SectionListItem label={'ADVISORY TYPE'} content={selectedAdvisory?.advisory_type} />
							<SectionListItem
								label={'RESTRICTION LEVEL'}
								content={selectedAdvisory?.restriction_level}
								valueColor={getAdvisoryRestrictionLevelColor(selectedAdvisory?.restriction_level)}
							/>
							<SectionListItem
								label={'LINK TO OFFICIAL RULE'}
								content={selectedAdvisory?.official_rule_link}
								valueLink={selectedAdvisory?.official_rule_link}
							/>
							<SectionListItem
								label={'CONTACT PHONE NUMBER'}
								content={selectedAdvisory?.contact_phone_number}
							/>
							<SectionListItem
								label={'CONTACT EMAIL'}
								content={selectedAdvisory?.contact_email}
								emailLink
								valueLink={selectedAdvisory?.contact_email}
							/>
							<SectionListItem
								label={'CONTACT WEBSITE'}
								content={selectedAdvisory?.contact_website}
								valueLink={selectedAdvisory?.contact_website}
							/>
						</Box>
						<Box sx={styles.section}>
							<SectionHeader content={'Date and Time Range'} />
							{!hasDates && (
								<Box sx={styles.checkboxContainer}>
									<InputLabel title={'Permanent Advisory (Active when published)'} />
									<Checkbox disabled checked />
								</Box>
							)}
							{hasDates && (
								<Box sx={styles.times}>
									<SectionListItem
										label={'START DATE & TIME'}
										content={
											selectedAdvisory?.start_time
												? utcToLocalTime(selectedAdvisory?.start_time)
												: ''
										}
									/>
									<SectionListItem
										label={'END DATE & TIME'}
										content={
											selectedAdvisory?.end_time ? utcToLocalTime(selectedAdvisory?.end_time) : ''
										}
									/>
								</Box>
							)}
						</Box>
						<SectionHeader content={'Map Location'} />
						{selectedAdvisory?.feature_collection_oversized && (
							<Alert severity='info'>
								Your advisory is too big to preview here, so a bounding box is shown. Your advisory
								will display correctly in the Aloft Network.
							</Alert>
						)}
					</Box>
					<Box sx={styles.mapContainer}>
						{featureCollectionToDisplay && (
							<MapView featureCollection={featureCollectionToDisplay} />
						)}
						<Button
							sx={styles.b4uflyButton}
							fullWidth
							variant='contained'
							endIcon={<LaunchIcon />}
							onClick={goToB4UFLY}
						>
							View in Aloft Network
						</Button>
					</Box>
					<ConfirmPopover
						open={Boolean(confirmFlagAnchorEl)}
						anchorEl={confirmFlagAnchorEl}
						title='Advisory Status'
						subTitle={selectedAdvisory?.flag_status ? confirmUnflagMessage : confirmFlagMessage}
						onCancel={() => setConfirmAnchorEl(null)}
						onConfirm={handleToggleFlag}
						cancelText={'Cancel'}
						confirmText={'Confirm'}
						loading={confirmPopupLoading}
					/>
					<ConfirmPopover
						open={Boolean(confirmDeleteAnchorEl)}
						anchorEl={confirmDeleteAnchorEl}
						title='Delete Advisory'
						subTitle={confirmDeleteMessage}
						onCancel={() => setConfirmDeleteAnchorEl(null)}
						onConfirm={handleDeleteAdvisory}
						cancelText={'Cancel'}
						confirmText={'Delete'}
						loading={confirmPopupLoading}
					/>
					<ConfirmPopover
						open={Boolean(confirmPublishAnchorEl)}
						anchorEl={confirmPublishAnchorEl}
						title={selectedAdvisory?.is_published ? 'Unpublish Advisory' : 'Publish Advisory'}
						subTitle={
							selectedAdvisory?.is_published ? confirmUnpublishMessage : confirmPublishMessage
						}
						onCancel={() => setConfirmPublishAnchorEl(null)}
						onConfirm={handleTogglePublish}
						cancelText={'Cancel'}
						confirmText={selectedAdvisory?.is_published ? 'Unpublish' : 'Publish'}
						loading={confirmPopupLoading}
					/>
				</>
			)}
		</Box>
	);
};

AdvisoryView.propTypes = {
	admin: PropTypes.bool
};

export default AdvisoryView;
