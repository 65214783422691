import { createSlice } from '@reduxjs/toolkit';

const initialState = {}; //Set by configure store

const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		setUser(state, action) {
			state.user = action.payload;
		},
		setResetPasswordEmail(state, action) {
			state.resetPasswordEmail = action.payload;
		},
		setSignUpEmail(state, action) {
			state.signUpEmail = action.payload;
		}
	}
});

export const { setUser, setResetPasswordEmail, setSignUpEmail } = authenticationSlice.actions;

export default authenticationSlice.reducer;

export const userSelector = state => state.authentication.user;
export const resetPasswordEmailSelector = state => state.authentication.resetPasswordEmail;
export const signInSelector = state => state.authentication.user;
export const signUpEmailSelector = state => state.authentication.signUpEmail;
