import { Redirect, Route, withRouter } from 'react-router-dom';
import { TOKEN } from 'constants/localStorageConstants';

const PrivateRoute = ({ component: Component, history, ...rest }) => {
	const token = localStorage.getItem(TOKEN);
	return (
		<Route
			{...rest}
			render={props =>
				token ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/sign_in'
						}}
					/>
				)
			}
		/>
	);
};

export default withRouter(PrivateRoute);
