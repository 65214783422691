import { Box } from '@mui/material';
import LargeTitle from 'components/elements/LargeTitle';
import PropTypes from 'prop-types';

const Header = ({ button, title }) => {
	const styles = {
		main: {
			padding: '20px',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '80px'
		}
	};

	return (
		<Box sx={styles.main}>
			<LargeTitle content={title} />
			{button}
		</Box>
	);
};
Header.propTypes = {
	button: PropTypes.element,
	title: PropTypes.string
};

export default Header;
