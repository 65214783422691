// Taken from https://github.com/iamanvesh/mapbox-gl-draw-circle
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import { convertMilesToFeet } from 'utils/mapHelpers';

function enableZoom(context) {
	setTimeout(() => {
		// eslint-disable-next-line
        const store = context._ctx && context._ctx.store;
		const map = context.map && context.map;

		if (!map && !store.getInitialValue) {
			return;
		}

		if (!store.getInitialConfigValue('doubleClickZoom')) {
			return;
		}

		if (!store.getInitialConfigValue('dragPan')) {
			return;
		}

		map.doubleClickZoom.enable();
		map.dragPan.enable();
	}, 0);
}

function disableZoom(context) {
	setTimeout(() => {
		const { map } = context;
		const doubleClickZoom = map && map.doubleClickZoom;
		const dragPan = map && map.dragPan;

		if (!map || !doubleClickZoom || !dragPan) {
			return;
		}

		// Always disable here, as it's necessary in some cases.
		doubleClickZoom.disable();
		dragPan.disable();
	}, 0);
}

const DragCircleMode = {};

DragCircleMode.onSetup = function (opts) {
	const polygon = this.newFeature({
		type: 'Feature',
		properties: {
			isCircle: true,
			center: []
		},
		geometry: {
			type: 'Polygon',
			coordinates: []
		}
	});

	this.addFeature(polygon);
	this.clearSelectedFeatures();

	this.updateUIClasses({ mouse: 'add' });
	// this.activateUIButton('Polygon');
	this.setActionableState({
		trash: true
	});
	disableZoom(this);
	return {
		polygon,
		currentVertexPosition: 0
	};
};

DragCircleMode.onMouseDown = DragCircleMode.onTouchStart = function (state, e) {
	const currentCenter = state.polygon.properties.center;
	if (currentCenter.length === 0) {
		state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
	}
};

DragCircleMode.onDrag = DragCircleMode.onMouseMove = function (state, e) {
	const center = state.polygon.properties.center;
	if (center.length > 0) {
		const distanceInMiles = distance(point(center), point([e.lngLat.lng, e.lngLat.lat]), {
			units: 'miles'
		});
		const circleFeature = circle(center, distanceInMiles, { units: 'miles' });
		state.polygon.incomingCoords(circleFeature.geometry.coordinates);
		const distanceInFeet = convertMilesToFeet(distanceInMiles);
		state.polygon.properties.radiusInFeet = distanceInFeet;
	}
};

DragCircleMode.onMouseUp = DragCircleMode.onTouchEnd = function (state, e) {
	// dragPan.enable(this);
	enableZoom(this);
	this.changeMode('simple_select');
};

DragCircleMode.onClick = DragCircleMode.onTap = function (state, e) {
	// don't draw the circle if its a tap or click event
	state.polygon.properties.center = [];
};

DragCircleMode.onStop = function (state, e) {
	if (state.polygon.isValid()) {
		this.map.fire('draw.create', {
			features: [state.polygon.toGeoJSON]
		});
	}
	//Remove the feature from state if it wasn't valid or after draw.create has been called
	//Prevents multiple circles from being drawn
	this.deleteFeature([state.polygon.id], { silent: true });
};

DragCircleMode.toDisplayFeatures = function (state, geojson, display) {
	const isActivePolygon = geojson.properties.id === state.polygon.id;
	geojson.properties.active = isActivePolygon
		? Constants.activeStates.ACTIVE
		: Constants.activeStates.INACTIVE;
	if (state.polygon.properties.center.length > 0) return display(geojson);
	return display(geojson);
};

export default DragCircleMode;
