import { Box, Typography } from '@mui/material';

const Terms = () => {
	const styles = {
		container: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			padding: '50px',
			overflowY: 'auto'
		},
		body: { height: '100%', width: '100%', maxWidth: '1000px' },
		spacer: {
			height: '50px'
		}
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.body}>
				<Typography variant='h4' gutterBottom component='div' align='center'>
					Aloft Geo Portal Account Verification Policy
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					Last Update: May 23, 2022
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					For data uploaded to the Aloft Geo Portal to be published across the Aloft Drone Data
					Network, the user will need to be verified. To receive verification, your account must be{' '}
					<strong>accurate</strong> and <strong>authoritative</strong>.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					Accurate
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					To create trusted and reliable data, accounts must submit complete and accurate account
					information, including the following:
				</Typography>
				<Typography variant='body1' gutterBottom component='ul'>
					<Typography variant='body1' gutterBottom component='li'>
						Email addresses (that will be verified programmatically by submitting the correct
						activation code).
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Phone number.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						First and last name.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Organization name.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Organization website (that corresponds to the activated email address).
					</Typography>
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					If any of the above is missing, mismatched, or incorrect, the account will not be
					verified. The Aloft team may also reach out to the account owner with follow-up questions.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					If your account information ever changes, you must update it with your latest information.{' '}
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					Authoritative
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					Your account must represent or otherwise be associated with an entity with official data
					about the ability to operate drones (including small recreational drones, commercial
					drones, drone delivery, or drone air taxis). This data could include information about
					drone restrictions, safety issues, or intended areas for flight (such as parks, drone
					corridors, etc).
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					Categories of entities that fit this authoritative requirement include, but are not
					limited to the following:
				</Typography>
				<Typography variant='body1' gutterBottom component='ul'>
					<Typography variant='body1' gutterBottom component='li'>
						Federal, state, and local government agencies.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Law enforcement and first responders.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Airport and heliport operators.
					</Typography>
					<Typography variant='body1' gutterBottom component='li'>
						Commercial enterprises with public safety data (such as utilities).
					</Typography>
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					If you have any questions about your organization or its status, please contact{' '}
					<a href='mailto:geo@aloft.ai' target='_blank' rel='noreferrer'>
						geo@aloft.ai
					</a>
					.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					If you are an individual operator or otherwise do not meet these verification
					requirements, we encourage you to continue using our crowdsourcing data tools by
					submitting or flagging data on the Aloft Drone Data Network. You can do this directly from
					the B4UFLY mobile apps and{' '}
					<a href='https://b4ufly.aloft.ai' target='_blank' rel='noreferrer'>
						https://b4ufly.aloft.ai
					</a>
					.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					Loss of Verified Status
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					In accordance with the{' '}
					<a href='https://www.aloft.ai/terms' target='_blank' rel='noreferrer'>
						Aloft Terms of Use
					</a>{' '}
					and the{' '}
					<a href='https://geo.aloft.ai/terms' target='_blank' rel='noreferrer'>
						Geo Portal Terms and Conditions
					</a>
					, Aloft may remove the verified status of an account at any time without notice.
				</Typography>
				<Box sx={styles.spacer} />
			</Box>
		</Box>
	);
};

export default Terms;
