import { $black, $danger, $elevated, $primary, $success } from 'constants/styles';
import {
	formatDateToString,
	getAccountStatusColor,
	getAdvisoryRestrictionLevelColor,
	getAdvisoryRestrictionLevelObject,
	getAdvisoryStatusColor,
	getEmailVerificationStatusColor,
	utcToLocalTime
} from 'utils/helpers';
import dayjs from 'utils/customDayJS';
export const verifiedStatus = 'Verified';
export const rejectedStatus = 'Rejected';

export const accountStatuses = [
	{ value: 'Flagged', color: $danger, label: 'Flagged' },
	{ value: 'Pending', color: $elevated, label: 'Pending' },
	{ value: verifiedStatus, color: $primary, label: 'Verified' },
	{ value: rejectedStatus, color: $black, label: 'Rejected' }
];

export const advisoryStatuses = [
	{ value: 'Active', color: $black, label: 'Active' },
	{ value: 'Expired', color: $black, label: 'Expired' },
	{ value: 'Flagged', color: $danger, label: 'Flagged' },
	{ value: 'Pending', color: $elevated, label: 'Pending' }
];

export const restrictedValue = 'Restricted';

export const advisoryRestrictionLevels = [
	{ value: 'Clear', color: $success, label: 'Clear' },
	{ value: 'Caution', color: $elevated, label: 'Caution' },
	{ value: restrictedValue, color: $danger, label: 'Restricted' }
];

export const advisoryColorsAndLabels = [
	{ value: 'green', color: $success, label: 'Clear' },
	{ value: 'yellow', color: $elevated, label: 'Caution' },
	{ value: 'red', color: $danger, label: 'Restricted' }
];

export const emailVerificationStatuses = [
	{ value: true, color: $black, label: 'Verified' },
	{ value: false, color: $elevated, label: 'Pending' }
];

export const advisoriesColumns = [
	{
		width: 400,
		headerName: 'Advisory Title',
		field: 'title'
	},
	{
		width: 150,
		headerName: 'Status',
		field: 'status',
		renderCell: params => {
			const color = getAdvisoryStatusColor(params?.value);
			return <span style={{ color }}>{params?.value}</span>;
		}
	},
	{
		width: 150,
		headerName: 'Published',
		field: 'is_published',
		renderCell: params => {
			return (
				<span style={{ color: params.value ? $success : '' }}>{params.value ? 'Yes' : 'No'}</span>
			);
		}
	},
	{
		width: 225,
		headerName: 'Created On',
		field: 'created_date',
		valueGetter: params => {
			return formatDateToString(params.value); //Do not need to convert to UTC because the string returned from the api includes the "Z" for Zulu (UTC) time format.
		}
	},
	{
		width: 225,
		headerName: 'Last Updated',
		field: 'updated_at',
		valueGetter: params => {
			return formatDateToString(params.value); //Do not need to convert to UTC  because the string returned from the api includes the "Z" for Zulu (UTC) time format.
		}
	},
	{
		width: 200,
		headerName: 'Flags',
		field: 'flags',
		type: 'number'
	}
];

export const activeAdvisoriesColumns = [
	{ width: 400, headerName: 'Advisory Title', field: 'name' },
	{
		width: 150,
		headerName: 'Restriction Level',
		field: 'color',
		renderCell: params => (
			<span style={{ color: getAdvisoryRestrictionLevelColor(params.value) }}>{params.value}</span>
		),
		valueGetter: params => getAdvisoryRestrictionLevelObject(params.value).label
	},
	{
		width: 225,
		headerName: 'Starts At',
		field: 'starts_at',
		valueGetter: params => {
			return params.value ? utcToLocalTime(params.value) : 'Permanent';
		}
	},
	{
		width: 225,
		headerName: 'Ends At',
		field: 'ends_at',
		valueGetter: params => {
			return params.value ? utcToLocalTime(params.value) : 'Permanent';
		}
	}
];

export const defaultMapCenter = [-95.9325, 41.25603]; //Center of USA

export const accountsColumns = [
	{
		width: 300,
		headerName: 'Organization',
		field: 'organization'
	},
	{
		width: 200,
		headerName: 'Account Status',
		field: 'account_status',
		renderCell: params => {
			const color = getAccountStatusColor(params?.value);
			return <span style={{ color }}>{params?.value}</span>;
		}
	},
	{
		width: 300,
		headerName: 'Organization Website',
		field: 'organization_website'
	},
	{
		width: 300,
		headerName: 'User Email',
		field: 'email'
	},
	{
		width: 200,
		headerName: 'Email Status',
		field: 'email_status',
		renderCell: params => {
			const color = getEmailVerificationStatusColor(params?.value);
			return <span style={{ color }}>{params?.value ? 'Yes' : 'No'}</span>;
		}
	},
	{
		width: 225,
		headerName: 'Created On',
		field: 'created_at',
		valueGetter: params => {
			return formatDateToString(params.value); //Do not need to convert to UTC because the string returned from the api includes the "Z" for Zulu (UTC) time format.
		}
	},
	{
		width: 225,
		headerName: 'Last Updated',
		field: 'updated_at',
		valueGetter: params => {
			return formatDateToString(params.value); //Do not need to convert to UTC  because the string returned from the api includes the "Z" for Zulu (UTC) time format.
		}
	},
	{
		width: 200,
		headerName: 'Total Account Advisories',
		field: 'advisories_total',
		type: 'number'
	},
	{
		width: 200,
		headerName: 'Total Advisory Flags',
		field: 'flags',
		type: 'number'
	}
];

export const defaultMapStyle = 'streets-v11';

export const emptyGeoJSONObject = {
	type: 'Feature',
	properties: {},
	geometry: {
		type: 'Polygon',
		coordinates: []
	}
};

export const rectangleDrawMode = 'drag_rectangle';
export const circleDrawMode = 'drag_circle';
export const polygonDrawMode = 'draw_polygon';
export const simpleSelectMode = 'simple_select';
export const directSelectMode = 'direct_select';

export const dateTimeFormat = 'L LT z';

export const countriesAndCallingCodes = [];

export const TwentyMBFileSize = 20000000;

export const confirmFlagMessage =
	'Confirm status change from active to flagged, which will remove the advisory from view and notify the account holder.';
export const confirmUnflagMessage = 'Confirm status change from flagged to active.';

export const confirmUnpublishMessage =
	'Confirm status change from published to unpublished, which will remove the advisory from view.';
export const confirmPublishMessage =
	'Confirm status change from unpublished to published, which will cause the advisory to be viewable on the Aloft data network.';

export const confirmDeleteMessage = 'Are you sure you want to delete?';

export const browserTimeZoneAbbreviation = dayjs().tz(dayjs.tz.guess()).format('z');
