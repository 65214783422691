import {
	urlRegex,
	emailAddressRegex,
	passwordRegex,
	phoneNumberRegexHyphens
} from './regexHelpers';
import kinks from '@turf/kinks';
import { every } from 'lodash-es';
import dayjs from 'utils/customDayJS';
export const validateEmailRegex = value => {
	if (!value) return true;
	return emailAddressRegex.test(value.toLowerCase());
};

export const validatePhoneNumberRegex = value => {
	if (!value) return true;
	if (value) {
		return phoneNumberRegexHyphens.test(value);
	}
};

export const validateMinLength10 = value => {
	return value.length >= 10;
};

export const validateSixDigitCode = value => {
	return value.length === 6;
};

export const validatePasswordShape = value => {
	return passwordRegex.test(value);
};

export const validateUrl = value => {
	if (!value) return true;
	return urlRegex.test(value);
};

export const validateMapFeatureNoIntersect = featureCollection => {
	const featuresDefined = featureCollection.features.length > 0;

	if (featuresDefined) {
		//Determine if any polygon is self-intersecting
		const res = every(featureCollection.features, feature => !kinks(feature).features.length > 0);
		return res;
	} else {
		return true;
	}
};

export const validateMapFeatureDefined = featureCollection => {
	return featureCollection.features.length > 0;
};

export const validateEndTimeGreaterThanStartTime = ({ start, end }) => {
	const startDate = dayjs(start);
	const endDate = dayjs(end);
	return endDate > startDate;
};
