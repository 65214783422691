import {
	setActiveAdvisoriesList,
	setActiveAdvisoriesListLoaded,
	setAdvisoriesList,
	setAdvisoriesLoaded
} from 'slices/advisoriesSlice';
import { closeAdvisoryDrawer } from 'actions/advisories.actions';
import { setProfile } from 'slices/profileSlice';
import { setAccountsList, setAccountsLoaded, setSelectedAccount } from 'slices/adminSlice';

export const clearSelectedAccountData = () => dispatch => {
	dispatch(setSelectedAccount(null));
	dispatch(setAdvisoriesList([]));
	dispatch(setAdvisoriesLoaded(false));
	dispatch(closeAdvisoryDrawer());
	dispatch(setProfile({}));
	//Clear accounts list to trigger reload of accounts list (to show updates in table view after editing an account)
	dispatch(setAccountsList([]));
	dispatch(setAccountsLoaded(false));
	//Clear active advisories list to trigger reload of active advisories
	dispatch(setActiveAdvisoriesList([]));
	dispatch(setActiveAdvisoriesListLoaded(false));
};
