import { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Snackbar, Alert } from '@mui/material';
import { blue } from '@mui/material/colors';
import { $laancStatusAuthorized, $laancStatusCanceled, $warning } from 'constants/styles/_colors';
import { alertMessageSelector, setAlertMessage } from 'slices/globalSlice';

const styles = {
	snackBar: {
		'& .MuiAlert-filledSuccess': {
			backgroundColor: `${$laancStatusAuthorized} !important`
		},
		'& .MuiAlert-filledError': {
			backgroundColor: `${$laancStatusCanceled} !important`
		},
		'& .MuiAlert-filledInfo': {
			bgcolor: `${blue[500]} !important`
		},
		'& .MuiAlert-filledWarning': {
			backgroundColor: `${$warning} !important`
		}
	}
};

const MySnackbarContentWrapper = forwardRef(function MySnackbarContentWrapper(props, ref) {
	return <Alert elevation={6} ref={ref} variant='filled' icon={false} {...props} />;
});

MySnackbarContentWrapper.propTypes = {
	props: PropTypes.any,
	ref: PropTypes.any
};

function AlertMessage() {
	let initialState = { isOpen: false, message: '', type: 'info', errors: [] };
	const history = useHistory();
	const dispatch = useDispatch();

	const [errorOpen, setErrorOpen] = useState([]);
	const alertMessage = useSelector(alertMessageSelector);
	const { isOpen, type, message, errors, duration } = alertMessage;

	useEffect(() => {
		const list = errors && errors.map(() => true);
		errors && setErrorOpen(list);
	}, [errors]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(setAlertMessage({ ...initialState, type }));
	};

	const handleErrorClose = (e, idx, reason) => {
		e.stopPropagation();
		if (reason === 'clickaway') {
			return;
		}
		errorOpen[idx] = false;
		setErrorOpen([...errorOpen]);
	};

	const renderAlertMsg = () => {
		return errors.map((error, idx) => {
			if (error.status === 401 || error.status === 403) {
				history.push('/logout');
			}
			return (
				<Box key={idx} style={{ margin: 10 }}>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						style={{ top: `${idx !== 0 ? idx * 70 + 20 : 20}px` }}
						open={errorOpen[idx]}
						autoHideDuration={duration || 5000}
						onClose={handleClose}
						sx={styles.snackBar}
					>
						<MySnackbarContentWrapper onClose={e => handleErrorClose(e, idx)} severity={type}>
							{error.detail}
						</MySnackbarContentWrapper>
					</Snackbar>
				</Box>
			);
		});
	};

	return (
		<Box>
			{type === 'error' && errors?.length > 0 ? (
				renderAlertMsg()
			) : (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={isOpen}
					autoHideDuration={duration || 5000}
					onClose={handleClose}
					sx={styles.snackBar}
				>
					<MySnackbarContentWrapper onClose={handleClose} severity={type}>
						{message}
					</MySnackbarContentWrapper>
				</Snackbar>
			)}
		</Box>
	);
}

export default AlertMessage;
