import { createSlice } from '@reduxjs/toolkit';

const initialState = {}; //Set by configure store

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile(state, action) {
			state.data = action.payload;
		},
		setEditingProfile(state, action) {
			state.editingProfile = action.payload;
		}
	}
});

export const { setProfile, setEditingProfile } = profileSlice.actions;

export default profileSlice.reducer;

export const profileSelector = state => state.profile.data;
export const editingProfileSelector = state => state.profile.editingProfile;
