import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	LinearProgress,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	editingAdvisorySelector,
	selectedAdvisorySelector,
	setAdvisoriesLoaded,
	setCreatingAdvisory,
	setEditingAdvisory,
	setSelectedAdvisory
} from 'slices/advisoriesSlice';
import { $bluishGray60, $danger, $white } from 'constants/styles';
import {
	formatDateToString,
	getAdvisoryRestrictionLevelColor,
	localToUTCUnix,
	utcToLocalTime,
	formatDateToUnix
} from 'utils/helpers';
import SectionHeader from 'components/elements/SectionHeader';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from 'components/elements/InputLabel';
import ErrorMessage from 'utils/errorMessages';
import {
	advisoryRestrictionLevels,
	polygonDrawMode,
	restrictedValue,
	simpleSelectMode,
	browserTimeZoneAbbreviation
} from 'constants/generalConstants';
import {
	validateEmailRegex,
	validateEndTimeGreaterThanStartTime,
	validateMapFeatureDefined,
	validateUrl
} from 'utils/validateForm';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ButtonRegular from 'components/elements/ButtonRegular';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import { drawerLoadingSelector, setDrawerLoading } from 'slices/globalSlice';
import { createAdvisory, togglePublishAdvisory, updateAdvisory } from 'apis/advisories.api';
import MapDrawModal from 'components/elements/MapDrawModal';
import { useEffect, useState } from 'react';
import {
	featureCollectionSelector,
	geolocationErrorSelector,
	largeDrawInstanceSelector,
	setCenterMapOnLocationDetails,
	setFeatureCollection,
	setLocationDetails,
	setSelectedFeatures,
	setUpdateMapFromFile,
	smallDrawInstanceSelector
} from 'slices/mapSlice';
import { convertMapboxLocationToPointFeature, emptyFeatureCollection } from 'utils/mapHelpers';
import UploadOrPasteControls from 'components/advisory/UploadOrPasteControls';
import MapDrawControls from 'components/advisory/MapDrawControls';
import MapEditable from 'components/advisory/MapEditable';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CustomIntlPhoneInput from 'components/elements/CustomIntlPhoneInput';
import { closeAdvisoryDrawer } from 'actions/advisories.actions';
import { isEqual } from 'lodash-es';
import LocationSearchOrCoordinateInput from 'components/elements/LocationSearchOrCoordinateInput';
import TimePicker from 'components/elements/TimePicker';

const AdvisoryCreateOrEdit = () => {
	const styles = {
		main: {
			height: '100vh',
			width: '100%',
			overflow: 'auto'
		},
		header: {
			padding: '25px'
		},
		statusContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		statusLabel: {
			color: $bluishGray60,
			textTransform: 'uppercase',
			marginRight: '10px'
		},
		buttonRow: {
			display: 'flex',
			justifyContent: 'flex-end',
			gridGap: '10px',
			marginBottom: '20px'
		},
		body: {
			padding: '25px',
			paddingBottom: '0px'
		},
		mapContainer: {
			height: 400,
			width: '100%'
		},
		mapErrorContainer: {
			paddingLeft: '25px',
			paddingRight: '25px',
			width: '100%'
		},
		loaderPlaceholder: {
			height: '4px'
		},
		section: {
			marginBottom: '30px'
		},
		times: {
			display: 'flex',
			gridGap: '20px',
			marginBottom: '20px'
		},
		spaceBelow: {
			marginBottom: '20px'
		},
		fullWidth: {
			width: '100%'
		},
		halfWidth: {
			width: '290px'
		},
		mapInfo: {
			display: 'flex'
		},
		error: {
			backgroundColor: $danger,
			color: $white,
			paddingLeft: '10px'
		},
		checkboxContainer: {
			display: 'flex',
			alignItems: 'center'
		}
	};

	const emptyValues = {
		title: '',
		status: '',
		advisory_details: '',
		advisory_type: '',
		restriction_level: '',
		official_rule_link: '',
		contact_phone_number: '',
		contact_email: '',
		contact_website: '',
		start_time: null,
		end_time: null
	};

	const dispatch = useDispatch();
	const editingAdvisory = useSelector(editingAdvisorySelector);
	const selectedAdvisory = useSelector(selectedAdvisorySelector);
	const drawerLoading = useSelector(drawerLoadingSelector);
	const geolocationError = useSelector(geolocationErrorSelector);
	const featureCollectionInRedux = useSelector(featureCollectionSelector);
	const smallDrawInstance = useSelector(smallDrawInstanceSelector);
	const largeDrawInstance = useSelector(largeDrawInstanceSelector);
	const [map, setMap] = useState(null);
	const [largeMap, setLargeMap] = useState(null);
	const [drawMode, setDrawMode] = useState(editingAdvisory ? simpleSelectMode : polygonDrawMode); //Used to track button states, not used to set draw.mode
	const [modalOpen, setModalOpen] = useState(false);
	const hasDates = selectedAdvisory?.start_time || selectedAdvisory?.end_time;
	const [showDateFields, setShowDateFields] = useState(editingAdvisory ? hasDates : true);
	const [phoneWithCallingCode, setPhoneWithCallingCode] = useState(
		editingAdvisory ? selectedAdvisory?.contact_phone_number : ''
	);

	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues,
		setValue,
		trigger,
		setError,
		clearErrors
	} = useForm({
		defaultValues: editingAdvisory
			? {
					...selectedAdvisory,
					start_time: utcToLocalTime(selectedAdvisory.start_time),
					end_time: utcToLocalTime(selectedAdvisory.end_time)
			  }
			: emptyValues
	});

	const handleRestrictionLevelChange = async e => {
		setValue('restriction_level', e.target.value);
		await trigger('restriction_level');
		await trigger('official_rule_link');
	};

	const handleStartDateChange = async date => {
		let formattedDate = null;
		if (date) {
			formattedDate = formatDateToString(date);
		}
		setValue('start_time', formattedDate);
		await trigger('start_time');
		await trigger('end_time');
	};

	const handleEndDateChange = async date => {
		let formattedDate = null;
		if (date) {
			formattedDate = formatDateToString(date);
		}
		setValue('end_time', formattedDate);
		await trigger('end_time');
		await trigger('start_time');
	};

	const handleClickCancel = () => {
		//Clear the searched location so that the map will be on the user's location when the user creates a new advisory
		dispatch(setLocationDetails(null));
		if (editingAdvisory) {
			dispatch(setEditingAdvisory(false));
		} else {
			dispatch(closeAdvisoryDrawer());
		}
	};

	const handleClickSaveAdvisory = async advisoryObject => {
		dispatch(setDrawerLoading(true));
		if (advisoryObject.id) {
			//Editing
			const values = getValues();
			//Convert UTC times to unix timestamp format before saving
			values.start_time = formatDateToUnix(values.start_time);
			values.end_time = formatDateToUnix(values.end_time);
			const updatedFeatureCollection = getValues().feature_collection;
			const payload = {
				...values,
				contact_phone_number: phoneWithCallingCode
			};
			//If oversized, featureCollection in redux and feature_collection in the form are set to the value of selectedAdvisory.feature_collection_bounding_box
			//If updatedFeatureCollection is the same as selectedAdvisory.feature_collection_bounding_box then user has not modified the shape
			const oversizedShapeWasNotChanged =
				selectedAdvisory.feature_collection_oversized &&
				isEqual(updatedFeatureCollection, selectedAdvisory.feature_collection_bounding_box);
			if (oversizedShapeWasNotChanged) {
				//Do not send this value because it will overwrite the shape with the value of feature_collection_bounding_box
				delete payload.feature_collection;
			} else {
				//Advisory is not oversized OR oversized advisory shape was changed
				delete payload.feature_collection_oversized;
				delete payload.feature_collection_bounding_box;
				payload.feature_collection = JSON.stringify(updatedFeatureCollection);
			}
			const res = await dispatch(updateAdvisory(advisoryObject.id, payload));
			if (res) {
				if (selectedAdvisory?.is_published) {
					const publishedRes = await dispatch(
						togglePublishAdvisory(selectedAdvisory.id, selectedAdvisory?.is_published)
					);
					dispatch(setSelectedAdvisory(publishedRes));
				} else {
					dispatch(setSelectedAdvisory(res));
					dispatch(setFeatureCollection(res.feature_collection));
				}
				dispatch(setEditingAdvisory(false));
			}
		} else {
			//Creating
			const values = getValues();
			//Convert local times to utc and to unix timestamp format before saving
			values.start_time = localToUTCUnix(values.start_time);
			values.end_time = localToUTCUnix(values.end_time);

			const payload = {
				...values,
				contact_phone_number: phoneWithCallingCode,
				feature_collection: JSON.stringify(getValues().feature_collection)
			};

			const res = await dispatch(createAdvisory(payload));
			if (res) {
				dispatch(setSelectedAdvisory(res));
				dispatch(setFeatureCollection(res.feature_collection));
				dispatch(setCreatingAdvisory(false));
			}
		}
		dispatch(setSelectedFeatures([]));
		dispatch(setDrawerLoading(false));
		dispatch(setAdvisoriesLoaded(false)); //Trigger reload of table
	};

	const deleteFeature = async () => {
		setValue('feature_collection', null);
		await trigger('feature_collection');
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleOpenMapModal = () => {
		setModalOpen(true);
	};

	const handleFileUpload = async updatedFeatureCollection => {
		const updatedWithId = {
			...updatedFeatureCollection,
			id: 'fromFile'
		};
		dispatch(setUpdateMapFromFile(true));
		dispatch(setFeatureCollection(updatedWithId));
		//Clear the searched location so that the map will go to the location of the uploaded feature
		dispatch(setLocationDetails(null));
	};

	const handleClickTogglePermanent = async e => {
		const checked = e.target.checked;
		if (checked) {
			//Clear dates & times and revalidate
			setValue('start_time', null);
			setValue('end_time', null);
			await trigger('start_time');
			await trigger('end_time');
			setShowDateFields(false);
		} else {
			setShowDateFields(true);
		}
	};

	const handlePhoneInput = async (isValid, rawValue, object, valueWithCallingCode) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('contact_phone_number', rawValue);
		await trigger('contact_phone_number');
		if (!isValid && rawValue) {
			setError('contact_phone_number', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('contact_phone_number');
		}
	};

	const handleSelectFlag = async (rawValue, object, valueWithCallingCode, isValid) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('contact_phone_number', rawValue);
		await trigger('contact_phone_number');
		if (!isValid && rawValue) {
			setError('contact_phone_number', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('contact_phone_number');
		}
	};

	const setToEmptyValue = () => {
		setValue('feature_collection', emptyFeatureCollection);
	};

	const validateDates = () => {
		return validateEndTimeGreaterThanStartTime({
			start: getValues().start_time,
			end: getValues().end_time
		});
	};

	const handleSelectLocation = location => {
		const locationDetails = convertMapboxLocationToPointFeature(location);
		dispatch(setLocationDetails(locationDetails));
		dispatch(setCenterMapOnLocationDetails(true));
	};

	useEffect(() => {
		const updateFormWithFeatureCollection = async () => {
			setValue('feature_collection', featureCollectionInRedux);
			if (featureCollectionInRedux?.features?.length > 0) {
				await trigger('feature_collection');
			}
		};
		updateFormWithFeatureCollection();
	}, [featureCollectionInRedux, setValue, trigger]);

	return (
		<Box component={'form'} sx={styles.main}>
			{drawerLoading ? <LinearProgress /> : <Box sx={styles.loaderPlaceholder} />}
			<Box sx={styles.header}>
				<Box sx={styles.buttonRow}>
					<ButtonRegular tabIndex={-1} fitContent onClick={handleClickCancel}>
						Cancel
					</ButtonRegular>
					<ButtonOutlined
						tabIndex={-1}
						fitContent
						onClick={handleSubmit(handleClickSaveAdvisory)}
						disabled={drawerLoading}
					>
						SAVE
					</ButtonOutlined>
				</Box>
				<Box>
					<Box>
						<Controller
							render={({ field }) => (
								<TextField
									sx={styles.halfWidth}
									fullWidth
									{...field}
									placeholder='Title*'
									variant='standard'
								/>
							)}
							name='title'
							control={control}
							rules={{ required: true }}
						/>
						<ErrorMessage name='title' error={errors.title} />
					</Box>
					{selectedAdvisory?.status && (
						<Box sx={styles.statusContainer}>
							<Typography variant='h6' sx={styles.statusLabel}>
								Status:
							</Typography>
							<Typography variant='h6' sx={{ ...styles.statusValue }}>
								{selectedAdvisory?.status}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Divider />
			<Box sx={styles.body}>
				<Box sx={styles.section}>
					<SectionHeader content={'Advisory Details'} />
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'SUMMARY DETAIL*'} />
						<Controller
							render={({ field }) => (
								<TextField
									fullWidth
									multiline
									minRows={4}
									{...field}
									placeholder=''
									variant='outlined'
								/>
							)}
							name='advisory_details'
							control={control}
							rules={{ required: true }}
						/>
						<ErrorMessage name='advisory_details' error={errors.advisory_details} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'ADVISORY TYPE*'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} placeholder='' variant='standard' sx={styles.halfWidth} />
							)}
							name='advisory_type'
							control={control}
							rules={{ required: true }}
						/>
						<ErrorMessage name='advisory_type' error={errors.advisory_type} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'RESTRICTION LEVEL*'} />
						<FormControl>
							<Controller
								render={() => {
									return (
										<RadioGroup
											row
											onChange={handleRestrictionLevelChange}
											sx={styles.radioGroup}
											value={getValues().restriction_level}
										>
											{advisoryRestrictionLevels.map((option, idx) => {
												return (
													<Box key={idx}>
														<Radio size='small' value={option.value} />
														<span style={{ color: getAdvisoryRestrictionLevelColor(option.value) }}>
															{option.label}
														</span>
													</Box>
												);
											})}
										</RadioGroup>
									);
								}}
								name='restriction_level'
								control={control}
								rules={{ required: true }}
							/>
						</FormControl>
						<ErrorMessage name='restriction_level' error={errors.restriction_level} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'LINK TO OFFICIAL RULE'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} placeholder='' variant='standard' sx={styles.halfWidth} />
							)}
							name='official_rule_link'
							control={control}
							rules={{
								required: getValues().restriction_level === restrictedValue,
								validate: {
									url: validateUrl
								}
							}}
						/>
						<ErrorMessage name='official_rule_link' error={errors.official_rule_link} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'CONTACT PHONE NUMBER'} />
						<CustomIntlPhoneInput
							onPhoneNumberChange={handlePhoneInput}
							onSelectFlag={handleSelectFlag}
							value={getValues('contact_phone_number')}
						/>
						<ErrorMessage name='contact_phone_number' error={errors.contact_phone_number} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'CONTACT EMAIL'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} placeholder='' variant='standard' sx={styles.halfWidth} />
							)}
							name='contact_email'
							control={control}
							rules={{
								validate: {
									emailAddress: validateEmailRegex
								}
							}}
						/>
						<ErrorMessage name='contact_email' error={errors.contact_email} />
					</Box>
					<Box sx={styles.spaceBelow}>
						<InputLabel title={'CONTACT WEBSITE'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} placeholder='' variant='standard' sx={styles.halfWidth} />
							)}
							name='contact_website'
							control={control}
							rules={{
								validate: {
									url: validateUrl
								}
							}}
						/>
						<ErrorMessage name='contact_website' error={errors.contact_website} />
					</Box>
				</Box>
				<Box sx={styles.section}>
					<SectionHeader content={'Date and Time Range'} />
					<Box sx={styles.spaceBelow}>
						<Box sx={styles.checkboxContainer}>
							<InputLabel title={'Permanent Advisory (Active when published)'} />
							<Checkbox onChange={e => handleClickTogglePermanent(e)} checked={!showDateFields} />
						</Box>
					</Box>

					{showDateFields && (
						<Box>
							<Box sx={styles.times}>
								<Box>
									<InputLabel title={'START DATE'} />
									<Controller
										render={({ field }) => (
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DesktopDatePicker
													value={getValues().start_time}
													onChange={handleStartDateChange}
													renderInput={props => (
														<TextField {...props} variant='standard' sx={styles.halfWidth} />
													)}
												/>
											</LocalizationProvider>
										)}
										name='start_time'
										control={control}
										rules={{
											required: getValues().end_time
										}}
									/>
									<ErrorMessage name='start_time' error={errors.start_time} />
								</Box>
								<Box>
									<Controller
										render={({ field }) => (
											<TimePicker
												value={getValues().start_time}
												onChange={handleStartDateChange}
												label={'START TIME'}
												inputSX={styles.halfWidth}
												timeZone={browserTimeZoneAbbreviation}
											/>
										)}
										name='start_time'
										control={control}
										rules={{
											required: getValues().end_time
										}}
									/>
									<ErrorMessage name='start_time' error={errors.start_time} />
								</Box>
							</Box>
							<Box sx={styles.times}>
								<Box>
									<InputLabel title={'END DATE'} />
									<Controller
										render={({ field }) => (
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DesktopDatePicker
													renderInput={props => (
														<TextField {...props} variant='standard' sx={styles.halfWidth} />
													)}
													value={getValues().end_time}
													onChange={handleEndDateChange}
												/>
											</LocalizationProvider>
										)}
										name='end_time'
										control={control}
										rules={{
											required: getValues().start_time,
											validate: {
												greaterThanStartTime: a => {
													if (getValues().start_time) {
														return validateDates(a);
													}
												}
											}
										}}
									/>
									<ErrorMessage name='end_time' error={errors.end_time} />
								</Box>
								<Box>
									<Controller
										render={({ field }) => (
											<TimePicker
												value={getValues().end_time}
												onChange={handleEndDateChange}
												label={'END TIME'}
												inputSX={styles.halfWidth}
												timeZone={browserTimeZoneAbbreviation}
											/>
										)}
										name='end_time'
										control={control}
										rules={{
											required: getValues().start_time,
											validate: {
												greaterThanStartTime: a => {
													if (getValues().start_time) {
														return validateDates(a);
													}
												}
											}
										}}
									/>
									<ErrorMessage name='end_time' error={errors.end_time} />
								</Box>
							</Box>
						</Box>
					)}
				</Box>
				<Box sx={styles.section}>
					<SectionHeader content={'Map Location'} />
					<LocationSearchOrCoordinateInput onLocationSelect={handleSelectLocation} width={570} />
					<ErrorMessage name='feature_collection' error={errors.feature_collection} />
					<Box sx={geolocationError ? styles.error : {}}>
						{geolocationError ? `${geolocationError}. Use map to zoom in.` : ''}
					</Box>
					<UploadOrPasteControls
						drawObject={smallDrawInstance}
						handleFileUpload={handleFileUpload}
						value={
							getValues().feature_collection ? JSON.stringify(getValues().feature_collection) : null
						}
						editing={editingAdvisory}
						setDrawMode={setDrawMode}
						setToEmptyValue={setToEmptyValue}
					/>
					<MapDrawControls
						id={'small'}
						drawObject={smallDrawInstance}
						map={map}
						drawMode={drawMode}
						setDrawMode={setDrawMode}
						deleteFeature={deleteFeature}
					/>
					<Button
						onClick={handleOpenMapModal}
						fullWidth
						variant='outlined'
						endIcon={<OpenInFullIcon />}
					>
						Expand Map
					</Button>
					<Controller
						render={({ field }) => <div />}
						name='feature_collection'
						control={control}
						rules={{
							required: true,
							validate: {
								mapFeatureDefined: validateMapFeatureDefined
							}
						}}
					/>
					<Box sx={styles.mapContainer}>
						<MapEditable
							id={'small'}
							map={map}
							setMap={setMap}
							setDrawMode={setDrawMode}
							drawObject={smallDrawInstance}
							drawMode={drawMode}
						/>
					</Box>
					<MapDrawModal
						drawObject={largeDrawInstance}
						mapComponent={
							<MapEditable
								id={'large'}
								map={largeMap}
								setMap={setLargeMap}
								setDrawMode={setDrawMode}
								drawObject={largeDrawInstance}
								drawMode={drawMode}
							/>
						}
						open={modalOpen}
						handleFileUpload={handleFileUpload}
						onClose={handleCloseModal}
						map={largeMap}
						setMap={setLargeMap}
						setDrawMode={setDrawMode}
						drawMode={drawMode}
						deleteFeature={deleteFeature}
						featureCollection={
							getValues().feature_collection ? JSON.stringify(getValues().feature_collection) : null
						}
						setToEmptyValue={setToEmptyValue}
					/>
					<Controller
						render={({ field }) => <div />}
						name='feature_collection'
						control={control}
						rules={{
							required: true,
							validate: {
								mapFeatureDefined: validateMapFeatureDefined
							}
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

AdvisoryCreateOrEdit.propTypes = {};

export default AdvisoryCreateOrEdit;
