import { TextField, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { validateEmailRegex } from 'utils/validateForm';
import ErrorMessage from 'utils/errorMessages';
import { $loginDarkGray, $loginLightGray, authenticationPagesStyles } from 'constants/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { objectHasKeys } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { resendSignUpCode } from 'apis/authentication.api';

const styles = theme => ({
	...authenticationPagesStyles(theme),
	resendButton: {
		height: 19,
		fontSize: 16,
		textTransform: 'none'
	},
	fullWidth: {
		marginTop: 30,
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		gridColumnGap: 30
	},
	button: {
		height: 56,
		'&.Mui-disabled': {
			backgroundColor: `${$loginLightGray} !important`,
			color: $loginDarkGray
		}
	}
});

const ResendCodeEmailInput = ({ setShowResendCodeLoader, showResendCodeLoader }) => {
	const dispatch = useDispatch();
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues
	} = useForm();

	const hasErrors = objectHasKeys(errors);

	const handleResendCode = async () => {
		setShowResendCodeLoader(true);
		const { email } = getValues();
		const payload = {
			email
		};
		await dispatch(resendSignUpCode(payload));
		setShowResendCodeLoader(false);
	};

	return (
		<form onSubmit={handleSubmit(handleResendCode)} className={classes.fullWidth}>
			<Controller
				render={({ field }) => (
					<TextField
						{...field}
						placeholder='Email'
						variant='outlined'
						disabled={showResendCodeLoader}
						className={classes.textFieldNoMargin}
						autoFocus
					/>
				)}
				name='email'
				control={control}
				rules={{
					required: true,
					validate: {
						emailAddress: validateEmailRegex
					}
				}}
			/>
			<Button
				color='primary'
				className={classes.button}
				variant='contained'
				id='signUp'
				type='submit'
				disabled={showResendCodeLoader || hasErrors}
			>
				Resend
			</Button>

			<ErrorMessage name='email' error={errors.email} className={classes.error} />
		</form>
	);
};

export default ResendCodeEmailInput;

ResendCodeEmailInput.propTypes = {
	setShowResendCodeLoader: PropTypes.func.isRequired,
	showResendCodeLoader: PropTypes.bool.isRequired
};
