import { createSlice } from '@reduxjs/toolkit';
import { circleDrawMode, defaultMapCenter, rectangleDrawMode } from 'constants/generalConstants';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { mapDrawStyles } from 'utils/mapHelpers';
import DrawRectangleMode from 'utils/mapboxDrawUtils/DrawRectangleMode';
import DragCircleMode from 'utils/mapboxDrawUtils/DragCircleMode';
import DirectModeOverride from 'utils/mapboxDrawUtils/DirectModeOverride';
import SimpleSelectModeOverride from 'utils/mapboxDrawUtils/SimpleSelectModeOverride';
import { emptyFeatureCollection } from 'utils/mapHelpers';

const initialState = {
	geolocationError: false,
	userLocationLoading: false,
	mapCenter: defaultMapCenter,
	smallDrawInstance: new MapboxDraw({
		displayControlsDefault: false,
		userProperties: true,
		styles: mapDrawStyles,
		modes: {
			...MapboxDraw.modes,
			[rectangleDrawMode]: DrawRectangleMode,
			[circleDrawMode]: DragCircleMode,
			direct_select: DirectModeOverride,
			simple_select: SimpleSelectModeOverride
		}
	}),
	largeDrawInstance: new MapboxDraw({
		displayControlsDefault: false,
		userProperties: true,
		styles: mapDrawStyles,
		modes: {
			...MapboxDraw.modes,
			[rectangleDrawMode]: DrawRectangleMode,
			[circleDrawMode]: DragCircleMode,
			direct_select: DirectModeOverride,
			simple_select: SimpleSelectModeOverride
		}
	}),
	selectedFeatures: [],
	featureCollection: emptyFeatureCollection,
	radiusObject: {},
	updateMapFromFile: false,
	locationDetails: null,
	locationDetailsLoading: false,
	centerMapOnLocationDetails: false
};

const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setGeolocationError(state, action) {
			state.geolocationError = action.payload;
		},
		setUserLocationLoading(state, action) {
			state.userLocationLoading = action.payload;
		},
		setUserCoordinates(state, action) {
			state.userCoordinates = action.payload;
		},
		setMapCenter(state, action) {
			state.mapCenter = action.payload;
		},
		setSelectedFeatures(state, action) {
			state.selectedFeatures = action.payload;
		},
		setFeatureCollection(state, action) {
			state.featureCollection = action.payload;
		},
		setRadiusObject(state, action) {
			state.radiusObject = {
				...state.radiusObject,
				...action.payload
			};
		},
		setUpdateMapFromFile(state, action) {
			state.updateMapFromFile = action.payload;
		},
		setLocationDetails(state, action) {
			state.locationDetails = action.payload;
		},
		setLocationDetailsLoading(state, action) {
			state.locationDetailsLoading = action.payload;
		},
		setCenterMapOnLocationDetails(state, action) {
			state.centerMapOnLocationDetails = action.payload;
		}
	}
});

export const {
	setFeature,
	setUserCoordinates,
	setUserLocationLoading,
	setGeolocationError,
	setMapCenter,
	setDrawInstance,
	setSelectedFeatures,
	setFeatureCollection,
	setRadiusObject,
	setUpdateMapFromFile,
	setLocationDetailsLoading,
	setLocationDetails,
	setCenterMapOnLocationDetails
} = mapSlice.actions;

export default mapSlice.reducer;

export const geolocationErrorSelector = state => state.map.geolocationError;
export const userCoordinatesSelector = state => state.map.userCoordinates;
export const userLocationLoadingSelector = state => state.map.userLocationLoading;
export const mapCenterSelector = state => state.map.mapCenter;
export const smallDrawInstanceSelector = state => state.map.smallDrawInstance;
export const largeDrawInstanceSelector = state => state.map.largeDrawInstance;
export const selectedFeaturesSelector = state => state.map.selectedFeatures;
export const featureCollectionSelector = state => state.map.featureCollection;
export const radiusObjectSelector = state => state.map.radiusObject;
export const updateMapFromFileSelector = state => state.map.updateMapFromFile;
export const locationDetailsSelector = state => state.map.locationDetails;
export const locationDetailsLoadingSelector = state => state.map.locationDetailsLoading;
export const centerMapOnLocationDetailsSelector = state => state.map.centerMapOnLocationDetails;
