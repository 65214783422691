export const $brandColor = '#36D18A';
export const $primary = '#2196f3';
export const $secondary = '#151b26';
export const $danger = '#F46262';
export const $warning = '#F4A862';
export const $elevated = '#F4E562';
export const $success = '#36D18A';
export const $inactive = '#C4C4C4';

// ALERTS COLORS:
export const $laancStatusAuthorized = '#36D18A';
export const $laancStatusPending = '#F4E562';
export const $laancStatusRejected = '#F4A862';
export const $laancStatusCanceled = '#F46262';
export const $laancAuthorizedHalfOpacity = 'rgba(54, 209, 138, 50)';
export const $laanCanceledHalfOpacity = 'rgba(244, 98, 98, 50)';

// LAANC COLOR:
export const $laancColor = '#6DBEEC';

// LAANC COLOR:
export const $customAirspaceColor = '#fa9519';

export const $black = '#040D20';
export const $white = '#fff';

// BACKGROUND COLORS:
// Tan
export const $tan = '#C6C7C4';
// Dark Bluish Gray 7%
export const $gray100 = '#F7F8F9';
// Dark Bluish Gray 15%
export const $gray200 = '#EEF0F3';
export const $grayShadow = '#BABABA';

// TEXT COLORS:
// Dark Bluish Gray
export const $gray300 = '#9096A3';
// Black 75%
export const $gray400 = '#434958';
// Dark Bluish Gray
export const $gray500 = '#9096A3';

// Old colors
export const $gray600 = '#6c757d';
export const $gray700 = '#495057';
export const $gray800 = '#343a40';
export const $gray900 = '#212529';

// BUTTON COLORS:
export const $btnBlack = '#2A3142';
export const $btnPrimary = '#1489F5';
export const $btnPrimaryHover = '#0577E3';
export const $btnPrimaryDisabled = '#A1CFF3';

// random colors used in site.
export const $bluishGray60 = '#BBC2CF';
export const $khBlack = '#686E79';
export const $darkBluishGray = '#8E9AAF';
export const $lightGray = '#E4E7EA';
export const $darkGray = '#555B6E';
export const $backgroundOffWhite = '#FAFAFA';

//Flight Path Color
export const $flightPath = '#00e2fb';

//Login
export const $loginLightGray = '#BDBDBD';
export const $loginDarkGray = '#828282';
