import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	showReloadMsg: { isOpen: false, versionUpdate: null },
	alertMessage: {
		type: 'info', // ex: success, error
		message: '',
		isOpen: false,
		errors: []
	},
	drawerOpen: false,
	drawerLoading: false
};

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setAlertMessage(state, action) {
			state.alertMessage = action.payload;
		},
		setGlobalIsLoading(state, action) {
			state.isLoading = action.payload;
		},
		setShowReloadMessage(state, action) {
			state.showReloadMsg = action.payload;
		},
		openDrawer(state, action) {
			state.drawerOpen = true;
		},
		closeDrawer(state, action) {
			state.drawerOpen = false;
		},
		setDrawerLoading(state, action) {
			state.drawerLoading = action.payload;
		}
	}
});

export const {
	setAlertMessage,
	setGlobalIsLoading,
	setShowReloadMessage,
	openDrawer,
	closeDrawer,
	setDrawerLoading
} = globalSlice.actions;

export default globalSlice.reducer;

export const globalIsLoadingSelector = state => state.global.isLoading;
export const showReloadMsgSelector = state => state.global.showReloadMsg;
export const alertMessageSelector = state => state.global.alertMessage;
export const drawerOpenSelector = state => state.global.drawerOpen;
export const drawerLoadingSelector = state => state.global.drawerLoading;
