import Button from '@mui/material/Button';
import { $black } from 'constants/styles';
import PropTypes from 'prop-types';

function ButtonOutlined(props) {
	const { onClick, children, styles, fitContent, type, tabIndex, disabled, ...rest } = props;

	const btnStyles = {
		button: {
			fontSize: '14px',
			textTransform: 'none',
			fontWeight: 700,
			color: $black,
			border: `solid 1px ${$black}`,
			borderRadius: 0,
			height: '40px',
			width: fitContent ? 'fit-content' : '150px'
		}
	};
	return (
		<Button
			onClick={onClick}
			sx={btnStyles.button}
			variant='outlined'
			style={styles}
			type={type}
			tabIndex={tabIndex}
			disabled={disabled}
			{...rest}
		>
			{children}
		</Button>
	);
}

ButtonOutlined.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.any,
	fitContent: PropTypes.bool,
	type: PropTypes.string,
	tabIndex: PropTypes.number,
	disabled: PropTypes.bool,
	styles: PropTypes.object
};

export default ButtonOutlined;
