import { Box, Divider, LinearProgress } from '@mui/material';
import SectionHeader from 'components/elements/SectionHeader';
import SectionListItem from 'components/elements/SectionListItem';
import { getAccountStatusColor } from 'utils/helpers';
import PropTypes from 'prop-types';

const ProfileView = ({ button, profile, loading }) => {
	const styles = {
		main: {
			padding: '20px',
			paddingLeft: '40px'
		},
		top: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		bottom: {
			display: 'grid',
			gap: '20px',
			gridTemplateColumns: '1fr 1fr 1fr'
		},
		loaderPlaceholder: {
			height: '4px'
		}
	};

	return (
		<>
			<Divider />
			{loading ? <LinearProgress /> : <Box sx={styles.loaderPlaceholder} />}
			<Box sx={styles.main}>
				<Box sx={styles.top}>
					<SectionHeader content={'Account Profile'} />
					{button}
				</Box>
				<Box sx={styles.bottom}>
					<SectionListItem
						label={'NAME'}
						content={`${profile?.first_name ? profile.first_name : ''} ${
							profile?.last_name ? profile.last_name : ''
						}`}
					/>
					<SectionListItem label={'ORGANIZATION'} content={profile?.organization} />
					<SectionListItem
						label={'ACCOUNT VERIFICATION STATUS'}
						content={profile?.account_status}
						valueColor={getAccountStatusColor(profile?.account_status)}
					/>
					<div>
						<SectionListItem
							label={'EMAIL'}
							content={profile?.email}
							emailLink
							valueLink={profile?.email}
						/>
					</div>
					<SectionListItem
						label={'ORGANIZATION WEBSITE'}
						content={profile?.organization_website}
						valueLink={profile?.organization_website}
					/>
					<SectionListItem label={'PHONE NUMBER'} content={profile?.phone} />
				</Box>
			</Box>
			<Divider />
		</>
	);
};

ProfileView.propTypes = {
	button: PropTypes.element,
	profile: PropTypes.object.isRequired
};

export default ProfileView;
