import { Box, Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BackupIcon from '@mui/icons-material/Backup';
import toGeoJSON from '@mapbox/togeojson';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { featureCollection as createFeatureCollection } from '@turf/helpers';
import { simpleSelectMode, TwentyMBFileSize } from 'constants/generalConstants';
import shp from 'shpjs';
import {
	featureCollectionSelector,
	setFeatureCollection,
	setSelectedFeatures
} from 'slices/mapSlice';
import { emptyFeatureCollection } from 'utils/mapHelpers';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopover from 'components/elements/ConfirmPopover';
import { setAlertMessage } from 'slices/globalSlice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InputLabel from 'components/elements/InputLabel';
import GeoJSONValidator from 'components/elements/GeoJSONValidator';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UploadOrPasteControls = ({
	handleFileUpload,
	setDrawMode,
	openDrawer,
	closeDrawer,
	drawerOpen,
	value,
	modalVersion,
	setToEmptyValue,
	drawObject
}) => {
	const styles = {
		main: {
			display: 'flex',
			flexDirection: 'column'
		},
		buttonsContainer: {
			display: 'flex',
			paddingBottom: '20px',
			paddingTop: '20px',
			alignItems: 'center',
			gridTemplateColumns: '1fr 1fr',
			gridGap: '10px'
		},
		hidden: {
			visibility: 'hidden',
			width: '0px',
			minWidth: '0px',
			margin: '0px',
			padding: '0px'
		},
		manualGeoJSON: {
			marginTop: '20px'
		}
	};
	const dispatch = useDispatch();
	const [manualGeoJSON, setManualGeoJSON] = useState(false);
	const [warningAnchorEl, setWarningAnchorEl] = useState(null);
	const [onConfirm, setOnConfirm] = useState(() => {});
	const [warningMessage, setWarningMessage] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	const featureCollection = useSelector(featureCollectionSelector);
	const featuresAreDefined = featureCollection?.features?.length > 0;

	const clearFileFromInput = () => {
		document.getElementById('file').value = null;
	};

	const handleParseFile = doc => {
		const file = doc.target.files[0];
		if (file.size > TwentyMBFileSize) {
			clearFileFromInput();
			dispatch(
				setAlertMessage({
					isOpen: true,
					type: 'error',
					errors: [{ detail: 'Files must be 20MB or less.' }]
				})
			);
			return;
		}
		const isShapeFile = file?.name.includes('.zip');
		const isKmlFile = file?.name.includes('.kml');
		const isGeoJsonFile = file?.name.includes('.geojson');

		if (isShapeFile) {
			convertShapeToGeoJson(file);
			clearFileFromInput();
		} else if (isKmlFile) {
			convertKmlToGeoJson(file);
			clearFileFromInput();
		} else if (isGeoJsonFile) {
			parseGeoJson(file);
			clearFileFromInput();
		} else {
			clearFileFromInput();
			dispatch(
				setAlertMessage({
					isOpen: true,
					type: 'error',
					errors: [{ detail: `File upload error: unsupported file type` }]
				})
			);
		}
	};

	const convertKmlToGeoJson = file => {
		if (!file) return;
		setShowLoader(true);
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onloadend = event => {
			try {
				const file = event.target.result;
				const xmlDoc = new DOMParser().parseFromString(file, 'text/xml');
				const convertedFile = toGeoJSON.kml(xmlDoc);
				const fc = createFeatureCollection(convertedFile.features);
				handleFileUpload(fc);
				setShowLoader(false);
			} catch (e) {
				dispatch(
					setAlertMessage({
						isOpen: true,
						type: 'error',
						errors: [{ detail: `File upload error: ${e.message}` }]
					})
				);
			}
		};
		reader.onerror = event => {
			console.error('error', event.target.error.name);
			setShowLoader(false);
		};
	};

	const convertShapeToGeoJson = async file => {
		if (!file) return;
		setShowLoader(true);
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = async event => {
			const file = event.target.result;
			try {
				const fc = await shp(file);
				handleFileUpload(fc);
			} catch (e) {
				dispatch(
					setAlertMessage({
						isOpen: true,
						type: 'error',
						errors: [{ detail: `File upload error: ${e.message}` }]
					})
				);
			}
			setShowLoader(false);
		};
		reader.onerror = event => {
			console.error('error', event.target.error.name);
			setShowLoader(false);
		};
	};

	const parseGeoJson = file => {
		if (!file) return;
		setShowLoader(true);
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = event => {
			try {
				const result = JSON.parse(event.target.result);
				handleFileUpload(result);
				setShowLoader(false);
			} catch (e) {
				dispatch(
					setAlertMessage({
						isOpen: true,
						type: 'error',
						errors: [{ detail: `File upload error: ${e.message}` }]
					})
				);
			}
		};
		reader.onerror = event => {
			console.error('error', event.target.error.name);
			setShowLoader(false);
		};
	};

	const handleClickUpload = () => {
		setDrawMode(simpleSelectMode);
		drawObject.changeMode(simpleSelectMode);
		if (closeDrawer) {
			closeDrawer();
		}
		setManualGeoJSON(false);
		document.getElementById('fileInput').click();
	};

	const handleClickPaste = () => {
		setToEmptyValue();
		setDrawMode(simpleSelectMode);
		drawObject.changeMode(simpleSelectMode);
		setManualGeoJSON(!manualGeoJSON);
		if (closeDrawer && openDrawer) {
			drawerOpen ? closeDrawer() : openDrawer();
		}
	};

	const handleRequestUploadMode = (e, callback) => {
		const confirmAction = () => {
			setWarningAnchorEl(null);
			setWarningMessage('');
			drawObject.deleteAll();
			dispatch(setSelectedFeatures([]));
			dispatch(setFeatureCollection(emptyFeatureCollection));
			callback();
		};

		if (!featuresAreDefined) {
			//No features - allow upload/paste with no warning message
			callback();
		} else if (featureCollection.id !== 'fromFile') {
			//There are drawings present
			setWarningMessage('Changing to upload mode will remove drawn shapes.');
			setWarningAnchorEl(e.currentTarget);
			setOnConfirm(() => confirmAction);
		} else if (featureCollection.id === 'fromFile') {
			//There is a file currently uploaded
			setWarningMessage('Uploading a new file will remove current shapes.');
			setWarningAnchorEl(e.currentTarget);
			setOnConfirm(() => confirmAction);
		}
	};

	const handleGeoJSONToggle = e => {
		if (manualGeoJSON) {
			//GeoJSON input is open, user is closing it
			setManualGeoJSON(false);
		} else {
			//GeoJSON input is closed, use is opening it
			//Need to show warning that pasting geoJSON will override current drawings
			handleRequestUploadMode(e, handleClickPaste);
		}
	};

	return (
		<Box sx={styles.main}>
			<Box sx={styles.buttonsContainer}>
				<ConfirmPopover
					open={Boolean(warningAnchorEl)}
					anchorEl={warningAnchorEl}
					title={warningMessage}
					subTitle={'Do you want to continue?'}
					onCancel={() => setWarningAnchorEl(null)}
					onConfirm={onConfirm}
					cancelText={'Cancel'}
					confirmText={'Continue'}
				/>
				<Tooltip title='Shapefile .zip, .kml, or .geojson'>
					<LoadingButton
						variant='outlined'
						onClick={e => handleRequestUploadMode(e, handleClickUpload)}
						endIcon={<BackupIcon />}
						loading={showLoader}
						loadingPosition='end'
					>
						{showLoader ? 'Uploading' : 'Upload (Limit 20MB)'}
					</LoadingButton>
				</Tooltip>
				<Button
					id='fileInput'
					sx={styles.hidden}
					component={'label'}
					onChange={e => handleParseFile(e)}
				>
					<input id='file' type='file' style={styles.hidden} accept='.zip,.kml,.geojson' />
				</Button>
				<Button
					variant='outlined'
					onClick={handleGeoJSONToggle}
					endIcon={
						modalVersion ? (
							drawerOpen ? (
								<ChevronLeftIcon />
							) : (
								<ChevronRightIcon />
							)
						) : manualGeoJSON ? (
							<ExpandLessIcon />
						) : (
							<ExpandMoreIcon />
						)
					}
				>
					Paste GeoJSON
				</Button>
			</Box>
			{manualGeoJSON && !modalVersion && (
				<Box sx={styles.manualGeoJSON}>
					<InputLabel styles={{ textTransform: 'none' }} title={'PASTE OR TYPE GEOJSON HERE'} />
					<Box>
						<GeoJSONValidator
							handleGeoJsonInput={handleFileUpload}
							geoJSON={value}
							isOpen={true}
							copyPaste={() => handleFileUpload(null)}
							showManualGeoJSON={() => setManualGeoJSON(true)}
							manualGeoJSON={manualGeoJSON}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

UploadOrPasteControls.propTypes = {
	handleFileUpload: PropTypes.func.isRequired,
	value: PropTypes.string,
	setDrawMode: PropTypes.func.isRequired,
	modalVersion: PropTypes.bool,
	setToEmptyValue: PropTypes.func.isRequired,
	drawObject: PropTypes.object.isRequired
};

export default UploadOrPasteControls;
