import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { setShowReloadMessage, showReloadMsgSelector } from 'slices/globalSlice';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import { signOut } from 'apis/authentication.api';

const styles = {
	placement: {
		position: 'absolute',
		top: '20px',
		right: '20px'
	},
	alert: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiAlert-action': {
			paddingTop: 0
		}
	},
	button: {
		color: '#fff',
		border: 'solid #fff 1px',
		textTransform: 'none'
	}
};

const MySnackbarContentWrapper = forwardRef(function MySnackbarContentWrapper(props, ref) {
	return (
		<MuiAlert
			color='primary'
			elevation={6}
			ref={ref}
			variant='filled'
			sx={styles.alert}
			{...props}
		/>
	);
});

MySnackbarContentWrapper.propTypes = {
	props: PropTypes.any,
	ref: PropTypes.any
};

function AlertReload() {
	const history = useHistory();
	const reloadState = useSelector(showReloadMsgSelector);
	const dispatch = useDispatch();

	let action = '';
	let message = '';

	const logoutUser = async () => {
		await dispatch(signOut());
		history.push('/sign_in');
	};

	if (reloadState.versionUpdate === 'patch') {
		message = 'Our system has been updated. Please reload.';
		action = (
			<ButtonOutlined fitContent styles={styles.button} onClick={() => window.location.reload()}>
				Reload Now
			</ButtonOutlined>
		);
	} else {
		message = 'Our system has been updated. Please re-authenticate.';
		action = (
			<ButtonOutlined fitContent styles={styles.button} onClick={() => logoutUser()}>
				Re-Authenticate Now
			</ButtonOutlined>
		);
	}
	const handleClose = (e, reason) => {
		e && e.stopPropagation();
		if (reason === 'clickaway') {
			return;
		}
		dispatch(setShowReloadMessage(false));
	};

	const renderAlertMsg = () => {
		return (
			<Box>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={reloadState.isOpen}
					autoHideDuration={5000}
					sx={styles.snackBar}
					onClose={e => handleClose(e, 'clickaway')}
				>
					<MySnackbarContentWrapper
						onClose={e => handleClose(e, 'clickaway')}
						severity={'info'}
						action={action}
					>
						{message}
					</MySnackbarContentWrapper>
				</Snackbar>
			</Box>
		);
	};

	return <Box sx={styles.placement}>{renderAlertMsg()}</Box>;
}

export default AlertReload;
