import PropTypes from 'prop-types';
import InputLabel from 'components/elements/InputLabel';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { getTimeZoneOffsetLabel } from 'utils/helpers';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'utils/customDayJS';
const CustomTimePicker = ({
	value,
	onChange,
	label,
	className,
	timeZone,
	disabled = false,
	inputSX,
	...props
}) => {
	const handleInputChange = value => {
		onChange(value);
	};

	return (
		<div className={className}>
			<InputLabel styles={{ textTransform: 'none' }} title={label} />
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TimePicker
					disabled={disabled}
					value={value}
					onChange={handleInputChange}
					renderInput={({ inputRef, inputProps, InputProps }) => (
						<TextField
							sx={inputSX}
							variant='standard'
							{...inputProps}
							{...props}
							ref={inputRef}
							InputProps={{
								required: true,
								onClick: e => {
									e.preventDefault();
								},
								endAdornment: (
									<>
										<span>{timeZone}</span>
										{InputProps?.endAdornment}
									</>
								)
							}}
						/>
					)}
				/>
				<Box>{getTimeZoneOffsetLabel(dayjs())}</Box>
			</LocalizationProvider>
		</div>
	);
};

export default CustomTimePicker;

CustomTimePicker.propTypes = {
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	timeZone: PropTypes.string,
	disabled: PropTypes.bool
};
