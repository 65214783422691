// CSS is located in main.scss

import IntlTelInput from 'react-intl-tel-input';
import PropTypes from 'prop-types';

const CustomIntlPhoneInput = ({
	onPhoneNumberChange,
	onSelectFlag,
	value = '',
	variant = 'standard'
}) => {
	return (
		<IntlTelInput
			preferredCountries={['us']}
			onPhoneNumberChange={onPhoneNumberChange}
			onSelectFlag={onSelectFlag}
			containerClassName={`intl-tel-input phone_container_${variant}`}
			inputClassName={`phone_input_${variant}`}
			value={value || ''}
			separateDialCode
		/>
	);
};

CustomIntlPhoneInput.propTypes = {
	onPhoneNumberChange: PropTypes.func.isRequired,
	onSelectFlag: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};

export default CustomIntlPhoneInput;
