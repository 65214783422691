import { Box, Typography } from '@mui/material';

const Terms = () => {
	const styles = {
		container: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			padding: '50px',
			overflowY: 'auto'
		},
		body: { height: '100%', width: '100%', maxWidth: '1000px' },
		spacer: {
			height: '50px'
		}
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.body}>
				<Typography variant='h4' gutterBottom component='div' align='center'>
					Aloft Geo Portal Terms and Conditions
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					Last Update: April 25, 2022
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					In addition to the various Aloft Services and related terms set forth in the Aloft Terms
					of Use (
					<a href='https://www.aloft.ai/terms' target='_blank' rel='noreferrer'>
						https://www.aloft.ai/terms
					</a>
					), Aloft Technologies, Inc. (“Aloft”, “we”, “us”, or “our”) offers verified users access
					to the Aloft Geo Portal (available at{' '}
					<a href='https://geo.aloft.ai' target='_blank' rel='noreferrer'>
						https://geo.aloft.ai
					</a>
					), a platform for users to upload and manage federal, state, local, and other relevant
					drone advisories, in accordance with these Geo Portal terms and conditions (“GP T&C”).
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					These GP T&Cs incorporate the Aloft Terms of Use (“Terms”) and Privacy Policy (
					<a href='https://www.aloft.ai/privacy' target='_blank' rel='noreferrer'>
						https://www.aloft.ai/privacy
					</a>
					) and any updates thereto. Any capitalized terms not otherwise in these GP T&Cs shall have
					the meaning given to them in the Terms.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					PLEASE READ THE TERMS, THESE GP T&Cs, AND OUR PRIVACY POLICY CAREFULLY BEFORE REGISTERING
					FOR OR USING THE ALOFT SERVICE TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THE TERMS
					CONTAIN LIMITATIONS OF LIABILITY (SECTION 7.B.), A MANDATORY INDIVIDUAL ARBITRATION
					PROVISION (SECTION 9.B.) AND CLASS ACTION/JURY TRIAL WAIVER (SECTIONS 9.B. and 9.H.), AS
					FURTHER DETAILED IN THE TERMS. THESE PROVISIONS CONTROL HOW DISPUTES THAT ARISE UNDER THE
					TERMS ARE RESOLVED. IF YOU ARE NOT WILLING TO BE BOUND BY THE TERMS, THEN YOU MUST NOT
					ACCESS OR OTHERWISE USE ANY ALOFT SERVICE.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					1. Account Verification
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					To access and use the Aloft Geo Portal, you will be required to create an account with us
					and provide verifying information for Aloft’s authentication process. For related help
					documents, such as details of this authentication process and Geo Portal product features,
					please see our support page at{' '}
					<a href='https://www.aloft.ai/support/' target='_blank' rel='noreferrer'>
						https://www.aloft.ai/support
					</a>
					. Your account will not be able to publish data until this authentication process is
					successfully completed, after which your account will be deemed a “Verified Account”.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					It is important that you provide us with accurate, complete, and current account
					information and keep this information up to date. If you don’t, we reserve the right to
					suspend or terminate your account. To protect your account, please keep the account
					details and password confidential, and notify us right away of any unauthorized use. You
					are responsible for all activities that occur under your account. You will not allow any
					third party other than expressly authorized employees or contractors to access or use the
					Aloft Geo Portal.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					2. User Content
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					For clarity, any content you submit to the Aloft Geo Portal, including general and
					specific advisories, commentary on drone rules and regulations, and other suggestions,
					will be deemed User Content. Both you and Aloft can remove your User Content (or parts of
					such User Content) by specifically deleting it. However, please note that in certain
					instances, some of your User Content (such as posts or comments you make) may not be
					completely removed and copies of your User Content may continue to exist on the Aloft
					Services. To the maximum extent permitted by law, we are not responsible or liable for the
					removal or deletion of (or the failure to remove or delete) any of your User Content.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					You are solely responsible for all your User Content. You represent that, based on your
					knowledge and reasonable inquiry, all User Content is accurate, substantiated, and
					otherwise compliant with applicable laws. You represent and warrant that your (i) User
					Content, (ii) your provision of your User Content to be made available through the Aloft
					Services, and (iii) any intended use of your User Content, will not violate a third
					party’s rights of publicity or privacy, or result in the violation of any applicable law
					or regulation.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					By submitting any User Content to the Aloft Geo Portal, you hereby grant to Aloft the
					right to distribute and publicly display your User Content in connection the Aloft
					Services and promoting its products or services.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					3. Termination.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					We may suspend or terminate your access to and use of the Aloft Geo Portal, including
					suspending access to or terminating your Verified Account, at our sole discretion, at any
					time and without notice to you.
				</Typography>
				<Typography variant='h6' gutterBottom component='div'>
					4. Conflict.
				</Typography>
				<Typography variant='body1' gutterBottom component='div'>
					In the event of a conflict between these GP T&Cs and any provision of the Terms, these GP
					T&Cs supersedes the Terms with respect to your access to and use of the Aloft Geo Portal.
				</Typography>
				<Box sx={styles.spacer} />
			</Box>
		</Box>
	);
};

export default Terms;
