import ReactMapboxGl from 'react-mapbox-gl';
import { MAPBOX_KEY } from 'constants/environmentVariables';

import mapboxgl from 'mapbox-gl';
// Workaround for mapbox v2 transpilation in create-react-app
// See: https://github.com/mapbox/mapbox-gl-js/issues/10173

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const Mapbox = ReactMapboxGl({
	accessToken: MAPBOX_KEY
	// doubleClickZoom: false,
	// dragRotate: true
});
