import { $white } from 'constants/styles';
import { makeStyles } from '@mui/styles';

const styles = theme => ({
	footerContainer: {
		height: 78,
		width: '100%',
		color: $white,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'flex-end',
		gridGap: 5,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center'
		}
	}
});
const AuthenticationFooter = () => {
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const year = new Date().getFullYear();

	return (
		<div className={classes.footerContainer}>
			<span>© {year} Aloft Technologies, Inc.</span>
			<span> All rights reserved.</span>
		</div>
	);
};

export default AuthenticationFooter;
