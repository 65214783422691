import {
	Box,
	Divider,
	LinearProgress,
	TextField,
	FormControl,
	RadioGroup,
	Radio
} from '@mui/material';
import SectionHeader from 'components/elements/SectionHeader';
import SectionListItem from 'components/elements/SectionListItem';
import InputLabel from 'components/elements/InputLabel';
import { getAccountStatusColor } from 'utils/helpers';
import ButtonContained from 'components/elements/ButtonContained';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from 'utils/errorMessages';
import { validateUrl, validateEmailRegex } from 'utils/validateForm';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from 'apis/accounts.api';
import { setProfile, setEditingProfile } from 'slices/profileSlice';
import { accountStatuses } from 'constants/generalConstants';
import PropTypes from 'prop-types';
import ButtonRegular from 'components/elements/ButtonRegular';
import { setAccountsLoaded } from 'slices/adminSlice';
import { userSelector } from 'slices/authenticationSlice';
import { USER_PROFILE } from 'constants/localStorageConstants';
import { setAdvisoriesLoaded } from 'slices/advisoriesSlice';
import CustomIntlPhoneInput from 'components/elements/CustomIntlPhoneInput';

const ProfileEdit = ({ profile, admin = false }) => {
	const styles = {
		main: {
			padding: '20px',
			paddingLeft: '40px'
		},
		top: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		bottom: {
			display: 'grid',
			gap: '20px',
			gridTemplateColumns: '1fr 1fr 1fr'
		},
		textField: {
			width: '250px'
		},
		inLine: {
			display: 'grid',
			gap: '20px',
			gridTemplateColumns: '1fr 1fr'
		},
		radioGroup: {
			height: '18px'
		},
		loaderPlaceholder: {
			height: '4px'
		},
		buttonRow: {
			display: 'flex',
			gridGap: '10px'
		}
	};

	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues,
		setValue,
		trigger,
		setError,
		clearErrors
	} = useForm({
		defaultValues: {
			email: profile.email,
			first_name: profile.first_name,
			last_name: profile.last_name,
			organization: profile.organization,
			organization_website: profile.organization_website,
			account_status: profile.account_status,
			phone: profile.phone
		}
	});

	const { account_status } = profile;
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [phoneWithCallingCode, setPhoneWithCallingCode] = useState(profile.phone);

	const user = useSelector(userSelector);

	const handleSaveProfile = async () => {
		setLoading(true);
		const payload = {
			...getValues(),
			phone: phoneWithCallingCode
		};
		const res = await dispatch(updateAccount(profile.id, payload));
		if (res) {
			setLoading(false);
			dispatch(setEditingProfile(false));
			dispatch(setAccountsLoaded(false));
			if (profile.account_status !== res.account_status) {
				//Trigger reload of advisories because they may become unpublished
				dispatch(setAdvisoriesLoaded(false));
			}
			dispatch(setProfile(res));
			//Update profile in local storage if the user is editing their own profile
			if (user.user_id === res.user_id) {
				localStorage.setItem(USER_PROFILE, JSON.stringify(res));
			}
		}
	};

	const handleStatusChange = e => {
		setValue('account_status', e.target.value);
		trigger('account_status');
	};

	const handleClickCancel = () => {
		dispatch(setEditingProfile(false));
	};

	const handlePhoneInput = async (isValid, rawValue, object, valueWithCallingCode) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('phone', rawValue);
		await trigger('phone');
		if (!isValid && rawValue) {
			setError('phone', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('phone');
		}
	};

	const handleSelectFlag = async (rawValue, object, valueWithCallingCode, isValid) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('phone', rawValue);
		await trigger('phone');
		if (!isValid && rawValue) {
			setError('phone', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('phone');
		}
	};

	return (
		<>
			<Divider />
			{loading ? <LinearProgress /> : <Box sx={styles.loaderPlaceholder} />}
			<Box component={'form'} sx={styles.main}>
				<Box sx={styles.top}>
					<SectionHeader content={'Account Profile'} />
					<Box sx={styles.buttonRow}>
						<ButtonRegular tabIndex={-1} fitContent onClick={handleClickCancel} disabled={loading}>
							Cancel
						</ButtonRegular>
						<ButtonContained onClick={handleSubmit(handleSaveProfile)} disabled={loading}>
							SAVE
						</ButtonContained>
					</Box>
				</Box>
				<Box sx={styles.bottom}>
					<Box sx={styles.inLine}>
						<div>
							<InputLabel title={'FIRST NAME'} />
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										placeholder={'TEST'}
										variant='standard'
										sx={styles.textField}
									/>
								)}
								name='first_name'
								control={control}
								rules={{ required: true }}
							/>
							<ErrorMessage name='first_name' error={errors.first_name} />
						</div>
						<div>
							<InputLabel title={'LAST NAME'} />
							<Controller
								render={({ field }) => (
									<TextField {...field} variant='standard' sx={styles.textField} />
								)}
								name='last_name'
								control={control}
								rules={{ required: true }}
							/>
							<ErrorMessage name='last_name' error={errors.last_name} />
						</div>
					</Box>
					<div>
						<InputLabel title={'ORGANIZATION'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} variant='standard' sx={styles.textField} />
							)}
							name='organization'
							control={control}
							rules={{ required: true }}
						/>
						<ErrorMessage name='organization' error={errors.organization} />
					</div>
					{admin && (
						<div>
							<InputLabel title={'ACCOUNT VERIFICATION STATUS'} />
							<FormControl>
								<Controller
									render={() => {
										return (
											<RadioGroup
												row
												onChange={handleStatusChange}
												sx={styles.radioGroup}
												value={getValues().account_status}
											>
												{accountStatuses.map((option, idx) => {
													return (
														<div key={idx}>
															<Radio size='small' value={option.value} />
															<span style={{ color: getAccountStatusColor(option.value) }}>
																{option.label}
															</span>
														</div>
													);
												})}
											</RadioGroup>
										);
									}}
									name='account_status'
									control={control}
									rules={{ required: true }}
								/>
							</FormControl>
							<ErrorMessage name='account_status' error={errors.account_status} />
						</div>
					)}
					{!admin && (
						<SectionListItem
							label={'ACCOUNT VERIFICATION STATUS'}
							content={account_status}
							valueColor={getAccountStatusColor(account_status)}
						/>
					)}
					<div>
						<InputLabel title={'EMAIL'} />
						<Controller
							render={({ field }) => (
								<TextField disabled={!admin} {...field} variant='standard' sx={styles.textField} />
							)}
							name='email'
							control={control}
							rules={{
								required: true,
								validate: {
									emailAddress: validateEmailRegex
								}
							}}
						/>
						<ErrorMessage name='email' error={errors.email} />
					</div>
					<div>
						<InputLabel title={'ORGANIZATION WEBSITE'} />
						<Controller
							render={({ field }) => (
								<TextField {...field} variant='standard' sx={styles.textField} />
							)}
							name='organization_website'
							control={control}
							rules={{
								required: true,
								validate: {
									url: validateUrl
								}
							}}
						/>
						<ErrorMessage name='organization_website' error={errors.organization_website} />
					</div>
					<div>
						<InputLabel title={'PHONE NUMBER'} />
						<CustomIntlPhoneInput
							onPhoneNumberChange={handlePhoneInput}
							onSelectFlag={handleSelectFlag}
							value={getValues('phone')}
						/>
						<ErrorMessage name='phone' error={errors.phone} />
					</div>
				</Box>
			</Box>
			<Divider />
		</>
	);
};

ProfileEdit.propTypes = {
	admin: PropTypes.bool,
	profile: PropTypes.object.isRequired
};

export default ProfileEdit;
