import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { reduxStore } from 'services/configureStore';
import AppThemeProvider from './AppThemeProvider';
import * as Sentry from '@sentry/react';

const Root = ({ children }) => {
	return (
		<Provider store={reduxStore}>
			<AppThemeProvider>
				<BrowserRouter>{children}</BrowserRouter>
			</AppThemeProvider>
		</Provider>
	);
};

export default Sentry.withProfiler(Root);
