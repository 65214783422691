import { closeDrawer, openDrawer, setDrawerLoading } from 'slices/globalSlice';
import {
	setCreatingAdvisory,
	setEditingAdvisory,
	setSelectedAdvisory
} from 'slices/advisoriesSlice';
import { setFeatureCollection, setSelectedFeatures } from 'slices/mapSlice';
import { emptyFeatureCollection } from 'utils/mapHelpers';
import { getAdvisoryById } from 'apis/advisories.api';

export const closeAdvisoryDrawer = () => dispatch => {
	dispatch(closeDrawer());
	dispatch(setCreatingAdvisory(false));
	dispatch(setEditingAdvisory(false));
	dispatch(setSelectedAdvisory(null));
	dispatch(setFeatureCollection(emptyFeatureCollection));
	dispatch(setSelectedFeatures([]));
};

export const openDrawerAndFetchAdvisory = id => async dispatch => {
	dispatch(openDrawer());
	dispatch(setDrawerLoading(true));
	const res = await dispatch(getAdvisoryById(id));
	if (res) {
		dispatch(setSelectedAdvisory(res));
		dispatch(setDrawerLoading(false));
	}
};
