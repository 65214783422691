import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ErrorBoundary from 'components/elements/ErrorBoundary';
import AlertReload from 'components/elements/AlertReload';
import 'constants/styles/App.scss';
import { setShowReloadMessage } from 'slices/globalSlice';
import {
	locationDetailsSelector,
	setUserCoordinates,
	setUserLocationLoading,
	userCoordinatesSelector
} from 'slices/mapSlice';
import { geolocateUser } from 'actions/geolocation';
import { manageLocalStorageVersion } from 'services/localStorageVersion';
import { setUser, userSelector } from 'slices/authenticationSlice';
import { getAccountById } from 'apis/accounts.api';
import { setProfile } from 'slices/profileSlice';
import { USER_PROFILE } from 'constants/localStorageConstants';
import { useLocation } from 'react-router-dom';
import { fetchLocationByCoord } from 'apis/mapbox.api';

const SideMenu = lazy(() => import('components/SideMenu'));

const styles = {
	withSideNav: {
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
		height: '100%',
		overflow: 'auto'
	}
};

const App = ({ children }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const userCoordinates = useSelector(userCoordinatesSelector);
	const currentUser = useSelector(userSelector);
	const locationDetails = useSelector(locationDetailsSelector);
	const [currentUserAccountRefreshed, setCurrentUserAccountRefreshed] = useState(false);
	/**
	 * @description on major or minor version updates
	 */
	const onNewMajorMinorVersion = useCallback(() => {
		// if user is not logged in, refresh page
		if (!currentUser) {
			window.location.reload();
		} else {
			// show toast message to have user log out
			dispatch(setShowReloadMessage({ isOpen: true, versionUpdate: 'minor-major' }));
		}
	}, [dispatch, currentUser]);

	/**
	 * @description on patch version updates
	 */
	const onNewPatchVersion = useCallback(() => {
		// if user is not logged in, refresh page
		if (!currentUser) {
			window.location.reload();
		} else {
			// show toast message to have user reload page
			dispatch(setShowReloadMessage({ isOpen: true, versionUpdate: 'patch' }));
		}
	}, [dispatch, currentUser]);

	useEffect(() => {
		manageLocalStorageVersion({
			onNewMajorVersion: onNewMajorMinorVersion,
			onNewMinorVersion: onNewMajorMinorVersion,
			onNewPatchVersion: onNewPatchVersion
		});
	}, [onNewMajorMinorVersion, onNewPatchVersion]);

	useEffect(() => {
		const fetchUserLocation = async () => {
			dispatch(setUserLocationLoading(true));
			const location = await dispatch(geolocateUser());
			dispatch(setUserCoordinates(location));
			dispatch(setUserLocationLoading(false));
		};
		//If there is no userCoordinates and the geolocate prop is true, use browser geolocation to locate user
		if (!userCoordinates) {
			fetchUserLocation();
		}
	}, [dispatch, userCoordinates]);

	useEffect(() => {
		const fetchUserLocationDetails = async () => {
			const { lat, long } = userCoordinates;
			await dispatch(fetchLocationByCoord(`${long.toFixed(5)},${lat.toFixed(5)}`));
		};
		if (!locationDetails && userCoordinates) {
			//Fetch details of the user's location
			fetchUserLocationDetails();
		}
	}, [locationDetails, userCoordinates, dispatch]);

	useEffect(() => {
		//Gets user's current profile on app load/refresh in case there are updates
		const fetchCurrentUserData = async () => {
			const res = await dispatch(getAccountById(currentUser?.id));
			if (res) {
				dispatch(setUser(res));
				if (pathname === '/portal') {
					//Display the user's information in the profile section
					dispatch(setProfile(res));
				}
				localStorage.setItem(USER_PROFILE, JSON.stringify(res));
				setCurrentUserAccountRefreshed(true);
			}
		};
		if (!currentUserAccountRefreshed && currentUser?.id) {
			fetchCurrentUserData();
		}
	}, [currentUserAccountRefreshed, currentUser?.id, dispatch, pathname]);

	return (
		<Box className='App'>
			<ErrorBoundary>
				<Suspense fallback={null}>
					<SideMenu />
				</Suspense>
			</ErrorBoundary>
			<ErrorBoundary>
				<Box component='main' sx={styles.withSideNav}>
					{children}
				</Box>
			</ErrorBoundary>
			<ErrorBoundary>
				<AlertReload />
			</ErrorBoundary>
		</Box>
	);
};

export default App;
