import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	advisoriesList: [],
	selectedAdvisory: null,
	editingAdvisory: false,
	creatingAdvisory: false,
	advisoriesLoaded: false,
	activeAdvisoriesList: [],
	activeAdvisoriesListLoaded: false
};

const advisoriesSlice = createSlice({
	name: 'advisories',
	initialState,
	reducers: {
		setAdvisoriesList(state, action) {
			state.advisoriesList = action.payload;
		},
		setSelectedAdvisory(state, action) {
			state.selectedAdvisory = action.payload;
		},
		setEditingAdvisory(state, action) {
			state.editingAdvisory = action.payload;
		},
		setCreatingAdvisory(state, action) {
			state.creatingAdvisory = action.payload;
		},
		setAdvisoriesLoaded(state, action) {
			state.advisoriesLoaded = action.payload;
		},
		setActiveAdvisoriesList(state, action) {
			state.activeAdvisoriesList = action.payload;
		},
		setActiveAdvisoriesListLoaded(state, action) {
			state.activeAdvisoriesListLoaded = action.payload;
		}
	}
});

export const {
	setAdvisoriesList,
	setSelectedAdvisory,
	setEditingAdvisory,
	setCreatingAdvisory,
	setAdvisoriesLoaded,
	setActiveAdvisoriesList,
	setActiveAdvisoriesListLoaded
} = advisoriesSlice.actions;

export default advisoriesSlice.reducer;

export const advisoriesListSelector = state => state.advisories.advisoriesList;
export const selectedAdvisorySelector = state => state.advisories.selectedAdvisory;
export const editingAdvisorySelector = state => state.advisories.editingAdvisory;
export const creatingAdvisorySelector = state => state.advisories.creatingAdvisory;
export const advisoriesLoadedSelector = state => state.advisories.advisoriesLoaded;
export const activeAdvisoriesListSelector = state => state.advisories.activeAdvisoriesList;
export const activeAdvisoriesListLoadedSelector = state =>
	state.advisories.activeAdvisoriesListLoaded;
