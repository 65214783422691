import {
	$black,
	$btnPrimary,
	$danger,
	$gray300,
	$loginDarkGray,
	$loginLightGray,
	$primary,
	$tan,
	$white
} from 'constants/styles/_colors';
import MapBackground from 'assets/map_background.png';
import { blue } from '@mui/material/colors';

// viewport width
export const sideNavWidth = 66;
export const subNavWidth = 250;
export const panelTopSpace = 80;
export const defaultStyleLayer = 'light';
export const rightPanelContainerWidth = 660;
export const targetViewportWidth = 1024;

export const btnPrimary = {
	color: $white,
	background: $btnPrimary
};

export const btnDisabled = {
	color: $white,
	background: $gray300
};

export const elevation14 = {
	boxShadow:
		'0px 20px 28px -22px rgba(0,0,0,0.2), 0px 76px 19px -5px rgba(0,0,0,0.01), 0px 22px 21px 0px rgba(0,0,0,0.1)'
	// '0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12)'
};

export const elevation4 = {
	boxShadow:
		'0px 1px 5px -1px rgba(0,0,0,0.1), 0px 3px 3px -2px rgba(0,0,0,0.04), 0px 3px 4px 0px rgba(0,0,0,0.12)'
};

export const createEditDrawerStyles = {
	statusPlaceholder: {
		height: '27px'
	},
	editModeActions: {
		display: 'flex',
		alignItems: 'center'
	},
	exitEditMode: {
		textTransform: 'none',
		marginRight: '10px'
	},
	addBtnContainer: {
		position: 'relative'
	},
	requiredNote: {
		position: 'absolute',
		bottom: '-20px',
		width: '100%',
		textAlign: 'center',
		fontSize: '12px',
		fontStyle: 'italic'
	},
	statusSelector: {
		marginRight: '10px'
	},
	fullWidth: {
		width: '100%'
	},
	fullHeight: {
		height: '100%',
		overflowY: 'auto'
	},
	title: {
		fontWeight: 700,
		marginBottom: '20px'
	},
	titleNoMargin: {
		fontWeight: 700
	},
	section: {
		marginBottom: '40px'
	},
	checkboxLabel: {
		fontSize: 18,
		lineHeight: '42px',
		verticalAlign: 'middle'
	},
	card: {
		padding: '0 20px'
	},
	headerContainer: {
		display: 'grid',
		gridTemplateColumns: '5fr 2fr',
		gap: '40px'
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	columnsContainer: {
		height: '100%',
		display: 'flex',
		gap: '0 40px'
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		width: 'calc(50% - 20px)'
	},
	input: {
		'&::placeholder': {
			fontSize: '24px',
			lineHeight: '26px',
			color: $tan
		}
	},
	field: {
		marginTop: '20px'
	}
};

export const actionHeaderStyles = {
	actionsHeader: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	top: {
		display: 'flex',
		flex: '1 1 0%',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 20
	},
	titleAndNavButton: {
		flex: '1 1 auto',
		flexGrow: 2
	},
	bottom: {
		paddingRight: 20,
		paddingLeft: 10,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 20
	},
	mainButton: {
		float: 'right',
		backgroundColor: $white,
		marginRight: 15
	},
	searchInput: {
		width: 250
	},
	resetButton: {
		marginLeft: 75,
		border: `solid 1px ${$black}`,
		borderRadius: 0
	}
};

export const authenticationPagesStyles = theme => ({
	main: {
		height: '100%',
		width: '100%',
		overflow: 'auto',
		padding: 40,
		backgroundImage: `url(${MapBackground})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
			padding: 30
		}
	},
	headerContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 20,
			marginBottom: 20
		}
	},
	logo: {
		cursor: 'pointer'
	},
	geoPortal: {
		fontSize: '50px'
	},
	mainContainer: {
		width: '1200px',
		margin: '50px auto',
		maxWidth: '100%',
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto 50px'
		}
	},
	authLogosContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '30px',
		height: '80%'
	},
	leftContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		color: $white,
		fontWeight: 800
	},
	outlineContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 50,
		width: 500,
		maxWidth: '100%',
		border: `1px solid ${$loginDarkGray}`,
		borderRadius: '4px',
		backgroundColor: $black,
		[theme.breakpoints.down('sm')]: {
			padding: 20,
			width: '100%'
		}
	},
	outlineContainerNoPadding: {
		display: 'flex',
		flexDirection: 'column',
		width: 500,
		maxWidth: '100%',
		border: `1px solid ${$loginDarkGray}`,
		borderRadius: '4px',
		backgroundColor: $black,
		[theme.breakpoints.down('sm')]: {
			padding: 20,
			width: '100%'
		}
	},
	formSection: {
		display: 'flex',
		flexDirection: 'column',
		padding: 50,
		paddingBottom: 25,
		minHeight: 806
	},
	readOnly: {},
	termsCheckbox: {
		marginTop: 30,
		'& .MuiCheckbox-root': {
			color: $white
		},
		'& .Mui-checked': {
			color: $primary
		},
		'& .Mui-disabled': {
			color: $loginLightGray
		},
		'& .MuiFormControlLabel-label': {
			color: `${$white} !important`
		}
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	stepper: {
		height: 50,
		backgroundColor: $black,
		'& .MuiMobileStepper-dot': {
			backgroundColor: $loginLightGray
		},
		'& .MuiMobileStepper-dotActive': {
			backgroundColor: $primary
		}
	},
	inviteName: {
		fontSize: 30
	},
	accountName: {
		fontSize: 30,
		fontWeight: 'bold',
		color: $primary,
		marginRight: 10,
		marginLeft: 10
	},
	signIn: {
		fontSize: 50,
		marginBottom: 15,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	authCopy: {
		lineHeight: '1.5em',
		width: 500,
		maxWidth: '100%',
		fontSize: 18,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	actionLink: {
		fontWeight: 'bold',
		cursor: 'pointer',
		color: blue[300],
		marginLeft: 10
	},
	belowSignIn: {
		marginTop: 20,
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		},
		'& .MuiTypography-root': {
			color: $white,
			fontWeight: 'bold'
		}
	},
	formTitle: {
		fontSize: 30,
		marginBottom: 30,
		color: $white
	},
	textFieldNoMargin: {
		backgroundColor: $white,
		borderRadius: '3px'
	},
	textField: {
		backgroundColor: $white,
		borderRadius: '3px',
		marginTop: 30
	},
	button: {
		marginTop: 30,
		height: 50,
		'&.Mui-disabled': {
			backgroundColor: `${$loginLightGray} !important`,
			color: $loginDarkGray
		}
	},
	outlineButton: {
		color: $white,
		border: `1px solid ${$white}`,
		fontWeight: 'bold',
		height: 50,
		width: 350,
		maxWidth: '100%',
		'&:hover': {
			border: `1px solid ${$white} !important`,
			backgroundColor: `rgba(255, 255, 255, 0.2) !important`
		}
	},
	error: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: $danger,
		color: $white,
		padding: 5,
		width: '100%',
		maxWidth: 500,
		marginBottom: 5,
		marginTop: 5
	},
	appLinks: {
		display: 'grid',
		gridGap: 40,
		gridTemplateColumns: '1fr 1fr'
	},
	divider: {
		borderColor: $loginDarkGray,
		marginTop: 30,
		marginBottom: 30,
		width: '100%'
	},
	download: {
		fontSize: 20,
		marginTop: 20,
		marginBottom: 20,
		textAlign: 'center'
	},
	forgotPassword: {
		textTransform: 'none',
		color: $primary,
		alignSelf: 'flex-end',
		marginTop: 20,
		cursor: 'pointer',
		'&.Mui-disabled': {
			color: $loginDarkGray
		}
	}
});
