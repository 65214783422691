import { useState } from 'react';
import {
	Button,
	Checkbox,
	IconButton,
	InputAdornment,
	LinearProgress,
	TextField,
	Typography,
	Link,
	Grid
} from '@mui/material';
import { authenticationPagesStyles } from 'constants/styles';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { validateEmailRegex, validatePasswordShape, validateUrl } from 'utils/validateForm';
import ErrorMessage from 'utils/errorMessages';
import { setAlertMessage } from 'slices/globalSlice';
import AloftLogo from 'assets/aloft_logo_white.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CompanyLogos from 'components/authentication/CompanyLogos';
import AuthenticationFooter from 'components/authentication/AuthenticationFooter';
import { signUp } from 'apis/authentication.api';
import { setSignUpEmail } from 'slices/authenticationSlice';
import CustomIntlPhoneInput from 'components/elements/CustomIntlPhoneInput';

import { TOKEN } from 'constants/localStorageConstants';

const styles = theme => ({
	...authenticationPagesStyles(theme)
});

const SignUp = () => {
	const history = useHistory();
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showLoader, setShowLoader] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [phoneWithCallingCode, setPhoneWithCallingCode] = useState('');

	const token = localStorage.getItem(TOKEN);
	if (token) {
		//Redirect to root if user is logged in. This will then redirect to /portal or /admin
		history.push('/');
	}

	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues,
		setValue,
		trigger,
		setError,
		clearErrors
	} = useForm({
		defaultValues: {
			email: '',
			first_name: '',
			last_name: '',
			organization: '',
			organization_website: '',
			phone: '',
			password: '',
			confirm_password: '',
			terms_accepted: false,
			verification_policy_reviewed: false
		}
	});

	const onSubmit = () => {
		handleSubmit(handleSignUp)();
	};

	const handleSignUp = async () => {
		dispatch(setAlertMessage({}));
		setShowLoader(true);
		const payload = {
			...getValues(),
			phone: phoneWithCallingCode
		};
		dispatch(setSignUpEmail(payload.email));
		const res = await dispatch(signUp(payload));
		if (res) {
			setShowLoader(false);
			history.push('/confirm_sign_up');
		} else {
			setShowLoader(false);
		}
	};

	const validateConfirmPassword = value => {
		const { password } = getValues();
		return password && value && password === value;
	};

	const handleClickLogin = () => {
		history.push(`/sign_in`);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleAcceptTerms = async e => {
		setValue('terms_accepted', e.target.checked);
		await trigger('terms_accepted');
	};

	const handleReviewAccountVerificationPolicy = async e => {
		setValue('verification_policy_reviewed', e.target.checked);
		await trigger('verification_policy_reviewed');
	};

	const handleOpenTermsOfService = () => {
		window.open('/terms', '_blank');
	};

	const handleOpenAccountVerificationPolicy = () => {
		window.open('/verification_policy', '_blank');
	};

	const handlePhoneInput = async (isValid, rawValue, object, valueWithCallingCode) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('phone', rawValue);
		await trigger('phone');
		if (!isValid && rawValue) {
			setError('phone', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('phone');
		}
	};

	const handleSelectFlag = async (rawValue, object, valueWithCallingCode, isValid) => {
		setPhoneWithCallingCode(valueWithCallingCode);
		setValue('phone', rawValue);
		await trigger('phone');
		if (!isValid && rawValue) {
			setError('phone', { type: 'phoneNumberCountry' });
		} else {
			clearErrors('phone');
		}
	};

	return (
		<form className={classes.main}>
			<div className={classes.headerContainer}>
				<img src={AloftLogo} alt='' onClick={handleClickLogin} className={classes.logo} />
				<Link
					href='https://www.aloft.ai/support/geo-portal/'
					target='_blank'
					rel='noopener noreferrer'
					underline='none'
				>
					<Button className={classes.outlineButton} variant='outlined'>
						Frequently Asked Questions
					</Button>
				</Link>
			</div>
			<div className={classes.mainContainer}>
				<div className={classes.authLogosContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} className={classes.leftContainer}>
							<div>
								{/* <div className={classes.signIn}>Sign up for</div> */}
								<div className={classes.geoPortal}>Aloft Geo Portal</div>
								<Typography className={classes.authCopy} sx={{ margin: '10px 0 50px' }}>
									Publish safety advisories directly to Aloft and B4UFLY airspace applications,
									reaching the largest audience of recreational, commercial, and government drone
									pilots.
								</Typography>
								<Typography className={classes.authCopy}>
									If you already have an account,
									<br />
									You can
									<span className={classes.actionLink} onClick={handleClickLogin}>
										Sign in here!
									</span>
								</Typography>
							</div>
							<Typography className={classes.authCopy}>Trusted by:</Typography>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.rightContainer}>
							<div elevation={0} className={classes.outlineContainerNoPadding}>
								<div className={classes.formSection}>
									<div className={classes.formTitle}>
										Sign Up
										{showLoader && <LinearProgress />}
									</div>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Email'
												variant='outlined'
												disabled={showLoader}
												className={classes.textFieldNoMargin}
												autoFocus
											/>
										)}
										name='email'
										control={control}
										rules={{
											required: true,
											validate: {
												emailAddress: validateEmailRegex
											}
										}}
									/>
									<ErrorMessage name='email' error={errors.email} className={classes.error} />
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='First Name'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
											/>
										)}
										name='first_name'
										control={control}
										rules={{ required: true }}
									/>
									<ErrorMessage
										name='first_name'
										error={errors.first_name}
										className={classes.error}
									/>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Last Name'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
											/>
										)}
										name='last_name'
										control={control}
										rules={{ required: true }}
									/>
									<ErrorMessage
										name='last_name'
										error={errors.last_name}
										className={classes.error}
									/>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Organization'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
											/>
										)}
										name='organization'
										control={control}
										rules={{ required: true }}
									/>
									<ErrorMessage
										name='organization'
										error={errors.organization}
										className={classes.error}
									/>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Organization Website'
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
											/>
										)}
										name='organization_website'
										control={control}
										rules={{
											required: true,
											validate: {
												url: validateUrl
											}
										}}
									/>
									<ErrorMessage
										name='organization_website'
										error={errors.organization_website}
										className={classes.error}
									/>
									<Controller
										render={({ field }) => (
											<CustomIntlPhoneInput
												onPhoneNumberChange={handlePhoneInput}
												onSelectFlag={handleSelectFlag}
												value={getValues('phone')}
												variant={'outlined'}
											/>
										)}
										name='phone'
										control={control}
										rules={{
											required: true
										}}
									/>
									<ErrorMessage name='phone' error={errors.phone} className={classes.error} />
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Password'
												inputProps={{ type: showPassword ? 'text' : 'password' }}
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<IconButton onClick={handleClickShowPassword} tabIndex={-1}>
																{showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										)}
										name='password'
										control={control}
										rules={{
											required: true,
											validate: {
												passwordShape: validatePasswordShape
											}
										}}
									/>
									<ErrorMessage name='password' error={errors.password} className={classes.error} />
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Confirm Password'
												inputProps={{ type: showConfirmPassword ? 'text' : 'password' }}
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<IconButton onClick={handleClickShowConfirmPassword} tabIndex={-1}>
																{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										)}
										name='confirm_password'
										control={control}
										rules={{
											required: true,
											validate: {
												passwordMismatch: validateConfirmPassword
											}
										}}
									/>
									<ErrorMessage
										name='confirm_password'
										error={errors.confirm_password}
										className={classes.error}
									/>
									<div className={classes.termsCheckbox}>
										<Controller
											render={() => (
												<div className={classes.checkboxContainer}>
													<Checkbox
														disabled={showLoader}
														onChange={e => handleAcceptTerms(e)}
														checked={getValues().terms_accepted}
														onKeyPress={async e => {
															if (e.key === 'Enter') {
																setValue('terms_accepted', true);
																await trigger('terms_accepted');
															}
														}}
													/>
													<div className={classes.actionLink} onClick={handleOpenTermsOfService}>
														Accept terms of service
													</div>
												</div>
											)}
											name='terms_accepted'
											control={control}
											rules={{
												required: true
											}}
										/>
									</div>
									<ErrorMessage
										name='terms_accepted'
										error={errors.terms_accepted}
										className={classes.error}
									/>
									<div className={classes.termsCheckbox}>
										<Controller
											render={() => (
												<div className={classes.checkboxContainer}>
													<Checkbox
														disabled={showLoader}
														onChange={e => handleReviewAccountVerificationPolicy(e)}
														checked={getValues().verification_policy_reviewed}
														onKeyPress={async e => {
															if (e.key === 'Enter') {
																setValue('verification_policy_reviewed', true);
																await trigger('verification_policy_reviewed');
															}
														}}
													/>
													<div
														className={classes.actionLink}
														onClick={handleOpenAccountVerificationPolicy}
													>
														Review account verification policy
													</div>
												</div>
											)}
											name='verification_policy_reviewed'
											control={control}
											rules={{
												required: true
											}}
										/>
									</div>
									<ErrorMessage
										name='verification_policy_reviewed'
										error={errors.verification_policy_reviewed}
										className={classes.error}
									/>
									<Button
										onClick={onSubmit}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												onSubmit();
											}
										}}
										color='primary'
										className={classes.button}
										variant='contained'
										disabled={showLoader}
									>
										Submit
									</Button>
								</div>
							</div>

							<div className={classes.belowSignIn}>
								<Typography>
									Publish safety advisories directly to Aloft and B4UFLY airspace applications,
									reaching the largest audience of recreational, commercial, and government drone
									pilots.
								</Typography>
								<Typography sx={{ marginTop: '10px' }}>
									If you already have an account you can
									<span className={classes.actionLink} onClick={handleClickLogin}>
										Sign in here!
									</span>
								</Typography>
							</div>
						</Grid>
					</Grid>
					<CompanyLogos />
				</div>
			</div>
			<AuthenticationFooter />
		</form>
	);
};

export default SignUp;
