import NasaLogo from 'assets/logos/Nasa_logo.png';
import SCForestryCommissionLogo from 'assets/logos/SC_Forestry_Commission_logo.png';
import DHSLogo from 'assets/logos/DHS_logo.png';
import PCRLogo from 'assets/logos/PC_R_logo.png';
import WYDOTLogo from 'assets/logos/WYDOT_logo.png';
import NYCFireDeptLogo from 'assets/logos/NYC_Fire_Dept_logo.png';
import NJDEPLogo from 'assets/logos/NJDEP_logo.png';
import NorfolkFireRescueLogo from 'assets/logos/Norfolk_Fire_Rescue_logo.png';
import TXDPSSealLogo from 'assets/logos/TX_DPS_Seal_logo.png';
import SpaceForceLogo from 'assets/logos/Space_Force_logo.png';
import FaaLogo from 'assets/logos/faa_logo.png';
import DcnrLogo from 'assets/logos/Dcnr_logo.png';
import CityOfLasVegasLogo from 'assets/logos/CityOfLasVegas_logo.png';
import UsdaLogo from 'assets/logos/Usda_logo.png';
import LAFDUASLogo from 'assets/logos/LAFD_UAS_logo.png';
import MontereyBayNationalMarineSanctuaryLogo from 'assets/logos/Monterey_Bay_National_Marine_Sanctuary_logo.png';
import BLMLogo from 'assets/logos/BLM_logo.png';
import MDOTLogo from 'assets/logos/MDOT_logo.png';
import USCoastGuardLogo from 'assets/logos/US_Coast_Guard_logo.png';
import USArmyCorpsofEngineersLogo from 'assets/logos/US_Army_Corps_of_Engineers_logo.png';

import { ImageList, ImageListItem } from '@mui/material';

const logoImagesRowOne = [
	{
		img: FaaLogo,
		title: 'FAA Logo'
	},
	{
		img: NasaLogo,
		title: 'NASA Logo'
	},
	{
		img: SCForestryCommissionLogo,
		title: 'SC Forestry Commission Logo'
	},
	{
		img: DHSLogo,
		title: 'DHS Logo'
	},
	{
		img: PCRLogo,
		title: 'PCR Logo'
	},
	{
		img: WYDOTLogo,
		title: 'WYDOT Logo'
	},
	{
		img: NYCFireDeptLogo,
		title: 'NYC Fire Dept Logo'
	},
	{
		img: NJDEPLogo,
		title: 'NJDEP Logo'
	},
	{
		img: NorfolkFireRescueLogo,
		title: 'Norfolk Fire Rescue Logo'
	},
	{
		img: TXDPSSealLogo,
		title: 'TXDPS Seal Logo'
	}
];

const logoImagesRowTwo = [
	{
		img: SpaceForceLogo,
		title: 'Space Force Logo'
	},
	{
		img: CityOfLasVegasLogo,
		title: 'City of Las Vegas Logo'
	},
	{
		img: DcnrLogo,
		title: 'Dcnr Logo'
	},

	{
		img: UsdaLogo,
		title: 'Usda Logo'
	},
	{
		img: LAFDUASLogo,
		title: 'LAFDUAS Logo'
	},
	{
		img: MontereyBayNationalMarineSanctuaryLogo,
		title: 'Monterey Bay National Marine Sanctuary Logo'
	},
	{
		img: BLMLogo,
		title: 'BLM Logo'
	},
	{
		img: MDOTLogo,
		title: 'MDOT Logo'
	},
	{
		img: USCoastGuardLogo,
		title: 'US Coast Guard Logo'
	},
	{
		img: USArmyCorpsofEngineersLogo,
		title: 'US Army Corps of Engineers Logo'
	}
];

const styles = {
	companyLogos: {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	},
	companyLogosContainer: {
		overflowY: 'visible',
		margin: 0
	}
};

const CompanyLogos = () => {
	return (
		<>
			<ImageList
				cols={10}
				rowHeight={70}
				sx={[styles.companyLogosContainer, { display: { xs: 'none', sm: 'grid' } }]}
			>
				{logoImagesRowOne.map((item, index) => (
					<ImageListItem key={index}>
						<img
							style={styles.companyLogos}
							src={item.img}
							srcSet={item.img}
							alt={item.title}
							loading='lazy'
						/>
					</ImageListItem>
				))}
			</ImageList>
			<ImageList
				cols={10}
				rowHeight={55}
				sx={[styles.companyLogosContainer, { display: { xs: 'none', sm: 'grid' } }]}
			>
				{logoImagesRowTwo.map((item, index) => (
					<ImageListItem key={index}>
						<img
							style={styles.companyLogos}
							src={item.img}
							srcSet={item.img}
							alt={item.title}
							loading='lazy'
						/>
					</ImageListItem>
				))}
			</ImageList>
		</>
	);
};

export default CompanyLogos;
