import axiosInstance from 'services/axiosConfig';
import { BASE_URL } from 'constants/environmentVariables';
import { handleApiResponse, handleApiException } from 'actions/api.actions';
import { TOKEN, USER_PROFILE } from 'constants/localStorageConstants';

export const signUp = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/user`;
		const response = await axiosInstance.post(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const resendSignUpCode = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/user/resend-verification-email`;
		const response = await axiosInstance.patch(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const confirmSignUp = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/user/verify-account`;
		const response = await axiosInstance.patch(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const signIn = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/login`;
		const response = await axiosInstance.post(url, payload);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const requestPasswordReset = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/user/forget-password`;
		const response = await axiosInstance.patch(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const resetPassword = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/user/reset-password`;
		const response = await axiosInstance.patch(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const signOut = () => async dispatch => {
	try {
		const url = `${BASE_URL}/logout`;
		const response = await axiosInstance.post(url);
		localStorage.removeItem(USER_PROFILE);
		localStorage.removeItem(TOKEN);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};
