import axios from 'axios';
import { getToken } from 'actions/localStorage';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async request => {
	//Append token to request
	let token = await getToken();

	request.headers = {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest'
	};
	if (token) {
		request.headers.Authorization = `Bearer ${token}`;
	}

	return request;
});

export default axiosInstance;
