import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	accountsList: [],
	selectedAccount: null,
	accountsLoaded: false
};

const admin = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		setAccountsList(state, action) {
			state.accountsList = action.payload;
		},
		setSelectedAccount(state, action) {
			state.selectedAccount = action.payload;
		},
		setAccountsLoaded(state, action) {
			state.accountsLoaded = action.payload;
		}
	}
});

export const { setAccountsList, setSelectedAccount, setAccountsLoaded } = admin.actions;

export default admin.reducer;

export const accountsListSelector = state => state.admin.accountsList;
export const selectedAccountSelector = state => state.admin.selectedAccount;
export const accountsLoadedSelector = state => state.admin.accountsLoaded;
