import PropTypes from 'prop-types';
import {
	Button,
	ListItem,
	ListItemText,
	MenuList,
	Menu,
	LinearProgress,
	ListItemIcon
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
	button: {
		textTransform: 'none'
	}
};

const TableActionsEl = ({ actions, loading, anchor, open, handleClose, handleClick, anchorEl }) => {
	const onClick = e => {
		handleClick();
	};

	const onClose = () => {
		handleClose();
	};

	return (
		<>
			<Button
				size='small'
				sx={styles.button}
				startIcon={<ExpandMoreIcon />}
				onClick={onClick}
				ref={anchor}
			>
				APPLY ACTION
			</Button>
			<Menu open={open} anchorEl={anchorEl} onClose={!loading ? onClose : null}>
				{loading && <LinearProgress />}
				<MenuList>
					{actions.map((a, key) => {
						if (a.component) {
							return <div key={key}>{a.component}</div>;
						} else {
							return (
								<ListItem
									button
									dense
									key={key}
									onClick={e => a.onClick(e, a)}
									disabled={loading || a.disabled}
								>
									<ListItemIcon>{a.icon}</ListItemIcon>
									<ListItemText primary={a.label} secondary={a.disabled ? a.disabledLabel : ''} />
								</ListItem>
							);
						}
					})}
					{actions.length === 0 && (
						<ListItem button dense disabled>
							<ListItemText
								primary={'No actions allowed based on the account status and the selected items.'}
							/>
						</ListItem>
					)}
				</MenuList>
			</Menu>
		</>
	);
};

TableActionsEl.defaultProps = {
	loading: false
};

TableActionsEl.propTypes = {
	actions: PropTypes.array.isRequired,
	handleClick: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	anchorEl: PropTypes.any.isRequired,
	anchor: PropTypes.any
};

export default TableActionsEl;
