import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import UploadOrPasteControls from 'components/advisory/UploadOrPasteControls';
import MapDrawControls from 'components/advisory/MapDrawControls';
import { useSelector, useDispatch } from 'react-redux';
import { editingAdvisorySelector } from 'slices/advisoriesSlice';
import { useState } from 'react';
import InputLabel from 'components/elements/InputLabel';
import GeoJSONValidator from 'components/elements/GeoJSONValidator';
import LocationSearchOrCoordinateInput from 'components/elements/LocationSearchOrCoordinateInput';
import { convertMapboxLocationToPointFeature } from 'utils/mapHelpers';
import { setLocationDetails } from 'slices/mapSlice';
const MapDrawModal = ({
	open,
	onClose,
	map,
	setDrawMode,
	drawMode,
	deleteFeature,
	featureCollection,
	handleFileUpload,
	mapComponent,
	setToEmptyValue,
	drawObject
}) => {
	const editingAdvisory = useSelector(editingAdvisorySelector);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const dispatch = useDispatch();

	const styles = {
		dialog: {
			padding: '50px'
		},
		controlSection: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		mapContainer: {
			height: '100%',
			width: drawerOpen ? 'calc(100% - 500px)' : '100%'
		},
		bottomContainer: {
			display: 'flex',
			width: '100%',
			height: 'calc(100% - 100px)'
		},
		drawerContainer: {
			height: '100%',
			width: '500px',
			padding: '25px',
			paddingTop: 0
		}
	};

	const handleOpenDrawer = () => {
		setDrawerOpen(true);
	};

	const handleCloseDrawer = () => {
		setDrawerOpen(false);
	};

	const handleSelectLocation = location => {
		const locationDetails = convertMapboxLocationToPointFeature(location);
		dispatch(setLocationDetails(locationDetails));
	};

	return (
		<Dialog open={open} fullScreen sx={styles.dialog}>
			<DialogTitle>Map Edit</DialogTitle>
			<DialogContent>
				<Box sx={styles.controlSection}>
					<MapDrawControls
						id={'large'}
						drawObject={drawObject}
						map={map}
						drawMode={drawMode}
						setDrawMode={setDrawMode}
						deleteFeature={deleteFeature}
					/>
					<Box sx={{ width: '400px' }}>
						<LocationSearchOrCoordinateInput onLocationSelect={handleSelectLocation} width={400} />
					</Box>
					<UploadOrPasteControls
						drawObject={drawObject}
						handleFileUpload={handleFileUpload}
						editing={editingAdvisory}
						setDrawMode={setDrawMode}
						openDrawer={handleOpenDrawer}
						closeDrawer={handleCloseDrawer}
						drawerOpen={drawerOpen}
						modalVersion
						value={featureCollection}
						setToEmptyValue={setToEmptyValue}
					/>
				</Box>
				<Box sx={styles.bottomContainer}>
					<Box sx={styles.mapContainer}>{mapComponent}</Box>
					{drawerOpen && (
						<Box sx={styles.drawerContainer}>
							<InputLabel styles={{ textTransform: 'none' }} title={'PASTE OR TYPE GEOJSON HERE'} />
							<GeoJSONValidator
								fullHeight
								handleGeoJsonInput={handleFileUpload}
								geoJSON={featureCollection}
								isOpen={true}
								copyPaste={() => handleFileUpload(null)}
							/>
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);
};

MapDrawModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	map: PropTypes.object,
	setMap: PropTypes.func.isRequired,
	setDrawMode: PropTypes.func.isRequired,
	drawMode: PropTypes.string.isRequired,
	deleteFeature: PropTypes.func.isRequired,
	featureCollection: PropTypes.string,
	handleFileUpload: PropTypes.func.isRequired,
	mapComponent: PropTypes.element.isRequired,
	setToEmptyValue: PropTypes.func.isRequired,
	drawObject: PropTypes.object.isRequired
};

export default MapDrawModal;
