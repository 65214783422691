import { combineReducers } from 'redux';
import globalSlice from 'slices/globalSlice';
import authenticationSlice from 'slices/authenticationSlice';
import profileSlice from 'slices/profileSlice';
import advisoriesSlice from 'slices/advisoriesSlice';
import mapSlice from 'slices/mapSlice';
import adminSlice from 'slices/adminSlice';

const rootReducer = (state, action) => {
	if (action.type === 'RESET') {
		state = undefined;
	}
	return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
	global: globalSlice,
	authentication: authenticationSlice,
	profile: profileSlice,
	advisories: advisoriesSlice,
	map: mapSlice,
	admin: adminSlice
});

export default rootReducer;
