import { LS_LAST_UPDATED_VERSION } from 'constants/localStorageConstants';
import { REACT_APP_VERSION } from 'constants/environmentVariables';

export const getMinorVersion = version => {
	const splitVersion = version.split('.');
	const minorVersion = splitVersion[1];
	return minorVersion;
};

export const getMajorVersion = version => {
	const splitVersion = version.split('.');
	const majorVersion = splitVersion[0];
	return majorVersion;
};

export const getPatchVersion = version => {
	const splitVersion = version.split('.');
	const minorVersion = splitVersion[2];

	return minorVersion;
};

export const manageLocalStorageVersion = ({
	onNewMajorVersion,
	onNewMinorVersion,
	onNewPatchVersion
}) => {
	const deployedVersion = REACT_APP_VERSION;
	if (deployedVersion) {
		const deployedMajorVersion = getMajorVersion(deployedVersion);
		const deployedMinorVersion = getMinorVersion(deployedVersion);
		const deployedPatchVersion = getPatchVersion(deployedVersion);
		const lsLastUpdatedVersion = localStorage.getItem(LS_LAST_UPDATED_VERSION);

		if (lsLastUpdatedVersion) {
			const lsMajorVersion = getMajorVersion(lsLastUpdatedVersion);
			const lsMinorVersion = getMinorVersion(lsLastUpdatedVersion);
			const lsPatchVersion = getPatchVersion(lsLastUpdatedVersion);
			const newMajorVersion = lsMajorVersion !== deployedMajorVersion;
			const newMinorVersion = lsMinorVersion !== deployedMinorVersion;
			const newPatchVersion = lsPatchVersion !== deployedPatchVersion;

			//Update version in local storage
			if (newMajorVersion || newMinorVersion || newPatchVersion) {
				localStorage.removeItem(LS_LAST_UPDATED_VERSION);
				localStorage.setItem(LS_LAST_UPDATED_VERSION, deployedVersion);
			}

			//Callback for each type of version change
			if (newMajorVersion && onNewMajorVersion) {
				onNewMajorVersion();
			} else if (newMinorVersion && onNewMinorVersion) {
				onNewMinorVersion();
			} else if (newPatchVersion && onNewPatchVersion) {
				onNewPatchVersion();
			}
		} else {
			//Set lsLastUpdatedVersion for the first time
			localStorage.setItem(LS_LAST_UPDATED_VERSION, deployedVersion);
		}
	}
};
