import axiosInstance from 'services/axiosConfig';
import { BASE_URL } from 'constants/environmentVariables';
import { handleApiResponse, handleApiException } from 'actions/api.actions';

export const getAdvisoriesListByAccountId = accountId => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory?user_id=${accountId}`;
		const response = await axiosInstance.get(url);
		return handleApiResponse({ response, dispatch, errorMessage: false });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

let getAdvisoryByIdController;
export const getAdvisoryById = advisoryId => async dispatch => {
	//If a previous request exists, abort it before initiating the new request.
	if (getAdvisoryByIdController) {
		getAdvisoryByIdController.abort();
	}
	getAdvisoryByIdController = new AbortController(); //Set the controller so that this request can be canceled if needed.
	try {
		const url = `${BASE_URL}/advisory/${advisoryId}`;
		const response = await axiosInstance.get(url, { signal: getAdvisoryByIdController.signal });
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const createAdvisory = payload => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory`;
		const response = await axiosInstance.post(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const updateAdvisory = (advisoryId, payload) => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory/${advisoryId}`;
		const response = await axiosInstance.put(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const deleteAdvisory = advisoryId => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory/${advisoryId}`;
		const response = await axiosInstance.delete(url);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const togglePublishAdvisory = (id, isPublished) => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory/${id}/toggle-publish/${isPublished ? 1 : 0}`;
		const response = await axiosInstance.patch(url);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const toggleFlagAdvisory = (id, flag) => async dispatch => {
	try {
		const url = `${BASE_URL}/advisory/${id}/toggle-flag/${flag ? 1 : 0}`;
		const response = await axiosInstance.patch(url);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const getActiveAdvisories = () => async dispatch => {
	try {
		const url = `${BASE_URL}/active-advisories`;
		const response = await axiosInstance.get(url);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};
