import { useDispatch } from 'react-redux';
import { setAlertMessage } from 'slices/globalSlice';
import { ErrorBoundary } from '@sentry/react';

const CustomErrorBoundary = ({ children }) => {
	const dispatch = useDispatch();

	const ErrorFallback = () => null;

	const onError = () => {
		dispatch(
			setAlertMessage({
				type: 'error',
				errors: [{ detail: 'An error occurred and has been reported to our team.' }],
				isOpen: true
			})
		);
	};

	return (
		<ErrorBoundary fallback={ErrorFallback} onError={onError}>
			{children}
		</ErrorBoundary>
	);
};

export default CustomErrorBoundary;
