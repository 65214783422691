import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputLabel from 'components/elements/InputLabel';
import { fetchLocationByCoord } from 'apis/mapbox.api';
import { $gray400, $primary, $white } from 'constants/styles/_colors';
import { elevation14 } from 'constants/styles/_variables';
import { Box, Button, TextField } from '@mui/material';
import LocationSearchBox from 'components/elements/LocationSearchBox';
import { isValidCoordinates } from 'utils/mapHelpers';
import { locationDetailsSelector } from 'slices/mapSlice';

const styles = {
	sectionTitle: { display: 'flex', alignItems: 'center' },
	linkButton: {
		fontSize: '12px',
		// lineHeight: '13px',
		paddingLeft: '5px !important',
		color: $primary,
		padding: '0px',
		textTransform: 'uppercase',
		fontWeight: 500,
		cursor: 'pointer'
	},
	searchInputContainer: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `1px solid ${$gray400}`,
		marginTop: '10px',
		'&:hover': {
			borderBottom: `1px solid ${$primary}`
		},
		'&:focus': {
			border: 'none'
		}
	},
	coordInputContainer: {
		marginTop: '5px',
		display: 'grid',
		gridTemplateColumns: '150px 150px auto',
		gap: '10px'
	},
	searchContainer: {
		width: '100%',
		'& #react-geo-list': {
			position: 'absolute',
			zIndex: 10,
			top: '20px',
			left: '-26px',
			width: '450px',
			background: $white,
			borderRadius: '5px',
			...elevation14,
			'& li': {
				padding: '15px 0px',
				fontSize: '16px',
				'&:last-child': {
					paddingBottom: '30px'
				}
			}
		}
	},
	inputRoot: {
		marginTop: '10px !important'
	},
	linkStyle: {
		padding: '5px 10px'
		// color: $primary,
		// textTransform: 'none'
	},
	setLocation: {
		display: 'flex',
		alignItems: 'flex-end',
		fontSize: '18px',
		marginTop: '5px',
		marginBottom: '20px'
	},
	applyCoordinatesButton: {
		marginLeft: '5px',
		marginTop: '15px'
	}
};

function LocationSearchOrCoordinateInput({ onLocationSelect, required = false, width }) {
	const dispatch = useDispatch();

	// State
	const [searchType, setSearchType] = useState('search');
	const [searchedLocation, setLocation] = useState('Search for a location');
	const [inputLatitude, setLat] = useState('');
	const [inputLongitude, setLong] = useState('');
	const [watchInput, setWatchInput] = useState(false);
	const coordinatesValid = isValidCoordinates(inputLatitude, inputLongitude);

	// Redux Store
	const locationDetails = useSelector(locationDetailsSelector);

	useEffect(() => {
		if (!locationDetails) {
			setLocation('Search for a location');
			setLat('');
			setLong('');
		} else {
			//Set value of this component to the locationDetails from redux if it exists
			const coord = locationDetails?.geometry?.coordinates;
			setLocation(truncate(locationDetails?.properties?.place_name || ''));
			setLat(Number(coord[1]).toFixed(5));
			setLong(Number(coord[0]).toFixed(5));
		}
	}, [locationDetails]);

	const truncate = value => {
		const count = 27;
		if (value.length > count) {
			const trimmed = value.substring(0, count);
			return `${trimmed}...`;
		}
		return value;
	};

	const toggleSearchType = type => {
		setSearchType(type);
	};

	/**
	 * @description
	 * handle GeoCoder search and state update
	 */
	const handleLocationSelect = val => {
		if (!val) return;

		const coordinates = val?.geometry?.coordinates;
		if (val.place_name) {
			setLocation(truncate(val.place_name));
		}
		setLat(coordinates[1]);
		setLong(coordinates[0]);
		onLocationSelect(val);
	};

	const setManualCoordInput = async () => {
		const coord = `${inputLongitude},${inputLatitude}`;
		await dispatch(fetchLocationByCoord(coord));
	};

	const applyCoordinates = (e, type) => {
		e.preventDefault();
		if (e.which === 13) {
			setManualCoordInput();
			setWatchInput(true);
		}
	};

	const renderSearchFields = () => {
		if (searchType === 'search') {
			return (
				<>
					<Box sx={styles.sectionTitle}>
						<InputLabel styles={{ textTransform: 'none' }} title={'SEARCH FOR A LOCATION OR'} />
						<Box
							component='span'
							sx={styles.linkButton}
							onClick={() => toggleSearchType('coordinates')}
						>
							Input Coordinates
						</Box>
					</Box>
					<LocationSearchBox
						onSelect={handleLocationSelect}
						inputPlaceholder={searchedLocation}
						width={width}
					/>
				</>
			);
		} else {
			return (
				<>
					<Box sx={styles.searchContainer}>
						<Box sx={styles.coordInputContainer}>
							<Box>
								<InputLabel styles={{ textTransform: 'none' }} title={'LAT:'} />
								<TextField
									variant='standard'
									id='lat'
									type='number'
									sx={styles.inputRoot}
									placeholder={inputLatitude}
									value={inputLatitude}
									onKeyUp={e => applyCoordinates(e)}
									margin='normal'
									onChange={e => setLat(e.target.value)}
								/>
								{!inputLatitude && watchInput && (
									<Box component='span' className='error'>
										required
									</Box>
								)}
							</Box>
							<Box>
								<InputLabel styles={{ textTransform: 'none' }} title={'LONG:'} />
								<TextField
									variant='standard'
									id='long'
									type='number'
									sx={styles.inputRoot}
									placeholder={inputLongitude}
									value={inputLongitude}
									onKeyUp={e => applyCoordinates(e)}
									margin='normal'
									onChange={e => setLong(e.target.value)}
								/>
								{!inputLongitude && watchInput && (
									<Box component='span' className='error'>
										required
									</Box>
								)}
							</Box>
							<Box>
								<Box
									component='span'
									sx={[styles.linkButton, styles.addressButton]}
									onClick={() => toggleSearchType('search')}
								>
									Switch to Location Search
								</Box>
								<Box>
									<Button
										primary
										variant='outlined'
										disabled={!inputLatitude || !inputLongitude || !coordinatesValid}
										onClick={() => setManualCoordInput()}
										sx={styles.applyCoordinatesButton}
										add='true'
										size='small'
									>
										apply coordinates
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</>
			);
		}
	};

	return <Box sx={styles.searchContainer}>{renderSearchFields()}</Box>;
}

LocationSearchOrCoordinateInput.propTypes = {
	onLocationSelect: PropTypes.func.isRequired,
	required: PropTypes.bool
};

export default LocationSearchOrCoordinateInput;
