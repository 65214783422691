import { Box, Drawer } from '@mui/material';
import Header from 'components/elements/Header';
import ProfileView from 'components/profile/ProfileView';
import ProfileEdit from 'components/profile/ProfileEdit';
import AdvisoryView from 'components/advisory/AdvisoryView';
import AdvisoryCreateOrEdit from 'components/advisory/AdvisoryCreateOrEdit';
import Table from 'components/elements/Table';
import ButtonContained from 'components/elements/ButtonContained';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { drawerOpenSelector, openDrawer } from 'slices/globalSlice';
import { editingProfileSelector, profileSelector, setEditingProfile } from 'slices/profileSlice';
import { advisoriesColumns } from 'constants/generalConstants';
import {
	advisoriesListSelector,
	advisoriesLoadedSelector,
	creatingAdvisorySelector,
	editingAdvisorySelector,
	setAdvisoriesList,
	setAdvisoriesLoaded,
	setCreatingAdvisory
} from 'slices/advisoriesSlice';
import { useEffect, useState, useCallback } from 'react';
import { closeAdvisoryDrawer, openDrawerAndFetchAdvisory } from 'actions/advisories.actions';
import { getAdvisoriesListByAccountId } from 'apis/advisories.api';
import { useHistory } from 'react-router-dom';
import { userSelector } from 'slices/authenticationSlice';
import AdvisoryTableActions from 'components/elements/AdvisoryTableActions';
import ErrorBoundary from 'components/elements/ErrorBoundary';

const MainContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const currentUser = useSelector(userSelector);
	const profile = useSelector(profileSelector);
	const editingProfile = useSelector(editingProfileSelector);
	const editingAdvisory = useSelector(editingAdvisorySelector);
	const creatingAdvisory = useSelector(creatingAdvisorySelector);
	const advisoriesList = useSelector(advisoriesListSelector);
	const advisoriesLoaded = useSelector(advisoriesLoadedSelector);
	const drawerOpen = useSelector(drawerOpenSelector);
	const [tableLoading, setTableLoading] = useState(false);
	const [selectedAdvisories, setSelectedAdvisories] = useState([]);
	const [selectionModel, setSelectionModel] = useState([]);

	if (currentUser?.is_admin) {
		//Prevent admins from accessing portal route
		history.push('/admin');
	}

	const styles = {
		main: {
			width: '100%',
			height: '100vh',
			paddingLeft: '20px',
			paddingRight: '20px',
			backgroundColor: 'white'
		},
		tableContainer: {
			height: 'calc(100% - 298px)'
		},
		drawer: {
			'& .MuiDrawer-paper': {
				width: '660px'
			}
		}
	};

	const handleClickContact = () => {
		window.open('mailto:geo@aloft.ai');
	};

	const handleClickEditProfile = () => {
		dispatch(setEditingProfile(true));
	};

	const handleClickAddAdvisory = () => {
		dispatch(openDrawer());
		dispatch(setCreatingAdvisory(true));
	};

	const handleAdvisoryRowClick = async ({ data }) => {
		dispatch(openDrawerAndFetchAdvisory(data?.id));
	};

	const handleCloseDrawer = () => {
		dispatch(closeAdvisoryDrawer());
	};

	const handleSelectionModelChange = selectionModel => {
		const advisoriesSelected = advisoriesList.filter(a => selectionModel.includes(a.id));
		setSelectedAdvisories(advisoriesSelected);
		setSelectionModel(selectionModel);
	};

	const fetchAdvisoriesList = useCallback(
		async id => {
			setTableLoading(true);
			const res = await dispatch(getAdvisoriesListByAccountId(id));
			if (res) {
				dispatch(setAdvisoriesList(res));
				dispatch(setAdvisoriesLoaded(true));
				setTableLoading(false);
			} else {
				setTableLoading(false);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (!tableLoading && !advisoriesLoaded && profile?.id) {
			fetchAdvisoriesList(profile.id);
		}
	}, [tableLoading, advisoriesLoaded, profile?.id, fetchAdvisoriesList]);

	return (
		<Box sx={styles.main}>
			<Header
				title='Aloft Geo Portal'
				button={<ButtonContained onClick={handleClickContact}>CONTACT ALOFT</ButtonContained>}
			/>
			<ErrorBoundary>
				{!editingProfile && (
					<ProfileView
						button={<ButtonOutlined onClick={handleClickEditProfile}>EDIT PROFILE</ButtonOutlined>}
						profile={profile}
					/>
				)}
				{editingProfile && <ProfileEdit profile={profile} accountStatusEditable={true} />}
			</ErrorBoundary>
			<Box sx={styles.tableContainer}>
				<ErrorBoundary>
					<Table
						columns={advisoriesColumns}
						rows={advisoriesList}
						loading={tableLoading}
						title='Advisories List'
						noDataMessage='No Advisories'
						button={<ButtonOutlined onClick={handleClickAddAdvisory}>ADD ADVISORY</ButtonOutlined>}
						onCellClick={handleAdvisoryRowClick}
						checkboxSelection
						onSelectionModelChange={handleSelectionModelChange}
						selectionModel={selectionModel}
						actions={
							<AdvisoryTableActions
								selectedItems={selectedAdvisories}
								afterAction={() => {
									handleSelectionModelChange([]);
									fetchAdvisoriesList(profile.id);
								}}
							/>
						}
					/>
				</ErrorBoundary>
			</Box>
			<Drawer
				anchor='right'
				open={drawerOpen}
				elevation={16}
				BackdropProps={{ invisible: true }}
				sx={styles.drawer}
				onClose={handleCloseDrawer}
			>
				<ErrorBoundary>
					{drawerOpen && !editingAdvisory && !creatingAdvisory && <AdvisoryView />}
					{drawerOpen && (editingAdvisory || creatingAdvisory) && <AdvisoryCreateOrEdit />}
				</ErrorBoundary>
			</Drawer>
		</Box>
	);
};
export default MainContainer;
