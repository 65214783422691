import { $success } from 'constants/styles';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { point } from '@turf/helpers';

export const addMapControls = ({ map, mapboxgl }) => {
	map.addControl(
		new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}),
		'top-left'
	);
	map.addControl(new mapboxgl.NavigationControl(), 'top-left');
	map.addControl(new MapboxStyleSwitcherControl(), 'top-left');
};

export const createGeoJSONFeatureLayer = ({ type, id, feature, paint, layout }) => {
	return {
		type,
		id,
		source: {
			type: 'geojson',
			data: feature
		},
		paint: {
			...paint
		},
		layout: {
			...layout
		}
	};
};

const fillId = 'fill';
const lineId = 'line';

export const clearFeatureLayer = map => {
	if (map.getLayer(fillId)) {
		map.removeLayer(fillId);
	}
	if (map.getSource(fillId)) {
		map.removeSource(fillId);
	}
	if (map.getLayer(lineId)) {
		map.removeLayer(lineId);
	}
	if (map.getSource(lineId)) {
		map.removeSource(lineId);
	}
};

export const mapDrawStyles = [
	// ACTIVE (being drawn)
	// line stroke
	{
		id: 'gl-draw-line',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#36D18A',
			// 'line-dasharray': [0.2, 2],
			'line-width': 6
		}
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'fill-color': '#36D18A',
			'fill-outline-color': '#36D18A',
			'fill-opacity': 0.4
		}
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'line-color': '#fff',
			'line-opacity': 1,
			'line-width': 6
		},
		layout: {
			'line-join': 'round',
			'line-cap': 'round'
		}
	},
	// vertex point halos
	{
		id: 'gl-draw-polygon-and-line-vertex-halo-active',
		type: 'symbol',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'icon-opacity': 1
		},
		layout: {
			'icon-image': 'hospital-15',
			'icon-size': 1
		}
	},
	// vertex points
	{
		id: '',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 10, // size polygon draggable points
			'circle-color': '#fff', // color polygon draggable points
			'circle-opacity': 0.75 // opacity polygon draggable points
		}
	},
	// midpoints
	{
		id: 'circle-midpoint',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 5, // size polygon draggable points
			'circle-color': '#000', // color polygon draggable points
			'circle-opacity': 0.25 // opacity polygon draggable points
		}
	}
];

export const feetInMile = 5280;

export const convertFeetToMiles = feet => {
	return feet / feetInMile;
};

export const convertMilesToFeet = miles => {
	return miles * feetInMile;
};

export const createTenthMileBboxFromPoint = point => {
	const circleFeature = circle(point, 0.1, { units: 'miles' });
	const circleBBox = bbox(circleFeature);
	return circleBBox;
};

export const emptyFeatureCollection = {
	type: 'FeatureCollection',
	features: []
};

export const isLargeFeatureCollection = fc => {
	return fc?.features?.length >= 20;
};

export const hideLargeFCFromDevTools = mapSlice => {
	if (isLargeFeatureCollection(mapSlice.featureCollection)) {
		return {
			...mapSlice,
			featureCollection: 'Too Large for DevTools'
		};
	} else {
		return mapSlice;
	}
};

export const lineLayout = {
	'line-join': 'round',
	'line-cap': 'round'
};
export const linePaint = {
	'line-color': $success,
	'line-opacity': 1,
	'line-width': 6
};

export const fillPaint = {
	'fill-color': $success,
	'fill-opacity': 0.5
};

export const featureCollectionHasFeatures = fc => fc?.features?.length > 0;
export const featuresHasCoordinates = f => f?.geometry?.coordinates?.length > 0;

export const featureCollectionFeaturesAreDefined = fc => {
	return featureCollectionHasFeatures(fc) && fc?.features.every(f => featuresHasCoordinates(f));
};

export const coordinatesRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/gim;

export const looksLikeCoordinates = value => {
	const matchedValue = value.match(coordinatesRegex);
	return matchedValue ? matchedValue.length > 0 : false;
};

export const createPointFeature = ({ coordinates, id, properties, lat, long, ...rest }) => {
	if (coordinates && (lat || long)) {
		throw new Error(
			'Invalid parameters - Provide either a coordinates parameter OR lat and long parameters.'
		);
	}

	if (!coordinates && (lat === undefined || long === undefined)) {
		throw new Error('The coordinates parameter OR lat and long parameters are required.');
	}
	const coords = coordinates ? coordinates : [long, lat];
	const geoJSONPoint = point(coords, properties, { id });
	return {
		...geoJSONPoint,
		...rest
	};
};

export const convertCoordinatesToMapboxLocation = ({ lat, long, optionType }) => {
	return createPointFeature({
		lat,
		long,
		id: `${long}, ${lat}`,
		center: [long, lat],
		address: '-',
		place_name: `LAT: ${Number(lat).toFixed(6)} || LONG: ${Number(long).toFixed(6)}`,
		optionType
	});
};

export const isValidCoordinates = (lat, long) => {
	const latitude = lat >= -90 && lat <= 90 ? true : false; // lat -90, 90
	const longitude = long >= -180 && long <= 180 ? true : false; // long -180, 180
	return [latitude, longitude].every(el => el);
};

export const convertMapboxLocationToPointFeature = location => {
	const lat = location.geometry.coordinates[1];
	const long = location.geometry.coordinates[0];
	const locationDetails = createPointFeature({
		lat,
		long,
		properties: {
			place_name: location.place_name
		}
	});
	return locationDetails;
};
