import { MAP_STYLE, TOKEN } from 'constants/localStorageConstants';
import { defaultMapStyle } from 'constants/generalConstants';

export const saveMapStyleToLS = map => {
	const mapStyle = map.getStyle().metadata['mapbox:origin'];
	localStorage.setItem(MAP_STYLE, mapStyle);
};

export const getMapStyleFromLS = () => {
	return localStorage.getItem(MAP_STYLE) || defaultMapStyle;
};

export async function setToken(tempToken) {
	await localStorage.setItem(TOKEN, tempToken);
}

export async function getToken() {
	let t = await localStorage.getItem(TOKEN);
	return t;
}
