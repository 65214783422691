import { Box } from '@mui/material';
import SectionHeader from 'components/elements/SectionHeader';
import LargeTitle from 'components/elements/LargeTitle';
import {
	DataGridPro,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiRef,
	LicenseInfo
} from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { selectedAdvisorySelector } from 'slices/advisoriesSlice';
import { blue } from '@mui/material/colors';
import { $bluishGray60 } from 'constants/styles';
import PropTypes from 'prop-types';
import { MUI_KEY } from 'constants/environmentVariables';
import { emptyFilterModel } from 'constants/localStorageConstants';
import { useState } from 'react';

LicenseInfo.setLicenseKey(MUI_KEY);

const Table = ({
	columns,
	rows,
	loading,
	title,
	noDataMessage,
	button,
	onCellClick,
	checkboxSelection = false,
	actions,
	localStorageFilterModelKey,
	selectionModel,
	onSelectionModelChange
}) => {
	const styles = {
		main: {
			height: '100%',
			padding: '20px',
			paddingBottom: '0px'
		},
		top: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		gridContainer: {
			height: title ? 'calc(100% - 37px)' : '100%',
			'& .selected-row': {
				backgroundColor: `${blue[50]} !important`
			}
		},
		dataGrid: {
			border: 'none !important',
			'& .MuiDataGrid-columnHeader': {
				justifyContent: 'flex-end',
				'&:focus-within': {
					outline: 'none !important'
				},
				'& .MuiDataGrid-columnHeaderTitle': {
					fontSize: '15px',
					fontWeight: 600
				}
			},
			'& .MuiDataGrid-row': {
				'&:hover': {
					cursor: onCellClick ? 'pointer' : 'default'
				}
			},
			'& .MuiDataGrid-cell': {
				fontSize: '15px',
				'&:focus-within': {
					outline: 'none !important'
				}
			}
		},
		noRows: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 24,
			color: $bluishGray60
		}
	};

	const selectedAdvisory = useSelector(selectedAdvisorySelector);

	const apiRef = useGridApiRef();
	const localStorageFilterModelValue = localStorage.getItem(localStorageFilterModelKey)
		? JSON.parse(localStorage.getItem(localStorageFilterModelKey))
		: emptyFilterModel;

	const [filterModel, setFilterModel] = useState(
		localStorageFilterModelKey ? localStorageFilterModelValue : emptyFilterModel
	);

	const noRowsOverlay = () => (
		<Box sx={styles.noRows}>
			<LargeTitle content={noDataMessage} />
		</Box>
	);

	const handleFilterChange = model => {
		setFilterModel(model);
		if (localStorageFilterModelKey) {
			localStorage.setItem(localStorageFilterModelKey, JSON.stringify(model));
		}
	};

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				{actions}
			</GridToolbarContainer>
		);
	};

	return (
		<Box sx={styles.main}>
			{title && (
				<Box sx={styles.top}>
					<SectionHeader content={title} />
					{button}
				</Box>
			)}
			<Box sx={styles.gridContainer}>
				<DataGridPro
					apiRef={apiRef}
					columns={columns}
					rows={rows}
					pagination
					rowCount={10}
					getRowId={row => row.id}
					paginationMode='server'
					components={{
						Toolbar: CustomToolbar,
						NoRowsOverlay: noRowsOverlay
					}}
					onCellClick={data => {
						data?.field !== '__check__' &&
							onCellClick &&
							onCellClick({ data: data.row, field: data.field });
					}}
					disableSelectionOnClick
					disableColumnMenu
					getRowClassName={params => selectedAdvisory?.id === params?.id && `selected-row`} // Used to style selected row
					sx={styles.dataGrid}
					hideFooter
					loading={loading}
					checkboxSelection={checkboxSelection}
					onFilterModelChange={handleFilterChange}
					filterModel={filterModel}
					onSelectionModelChange={onSelectionModelChange}
					selectionModel={selectionModel}
				/>
			</Box>
		</Box>
	);
};

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	title: PropTypes.string,
	noDataMessage: PropTypes.string.isRequired,
	button: PropTypes.element,
	onCellClick: PropTypes.func.isRequired,
	checkboxSelection: PropTypes.bool,
	localStorageFilterModelKey: PropTypes.string,
	onSelectionModelChange: PropTypes.func.isRequired,
	selectionModel: PropTypes.array.isRequired
};

export default Table;
