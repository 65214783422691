import { useState } from 'react';
import {
	Button,
	IconButton,
	InputAdornment,
	LinearProgress,
	TextField,
	Typography,
	Link,
	Grid
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { authenticationPagesStyles } from 'constants/styles';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { resetPasswordEmailSelector } from 'slices/authenticationSlice';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from 'utils/errorMessages';
import { objectHasKeys } from 'utils/helpers';
import {
	validateEmailRegex,
	validatePasswordShape,
	validateSixDigitCode
} from 'utils/validateForm';
import AloftLogo from 'assets/aloft_logo_white.png';
import AuthenticationFooter from 'components/authentication/AuthenticationFooter';
import CompanyLogos from 'components/authentication/CompanyLogos';
import { resetPassword } from 'apis/authentication.api';
import { TOKEN } from 'constants/localStorageConstants';

const styles = theme => ({
	...authenticationPagesStyles(theme)
});

const ResetPassword = () => {
	const history = useHistory();
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showLoader, setShowLoader] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const resetPasswordEmail = useSelector(resetPasswordEmailSelector);

	const token = localStorage.getItem(TOKEN);
	if (token) {
		//Redirect to root if user is logged in. This will then redirect to /portal or /admin
		history.push('/');
	}

	const {
		formState: { errors },
		control,
		handleSubmit,
		getValues
	} = useForm({
		defaultValues: {
			code: '',
			password: '',
			confirmPassword: '',
			email: resetPasswordEmail
		}
	});

	const handleClickSignUp = () => {
		let link = '/sign_up';
		history.push(link);
	};

	const handleResetPassword = async () => {
		const { password, code, email } = getValues();
		const payload = { email, password: password, pass_verify_code: code };
		setShowLoader(true);
		const res = await dispatch(resetPassword(payload));
		if (res) {
			history.push(`/sign_in`);
		}
		setShowLoader(false);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const validateConfirmPassword = value => {
		const { password } = getValues();
		return password && value && password === value;
	};

	const handleClickLogo = () => {
		history.push(`/sign_in`);
	};

	const hasErrors = objectHasKeys(errors);

	return (
		<form className={classes.main} onSubmit={handleSubmit(handleResetPassword)}>
			<div className={classes.headerContainer}>
				<img src={AloftLogo} alt='' onClick={handleClickLogo} className={classes.logo} />
				<Link
					href='https://www.aloft.ai/support/geo-portal/'
					target='_blank'
					rel='noopener noreferrer'
					underline='none'
				>
					<Button className={classes.outlineButton} variant='outlined'>
						Frequently Asked Questions
					</Button>
				</Link>
			</div>
			<div className={classes.mainContainer}>
				<div className={classes.authLogosContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} className={classes.leftContainer}>
							<div>
								<div className={classes.geoPortal}>Aloft Geo Portal</div>
								<Typography className={classes.authCopy} sx={{ margin: '30px 0' }}>
									If you don't have an account, <br /> You can
									<span className={classes.actionLink} onClick={handleClickSignUp}>
										Sign up here!
									</span>
								</Typography>
							</div>
							<Typography className={classes.authCopy}>Trusted by:</Typography>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.rightContainer}>
							<div elevation={0} className={classes.outlineContainer}>
								<div className={classes.formTitle}>
									Reset Password
									{showLoader && <LinearProgress />}
								</div>
								<>
									<>
										<Controller
											render={({ field }) => (
												<TextField
													{...field}
													placeholder='Email'
													variant='outlined'
													disabled={showLoader}
													className={classes.textFieldNoMargin}
													autoFocus
												/>
											)}
											name='email'
											control={control}
											rules={{
												required: true,
												validate: {
													emailAddress: validateEmailRegex
												}
											}}
										/>
										<ErrorMessage name='email' error={errors.email} className={classes.error} />
									</>
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												disabled={showLoader}
												placeholder='Reset Code'
												variant='outlined'
												className={classes.textField}
											/>
										)}
										name='code'
										control={control}
										rules={{
											required: true,
											validate: {
												sixDigitCode: validateSixDigitCode
											}
										}}
									/>
									<ErrorMessage name='code' error={errors.code} className={classes.error} />
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='New Password'
												inputProps={{ type: showPassword ? 'text' : 'password' }}
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<IconButton onClick={handleClickShowPassword} tabIndex={-1}>
																{showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										)}
										name='password'
										control={control}
										rules={{
											required: true,
											validate: {
												passwordShape: validatePasswordShape
											}
										}}
									/>
									<ErrorMessage name='password' error={errors.password} className={classes.error} />
									<Controller
										render={({ field }) => (
											<TextField
												{...field}
												placeholder='Confirm New Password'
												inputProps={{ type: showConfirmPassword ? 'text' : 'password' }}
												variant='outlined'
												disabled={showLoader}
												className={classes.textField}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<IconButton onClick={handleClickShowConfirmPassword} tabIndex={-1}>
																{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										)}
										name='confirmPassword'
										control={control}
										rules={{
											required: true,
											validate: {
												passwordMismatch: validateConfirmPassword
											}
										}}
									/>
									<ErrorMessage
										name='confirmPassword'
										error={errors.confirmPassword}
										className={classes.error}
									/>
									<Button
										color='primary'
										disabled={showLoader || hasErrors}
										className={classes.button}
										variant='contained'
										type='submit'
									>
										Submit
									</Button>
								</>
							</div>
						</Grid>
						<div className={classes.belowSignIn}>
							<Typography sx={{ textAlign: 'center' }}>
								If you don't have an account you can
								<span className={classes.actionLink} onClick={handleClickSignUp}>
									Sign up here!
								</span>
							</Typography>
						</div>
					</Grid>
					<CompanyLogos />
				</div>
			</div>
			<AuthenticationFooter />
		</form>
	);
};

export default ResetPassword;
