import { setAlertMessage } from 'slices/globalSlice';

export const handleApiResponse = ({
	response,
	dispatch,
	successCallback,
	showSuccessMessage = false,
	errorMessage = true
}) => {
	const responseCode = response.data.code;
	if (responseCode === 200) {
		if (showSuccessMessage) {
			dispatch(
				setAlertMessage({
					isOpen: true,
					type: 'success',
					message: response.data.message,
					duration: 20000
				})
			);
		}
		if (successCallback) {
			successCallback(response.data);
		}
		return response.data.result;
	} else {
		if (errorMessage) {
			dispatch(
				setAlertMessage({
					isOpen: true,
					type: 'error',
					errors: [{ detail: response.data.message }]
				})
			);
		}
		return response.data;
	}
};

export const handleApiException = ({ error, dispatch, errorMessage = true }) => {
	if (error?.message === 'canceled') {
		//Request was canceled. No need to show error message.
		return;
	}
	if (errorMessage) {
		const errors = error?.response?.data?.errors;
		let errorMessage = error?.response?.data?.message;
		// Get the first error message from the list if there are multiple errors
		if (typeof errors === 'object' && errors !== null) {
			[errorMessage] = Object.entries(errors).reduce((acc, pairs) => {
				return [...acc, ...pairs[1]];
			}, []);
		}
		dispatch(
			setAlertMessage({
				isOpen: true,
				type: 'error',
				errors: [{ detail: errorMessage }]
			})
		);
		const responseCode = error?.response?.data?.status;
		if (responseCode === 401 || responseCode === 403) {
			//Token is not valid or user does not have permission
			localStorage.clear();
			window.location.assign(`${origin}/sign_in`);
		}
	}
	return error;
};
