import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'slices';
import { TOKEN, USER_PROFILE } from 'constants/localStorageConstants';
import { hideLargeFCFromDevTools } from 'utils/mapHelpers';
import { sentryReduxEnhancer } from 'services/sentry';

export const setupStore = props => {
	return configureStore({
		reducer: rootReducer,
		// Middleware setup to disable warnings. Ref: https://redux-toolkit.js.org/api/getDefaultMiddleware#api-reference
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: false,
				immutableCheck: false
			}),
		enhancers: [sentryReduxEnhancer],
		preloadedState: {
			profile: {
				editingProfile: false
			},
			authentication: {
				user: {
					...JSON.parse(localStorage.getItem(USER_PROFILE)),
					token: localStorage.getItem(TOKEN)
				},
				resetPasswordEmail: null,
				signUpEmail: null
			}
		},
		devTools: {
			stateSanitizer: state => ({
				...state,
				map: hideLargeFCFromDevTools(state.map)
			})
		}
	});
};

export const reduxStore = setupStore();
