import Typography from '@mui/material/Typography';
import { $bluishGray60 } from 'constants/styles';
import PropTypes from 'prop-types';

const classStyles = {
	labelStyle: {
		padding: '5px 0',
		fontSize: '12px',
		lineHeight: '13px',
		textTransform: 'uppercase',
		color: $bluishGray60,
		fontWeight: 600
	}
};

function InputLabel({ title, styles }) {
	return (
		<Typography sx={classStyles.labelStyle} style={styles}>
			{title}
		</Typography>
	);
}

InputLabel.propTypes = {
	styles: PropTypes.object,
	title: PropTypes.string
};

export default InputLabel;
