import { Mapbox } from 'services/mapboxConfig';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { defaultMapCenter } from 'constants/generalConstants';
import { useEffect, useState } from 'react';
import { addMapControls, linePaint, lineLayout, fillPaint } from 'utils/mapHelpers';
import mapboxgl from 'mapbox-gl';
import { getMapStyleFromLS, saveMapStyleToLS } from 'actions/localStorage';
import PropTypes from 'prop-types';
import { Layer, Source } from 'react-mapbox-gl';

const MapView = ({ featureCollection }) => {
	const mapStyle = getMapStyleFromLS();
	const mapBounds = bbox(featureCollection);
	const mapContainerStyle = { height: '100%', width: '100%' };
	const [mapCenter, setMapCenter] = useState(defaultMapCenter);
	const sourceId = 'advisorySource';

	useEffect(() => {
		const featureCenter = center(featureCollection);
		const featureCenterCoords = featureCenter.geometry.coordinates;
		setMapCenter(featureCenterCoords);
	}, [featureCollection]);

	const onStyleLoad = map => {
		addMapControls({ map, mapboxgl });
	};

	return (
		<Mapbox
			onStyleData={saveMapStyleToLS}
			style={`mapbox://styles/mapbox/${mapStyle}`}
			containerStyle={mapContainerStyle}
			fitBounds={mapBounds}
			center={mapCenter}
			movingMethod='jumpTo'
			onStyleLoad={onStyleLoad}
			fitBoundsOptions={{
				padding: 40,
				duration: 0
			}}
		>
			<Source
				id={sourceId}
				geoJsonSource={{
					type: 'geojson',
					data: featureCollection
				}}
			/>
			<Layer type='line' id='boundary' sourceId={sourceId} layout={lineLayout} paint={linePaint} />
			<Layer type='fill' id='area' sourceId={sourceId} paint={fillPaint} />
		</Mapbox>
	);
};

MapView.propTypes = {
	featureCollection: PropTypes.object.isRequired
};

export default MapView;
