import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hint } from '@mapbox/geojsonhint';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { isEqual } from 'lodash-es';
import { Box, Typography } from '@mui/material';
import { $danger, $primary, $white } from 'constants/styles';
import { emptyGeoJSONObject } from 'constants/generalConstants';
import { emptyFeatureCollection } from 'utils/mapHelpers';

const styles = {
	main: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		border: `1px solid ${$primary}`,
		marginBottom: '20px'
	},
	jsonValColor: {
		default: '#525252',
		keys: '#1da1f2',
		string: '#DAA520' // overrides theme colors with whatever color value you want
	},
	containerStyle: {
		width: '100%',
		padding: '5px 0 10px',
		background: $danger,
		textAlign: 'center',
		borderRadius: '0px 0px 5px 5px',
		border: `solid 1px ${$danger}`
	},
	contentStyle: {
		color: $white,
		margin: 0,
		fontSize: '1rem',
		borderRadius: '0px 0px 5px 5px',
		border: `solid 1px ${$danger}`
	}
};

const GeoJSONValidator = ({ geoJSON, handleGeoJsonInput, fullHeight }) => {
	const [errorList, setErrorList] = useState([]);
	const data = geoJSON ? JSON.parse(geoJSON) : emptyGeoJSONObject;

	const handleInput = e => {
		const val = e === undefined ? null : e.jsObject;

		if (val === null) {
			handleGeoJsonInput(val);
		}
		const errors = validate(e.jsObject);
		setErrorList(errors);

		if (errors.length === 0) {
			handleGeoJsonInput(val);
		} else {
			handleGeoJsonInput(emptyFeatureCollection);
		}
	};

	const validate = geoJSON => {
		const hintGeoJsonErrors = hint(geoJSON);
		const featureType = geoJSON && geoJSON.features.length > 0 && geoJSON.features[0].geometry.type;
		const errors = hintGeoJsonErrors;
		if (featureType === 'Point') {
			const pointError = { message: 'Feature can not be a point.' };
			errors.push(pointError);
		}
		return errors;
	};

	useEffect(() => {
		const errors = validate(data);
		if (!isEqual(errors, errorList)) {
			setErrorList(errors);
		}
	}, [data, errorList]);

	return (
		<Box sx={styles.main}>
			<JSONInput
				id='geojson_validator'
				placeholder={data}
				confirmGood={false}
				locale={locale}
				theme='light_mitsuketa_tribute'
				colors={styles.jsonValColor}
				height={fullHeight ? '100%' : '300px'}
				width={'100%'}
				onChange={handleInput}
				onBlur={handleInput}
			/>
			{errorList.length > 0 && (
				<Box sx={styles.containerStyle}>
					{errorList.map((item, idx) => {
						return (
							<Typography key={idx} keys={idx + 1} paragraph sx={styles.contentStyle}>
								{item.message}
							</Typography>
						);
					})}
				</Box>
			)}
		</Box>
	);
};

GeoJSONValidator.propTypes = {
	geoJSON: PropTypes.string,
	handleGeoJsonInput: PropTypes.func.isRequired
};

export default GeoJSONValidator;
