// This expression matches email addresses, and checks that they are of the proper form. Taken from regexr.com/2rhq7
export const emailAddressRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

// This expression matches a hyphen separated US phone number, of the form ANN-NNN-NNNN, where A is between 2 and 9 and N is between 0 and 9.
export const phoneNumberRegexHyphens = /^[2-9]\d{2}-\d{3}-\d{4}$/;

export const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,128}$/;
// At least 1 number
// At least 1 uppercase letter
// At least 1 lowercase letter
// At least 1 special character
// At least 8 characters
// No more than 128 characters

export const coordinatesRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/gim;

export const urlRegex = /^(http|https):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,6}(\/\S*)?$/;
