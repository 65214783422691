import { SENTRY_DSN, REACT_APP_ENV, REACT_APP_VERSION } from 'constants/environmentVariables';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () => {
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: REACT_APP_ENV,
		integrations: [new BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
		release: REACT_APP_VERSION,
		normalizeDepth: 10
	});
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
