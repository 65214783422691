import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const classStyles = {
	contentStyle: {
		padding: '5px 0',
		paddingBottom: '10px',
		fontSize: '19px',
		lineHeight: '22px',
		fontWeight: 700,
		margin: 0,
		minHeight: '36px'
	}
};

function SectionHeader(props) {
	const { content, textColor, gutterBottom = true, styles } = props;

	return (
		<Typography
			variant='h5'
			component='p'
			color={textColor}
			sx={classStyles.contentStyle}
			gutterBottom={gutterBottom}
			style={styles}
		>
			{content}
		</Typography>
	);
}
SectionHeader.propTypes = {
	content: PropTypes.string,
	textColor: PropTypes.string,
	gutterBottom: PropTypes.bool,
	styles: PropTypes.object
};

export default SectionHeader;
