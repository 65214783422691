import { Route, Switch, withRouter } from 'react-router-dom';
import AlertMessage from 'components/elements/AlertMessage';
import App from 'components/App';
import SignIn from 'components/authentication/SignIn';
import SignUp from 'components/authentication/SignUp';
import ConfirmSignUp from 'components/authentication/ConfirmSignUp';
import ResetPassword from 'components/authentication/ResetPassword';
import MainContainer from 'components/main/MainContainer';
import AdminContainer from 'components/admin/AdminContainer';
import AuthenticationRedirector from 'components/authentication/AuthenticationRedirector';
import PrivateRoute from 'components/elements/PrivateRoute';
import Terms from 'components/Terms';
import AccountVerificationPolicy from 'components/AccountVerificationPolicy';

const Routes = () => {
	return (
		<>
			<Switch>
				<Route path='/' exact component={AuthenticationRedirector} />
				<Route path='/sign_in' exact component={SignIn} />
				<Route path='/sign_up' exact component={SignUp} />
				<Route path='/confirm_sign_up' exact component={ConfirmSignUp} />
				<Route path='/reset_password' exact component={ResetPassword} />
				<Route path='/terms' component={Terms} />
				<Route path='/verification_policy' component={AccountVerificationPolicy} />
				<App>
					<PrivateRoute path='/portal' exact component={MainContainer} />
					<PrivateRoute path='/admin' exact component={AdminContainer} />
					<PrivateRoute path='/admin/:accountId' exact component={AdminContainer} />
				</App>
			</Switch>
			<AlertMessage />
		</>
	);
};

export default withRouter(Routes);
