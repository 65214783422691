import {
	accountStatuses,
	advisoryColorsAndLabels,
	advisoryRestrictionLevels,
	advisoryStatuses,
	dateTimeFormat,
	emailVerificationStatuses
} from 'constants/generalConstants';

import dayjs from 'utils/customDayJS';

export const objectHasKeys = obj => {
	if (!obj) {
		return false;
	}
	return Object.keys(obj).length > 0;
};

export const getAccountStatusColor = status => {
	return accountStatuses.find(s => s?.value === status)?.color;
};

export const getAdvisoryStatusColor = status => {
	return advisoryStatuses.find(s => s?.value === status)?.color;
};

export const getAdvisoryRestrictionLevelColor = level => {
	return advisoryRestrictionLevels.find(l => l?.value === level)?.color;
};

export const getEmailVerificationStatusColor = status => {
	return emailVerificationStatuses.find(s => s?.value === status)?.color;
};

export const getAdvisoryRestrictionLevelObject = status => {
	return advisoryColorsAndLabels.find(s => s.value === status);
};

export const utcToLocalTime = utcTime => {
	const localTime = dayjs.utc(utcTime).tz(dayjs.tz.guess());
	return formatDateToString(localTime);
};

export const localToUTCUnix = localTime => {
	const utcTime = dayjs(localTime).utc();
	return formatDateToUnix(utcTime);
};

export const formatDateToString = time => {
	return dayjs(time).tz(dayjs.tz.guess()).format(dateTimeFormat);
};

export const formatDateToUnix = utcTime => {
	return dayjs(utcTime).unix();
};

export const getTimeZoneOffsetStringFromTime = time => {
	return dayjs(time).format('Z');
};

export const getTimeZoneOffsetLabel = time => {
	const offsetHoursResult = getTimeZoneOffsetStringFromTime(time); // -06:00
	const splitHoursAndMinutes = offsetHoursResult.split(':');
	const hours = splitHoursAndMinutes[0]; // -06
	const offsetDirectionSign = hours.charAt(0); // -
	const offsetHoursNumber = Number(hours.substring(1)); // 6
	const minutes = Number(splitHoursAndMinutes[1]);
	const finalOffsetHours = offsetHoursNumber + minutes / 60; // Account for timezones that are on the half hour
	return `(${offsetDirectionSign}${Number(finalOffsetHours)} Hours UTC/GMT)`;
};
