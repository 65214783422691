import { useState } from 'react';
import { setResetPasswordEmail, setUser } from 'slices/authenticationSlice';
import {
	Button,
	IconButton,
	InputAdornment,
	LinearProgress,
	TextField,
	Typography,
	Link,
	Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { authenticationPagesStyles } from 'constants/styles';
import AloftLogo from 'assets/aloft_logo_white.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthenticationFooter from 'components/authentication/AuthenticationFooter';
import CompanyLogos from 'components/authentication/CompanyLogos';
import { requestPasswordReset, signIn } from 'apis/authentication.api';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { emailAddressRegex } from 'utils/regexHelpers';
import { setProfile } from 'slices/profileSlice';
import { TOKEN, USER_PROFILE } from 'constants/localStorageConstants';
import { setToken } from 'actions/localStorage';
import * as Sentry from '@sentry/react';

const styles = theme => ({
	...authenticationPagesStyles(theme)
});

const SignIn = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();
	const useStyles = makeStyles(styles);
	const classes = useStyles();
	const dispatch = useDispatch();

	const token = localStorage.getItem(TOKEN);
	if (token) {
		//Redirect to root if user is logged in. This will then redirect to /portal or /admin
		history.push('/');
	}

	const handleUsernameChange = e => {
		const value = e.target.value;
		setUsername(value);
	};

	const handlePasswordChange = e => {
		const value = e.target.value;
		setPassword(value);
	};

	const handleForgotPassword = async () => {
		setShowLoader(true);
		const payload = {
			email: username
		};
		const res = await dispatch(requestPasswordReset(payload));
		const lowercaseUsername = username.toLowerCase();
		dispatch(setResetPasswordEmail(lowercaseUsername));
		if (res) {
			let resetPasswordURL = `/reset_password`;
			history.push(resetPasswordURL);
		}
		setShowLoader(false);
	};

	const handleLogin = async () => {
		setShowLoader(true);
		const payload = {
			email: username,
			password: password
		};
		const res = await dispatch(signIn(payload));
		if (res?.id) {
			setShowLoader(false);
			if (res?.is_email_verified) {
				dispatch(setUser(res));
				dispatch(setProfile(res));
				Sentry.setUser({
					id: res.id,
					email: res.email
				});
				localStorage.setItem(USER_PROFILE, JSON.stringify(res));
				await setToken(res.token);
				if (res?.is_admin) {
					history.push('/admin');
				} else {
					history.push('/portal');
				}
			} else {
				history.push('/confirm_sign_up');
			}
		} else {
			setShowLoader(false);
		}
	};

	const handleClickSignUp = () => {
		let link = '/sign_up';
		if (username) {
			link.concat(`?email=${username}`);
		}
		history.push(link);
	};

	const handleClickLogo = () => {
		history.push(`/sign_in`);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handlePasswordKeyDown = e => {
		// Trigger login on Enter
		if (e.keyCode === 13) {
			handleLogin();
		}
	};

	const forgotPasswordDisabled = !emailAddressRegex.test(username) || showLoader;

	return (
		<div className={classes.main}>
			<div className={classes.headerContainer}>
				<img src={AloftLogo} alt='' onClick={handleClickLogo} className={classes.logo} />
				<Link
					href='https://www.aloft.ai/support/geo-portal/'
					target='_blank'
					rel='noopener noreferrer'
					underline='none'
				>
					<Button className={classes.outlineButton} variant='outlined'>
						Frequently Asked Questions
					</Button>
				</Link>
			</div>
			<div className={classes.mainContainer}>
				<div className={classes.authLogosContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} className={classes.leftContainer}>
							<div>
								{/* <div className={classes.signIn}>Sign in to</div> */}
								<div className={classes.geoPortal}>Aloft Geo Portal</div>
								<Typography className={classes.authCopy} sx={{ marginTop: '10px' }}>
									Publish safety advisories directly to Aloft and B4UFLY airspace applications,
									reaching the largest audience of recreational, commercial, and government drone
									pilots.
								</Typography>
							</div>
							<Typography className={classes.authCopy}>
								If you don't have an account, <br /> You can
								<span className={classes.actionLink} onClick={handleClickSignUp}>
									Sign up here!
								</span>
							</Typography>
							<Typography className={classes.authCopy}>Trusted by:</Typography>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.rightContainer}>
							<div elevation={0} className={classes.outlineContainer}>
								<div className={classes.formTitle}>
									Sign In
									{showLoader && <LinearProgress />}
								</div>
								<TextField
									variant='outlined'
									onChange={handleUsernameChange}
									id='email'
									name='email'
									className={classes.textFieldNoMargin}
									placeholder={'Email'}
									value={username}
									autoFocus
								/>
								<TextField
									inputProps={{ type: showPassword ? 'text' : 'password' }}
									variant='outlined'
									onChange={handlePasswordChange}
									onKeyDown={handlePasswordKeyDown}
									id='password'
									name='password'
									className={classes.textField}
									placeholder={'Password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton onClick={handleClickShowPassword} tabIndex={-1}>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
								<Button
									variant='text'
									disabled={forgotPasswordDisabled}
									className={classes.forgotPassword}
									onClick={handleForgotPassword}
									color='primary'
									tabIndex={-1}
								>
									Forgot password?
								</Button>
								<Button
									className={classes.button}
									variant='contained'
									onClick={handleLogin}
									onKeyPress={e => {
										if (e.key === 'Enter') {
											handleLogin();
										}
									}}
									id='login'
									color='primary'
								>
									Submit
								</Button>
							</div>
							<div className={classes.belowSignIn}>
								<Typography>
									Publish safety advisories directly to Aloft and B4UFLY airspace applications,
									reaching the largest audience of recreational, commercial, and government drone
									pilots.
								</Typography>
								<Typography sx={{ marginTop: '10px' }}>
									If you don't have an account you can
									<span className={classes.actionLink} onClick={handleClickSignUp}>
										Sign up here!
									</span>
								</Typography>
							</div>
						</Grid>
					</Grid>
					<CompanyLogos />
				</div>
			</div>
			<AuthenticationFooter />
		</div>
	);
};

export default SignIn;
