export default function ErrroMessage({ error, className = 'inputError' }) {
	if (error) {
		switch (error.type) {
			case 'required':
			case 'mapFeatureDefined':
				return <p className={className}>This is required.</p>;
			case 'emailAddress':
				return <p className={className}>Enter a valid email address.</p>;
			case 'url':
				return <p className={className}>Enter a valid url. (Must contain http:// or https://)</p>;
			case 'phoneNumberCountry':
				return <p className={className}>Enter a valid phone number for the selected counry.</p>;
			case 'passwordMismatch':
				return <p className={className}>Passwords must match.</p>;
			case 'passwordShape':
				return (
					<p className={className}>
						Password must be at least 8 characters in length and contain at least 1 lowercase
						letter, 1 uppercase letter, 1 number, and 1 special character.
					</p>
				);
			case 'sixDigitCode':
				return <p className={className}>Code must be 6 digits long.</p>;
			case 'mapFeatureNoIntersect':
				return <p className={className}>Feature can not intersect itself.</p>;
			case 'greaterThanStartTime':
				return <p className={className}>Must be after start date and time.</p>;
			default:
				return null;
		}
	}

	return null;
}
