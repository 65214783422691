import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'slices/authenticationSlice';
import { TOKEN } from 'constants/localStorageConstants';

const AuthenticationRedirector = () => {
	const history = useHistory();
	const token = localStorage.getItem(TOKEN);
	const user = useSelector(userSelector);
	if (token) {
		if (user?.is_admin) {
			history.push('admin');
		} else {
			history.push('portal');
		}
	} else {
		history.push('sign_in');
	}

	return null;
};

export default AuthenticationRedirector;
