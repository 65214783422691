import { useRef, useState } from 'react';
import TableActionsEl from 'components/elements/TableActionsEl';
import ConfirmPopover from 'components/elements/ConfirmPopover';
import { every } from 'lodash-es';
import { rejectedStatus, verifiedStatus } from 'constants/generalConstants';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { updateAccount } from 'apis/accounts.api';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

//The anchor ref needs to be in the same file as the TableActionsEl and the ConfirmPopover in order for the popover to be placed correctly

const AccountTableActions = ({ selectedItems, afterAction }) => {
	const [confirmActionAnchorEl, setConfirmActionAnchorEl] = useState(null);
	const confirmActionPopupOpen = Boolean(confirmActionAnchorEl);
	const [actionCallback, setActionCallback] = useState(null);
	const [actionText, setActionText] = useState({ title: '', subtitle: '', confirmText: '' });
	const [actionLoading, setActionLoading] = useState(false);
	const [actionsAnchorEl, setActionsAnchorEl] = useState(false);
	const anchor = useRef();
	const dispatch = useDispatch();

	const handleBulkUpdateAccountStatus = async newStatus => {
		setActionLoading(true);
		for (const a of selectedItems) {
			await dispatch(
				updateAccount(a.id, {
					...a,
					account_status: newStatus
				})
			);
		}
		setActionLoading(false);
		closeConfirmPopupAndActionsMenu();
		afterAction();
	};

	const closeConfirmPopupAndActionsMenu = () => {
		setConfirmActionAnchorEl(null);
		handleCloseActionsMenu();
	};

	const accountTableActions = [
		{
			label: 'Verify',
			icon: <ThumbUpIcon />,
			onClick: e => {
				setConfirmActionAnchorEl(anchor.current);
				handleCloseActionsMenu();
				setActionText({
					title: 'Verify Accounts',
					subtitle: '',
					confirmText: 'Verify'
				});
				setActionCallback(() => () => handleBulkUpdateAccountStatus(verifiedStatus));
			},
			disabled:
				selectedItems.length === 0 ||
				!every(selectedItems, a => a.account_status !== verifiedStatus),
			disabledLabel: '(select 1 or more unverified accounts)'
		},
		{
			label: 'Reject',
			icon: <ThumbDownIcon />,
			onClick: e => {
				setConfirmActionAnchorEl(anchor.current);
				setActionText({
					title: 'Reject Accounts',
					subtitle: '',
					confirmText: 'Reject'
				});
				setActionCallback(() => () => handleBulkUpdateAccountStatus(rejectedStatus));
			},
			disabled:
				selectedItems.length === 0 ||
				!every(selectedItems, a => a.account_status !== rejectedStatus),
			disabledLabel: '(select 1 or more unrejected accounts)'
		}
	];

	const handleClickActionsButton = () => {
		setActionsAnchorEl(anchor.current);
	};

	const handleCloseActionsMenu = () => {
		setActionsAnchorEl(null);
	};

	return (
		<>
			<TableActionsEl
				actions={accountTableActions}
				anchor={anchor}
				handleClick={handleClickActionsButton}
				handleClose={handleCloseActionsMenu}
				open={Boolean(actionsAnchorEl)}
				anchorEl={actionsAnchorEl}
			/>
			<ConfirmPopover
				open={confirmActionPopupOpen}
				anchorEl={confirmActionAnchorEl}
				title={actionText.title}
				subTitle={actionText.subtitle}
				onCancel={closeConfirmPopupAndActionsMenu}
				onConfirm={actionCallback}
				cancelText={'Cancel'}
				confirmText={actionText.confirmText}
				loading={actionLoading}
				list
				items={selectedItems.map(a => ({ primary: a.organization_website, secondary: a.email }))}
			/>
		</>
	);
};

AccountTableActions.propTypes = {
	selectedItems: PropTypes.array.isRequired,
	afterAction: PropTypes.func.isRequired
};

export default AccountTableActions;
