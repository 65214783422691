import PropTypes from 'prop-types';
import {
	Box,
	Popover,
	Button,
	List,
	ListItem,
	ListItemText,
	Divider,
	LinearProgress
} from '@mui/material';

const styles = {
	main: {
		borderRadius: '13px',
		minWidth: '400px',
		maxWidth: '500px !important',
		maxHeight: '400px',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	headerContainer: {
		paddingTop: '20px',
		paddingBottom: '10px',
		paddingRight: '30px',
		paddingLeft: '30px'
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: '5px'
	},
	subTitle: {
		fontSize: 16
	},
	listContainer: {
		paddingLeft: '10px',
		overflowY: 'auto'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '10px',
		paddingBottom: '10px',
		paddingRight: '30px',
		paddingLeft: '30px'
	},
	cancelButton: {
		textTransform: 'none',
		fontSize: 18
	},
	deleteButton: {
		textTransform: 'none',
		fontSize: 18
	}
};
const ConfirmPopover = ({
	onConfirm,
	onCancel,
	open,
	anchorEl,
	title,
	subTitle,
	cancelText,
	confirmText,
	list,
	items,
	loading
}) => {
	return (
		<Popover
			open={open}
			onClose={onCancel}
			anchorEl={anchorEl}
			PaperProps={{
				sx: styles.main
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
		>
			{loading && <LinearProgress />}
			<Box sx={styles.headerContainer}>
				<Box sx={styles.title}>{title}</Box>
				<Box sx={styles.subTitle}>{subTitle}</Box>
			</Box>
			<Divider />
			{list && (
				<List sx={styles.listContainer}>
					{items.map((i, key) => (
						<Box key={key}>
							<ListItem>
								<ListItemText primary={i.primary} secondary={i.secondary} />
							</ListItem>
						</Box>
					))}
				</List>
			)}
			<Divider />
			<Box sx={styles.buttonContainer}>
				<Button onClick={onCancel} sx={styles.cancelButton} disabled={loading}>
					{cancelText}
				</Button>
				<Button onClick={onConfirm} sx={styles.deleteButton} disabled={loading}>
					{confirmText}
				</Button>
			</Box>
		</Popover>
	);
};

ConfirmPopover.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	onConfirm: PropTypes.func,
	confirmText: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
	cancelText: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	anchorEl: PropTypes.object,
	loading: PropTypes.bool,
	list: PropTypes.bool,
	items: PropTypes.array
};

export default ConfirmPopover;
