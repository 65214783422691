import { Box, CircularProgress, Divider, Drawer, Tab, Tabs } from '@mui/material';
import Header from 'components/elements/Header';
import ProfileView from 'components/profile/ProfileView';
import ProfileEdit from 'components/profile/ProfileEdit';
import AdvisoryView from 'components/advisory/AdvisoryView';
import AdvisoryCreateOrEdit from 'components/advisory/AdvisoryCreateOrEdit';
import Table from 'components/elements/Table';
import ButtonOutlined from 'components/elements/ButtonOutlined';
import ButtonContained from 'components/elements/ButtonContained';
import { useDispatch, useSelector } from 'react-redux';
import { drawerOpenSelector } from 'slices/globalSlice';
import {
	accountsColumns,
	activeAdvisoriesColumns,
	advisoriesColumns
} from 'constants/generalConstants';
import {
	activeAdvisoriesListLoadedSelector,
	activeAdvisoriesListSelector,
	advisoriesListSelector,
	advisoriesLoadedSelector,
	creatingAdvisorySelector,
	editingAdvisorySelector,
	setActiveAdvisoriesList,
	setActiveAdvisoriesListLoaded,
	setAdvisoriesList,
	setAdvisoriesLoaded
} from 'slices/advisoriesSlice';
import { useCallback, useEffect, useState } from 'react';
import { getActiveAdvisories, getAdvisoriesListByAccountId } from 'apis/advisories.api';
import { closeAdvisoryDrawer, openDrawerAndFetchAdvisory } from 'actions/advisories.actions';
import {
	accountsListSelector,
	accountsLoadedSelector,
	setAccountsList,
	setAccountsLoaded,
	setSelectedAccount
} from 'slices/adminSlice';
import { getAccountById, getAllAccountsList } from 'apis/accounts.api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	editingProfileSelector,
	profileSelector,
	setEditingProfile,
	setProfile
} from 'slices/profileSlice';
import { useHistory, useParams } from 'react-router-dom';
import { userSelector } from 'slices/authenticationSlice';
import { ADMIN_TABLE_FILTER } from 'constants/localStorageConstants';
import { clearSelectedAccountData } from 'actions/accounts.actions';
import AccountTableActions from 'components/elements/AccountTableActions';
import AdvisoryTableActions from 'components/elements/AdvisoryTableActions';
import ErrorBoundary from 'components/elements/ErrorBoundary';

const AdminContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { accountId } = useParams();
	const currentUser = useSelector(userSelector);
	const profile = useSelector(profileSelector);
	const editingProfile = useSelector(editingProfileSelector);
	const editingAdvisory = useSelector(editingAdvisorySelector);
	const creatingAdvisory = useSelector(creatingAdvisorySelector);
	const advisoriesList = useSelector(advisoriesListSelector);
	const advisoriesLoaded = useSelector(advisoriesLoadedSelector);
	const accountsList = useSelector(accountsListSelector);
	const accountsLoaded = useSelector(accountsLoadedSelector);
	const drawerOpen = useSelector(drawerOpenSelector);
	const activeAdvisoriesList = useSelector(activeAdvisoriesListSelector);
	const activeAdvisoriesListLoaded = useSelector(activeAdvisoriesListLoadedSelector);
	const [advisoriesLoading, setAdvisoriesLoading] = useState(false);
	const [accountsLoading, setAccountsLoading] = useState(false);
	const [accountLoading, setAccountLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [activeAdvisoriesLoading, setActiveAdvisoriesLoading] = useState(false);
	const [accountSelectionModel, setAccountSelectionModel] = useState([]);
	const [selectedAccounts, setSelectedAccounts] = useState([]);
	const [selectedAdvisories, setSelectedAdvisories] = useState([]);
	const [advisorySelectionModel, setAdvisorySelectionModel] = useState([]);

	if (!currentUser?.is_admin) {
		//Prevent non-admins from accessing admin route
		history.push('/portal');
	}

	const styles = {
		main: {
			width: '100%',
			height: '100vh',
			paddingLeft: '20px',
			paddingRight: '20px',
			backgroundColor: 'white',
			overflow: 'hidden'
		},
		tableContainer: {
			height: accountId ? 'calc(100% - 298px)' : 'calc(100% - 81px)'
		},
		drawer: {
			'& .MuiDrawer-paper': {
				width: '660px'
			}
		},
		accountLoading: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		tabPanel: {
			height: 'calc(100% - 48px)'
		}
	};

	const handleClickEditProfile = () => {
		dispatch(setEditingProfile(true));
	};

	const handleAdvisoryRowClick = async ({ data, field }) => {
		if (field !== '__check__') {
			dispatch(openDrawerAndFetchAdvisory(data?.id));
		}
	};

	const handleAccountRowClick = async ({ data }) => {
		history.push(`/admin/${data?.id}`);
	};

	const handleCloseDrawer = () => {
		dispatch(closeAdvisoryDrawer());
	};

	const handleClickAllAccounts = () => {
		history.push('/admin');
	};

	const handleTabChange = (e, newVal) => {
		setActiveTab(newVal);
	};

	const handleAccountSelectionModelChange = selectionModel => {
		const selectedAccounts = accountsList.filter(a => selectionModel.includes(a.id));
		setSelectedAccounts(selectedAccounts);
		setAccountSelectionModel(selectionModel);
	};

	const handleAdvisorySelectionModelChange = selectionModel => {
		const advisoriesSelected = advisoriesList.filter(a => selectionModel.includes(a.id));
		setSelectedAdvisories(advisoriesSelected);
		setAdvisorySelectionModel(selectionModel);
	};

	useEffect(() => {
		//Clear account data when accountId in url changes
		dispatch(clearSelectedAccountData());
	}, [accountId, dispatch]);

	useEffect(() => {
		const handleFetchAccountDetails = async id => {
			setAccountLoading(true);
			const res = await dispatch(getAccountById(id));
			setAccountLoading(false);
			dispatch(setSelectedAccount(res));
			dispatch(setProfile(res));
			dispatch(setAdvisoriesLoaded(false));
		};

		if (accountId) {
			handleFetchAccountDetails(accountId);
		}
	}, [accountId, dispatch]);

	const fetchAdvisoriesList = useCallback(async () => {
		setAdvisoriesLoading(true);
		const res = await dispatch(getAdvisoriesListByAccountId(accountId));
		if (res) {
			dispatch(setAdvisoriesList(res));
			dispatch(setAdvisoriesLoaded(true));
			setAdvisoriesLoading(false);
		}
	}, [dispatch, accountId]);

	useEffect(() => {
		if (!advisoriesLoading && !advisoriesLoaded && accountId) {
			fetchAdvisoriesList();
		}
	}, [advisoriesLoading, advisoriesLoaded, accountId, fetchAdvisoriesList]);

	const fetchAccountsList = useCallback(async () => {
		setAccountsLoading(true);
		const res = await dispatch(getAllAccountsList());
		if (res) {
			dispatch(setAccountsList(res));
			dispatch(setAccountsLoaded(true));
			setAccountsLoading(false);
		}
	}, [dispatch]);

	useEffect(() => {
		if (!accountsLoading && !accountsLoaded) {
			fetchAccountsList();
		}
	}, [accountsLoading, accountsLoaded, fetchAccountsList]);

	useEffect(() => {
		const fetchActiveAdvisories = async () => {
			setActiveAdvisoriesLoading(true);
			const res = await dispatch(getActiveAdvisories());
			const activeAdvisoriesWithIds = res.map(a => ({ ...a, id: `${a.starts_at}-${a.name}` }));
			if (res) {
				dispatch(setActiveAdvisoriesList(activeAdvisoriesWithIds));
				dispatch(setActiveAdvisoriesListLoaded(true));
				setActiveAdvisoriesLoading(false);
			}
		};
		if (!activeAdvisoriesLoading && !activeAdvisoriesListLoaded) {
			fetchActiveAdvisories();
		}
	}, [activeAdvisoriesLoading, activeAdvisoriesListLoaded, dispatch]);

	return (
		<Box sx={styles.main}>
			<Header
				title='Aloft Geo Portal: Admin'
				button={
					accountId && (
						<ButtonOutlined
							fitContent
							onClick={handleClickAllAccounts}
							startIcon={<ArrowBackIcon />}
						>
							ALL ACCOUNTS
						</ButtonOutlined>
					)
				}
			/>
			{accountId && (
				<>
					<ErrorBoundary>
						{!editingProfile && (
							<ProfileView
								button={<ButtonContained onClick={handleClickEditProfile}>EDIT</ButtonContained>}
								profile={profile}
								loading={accountLoading}
							/>
						)}
						{editingProfile && <ProfileEdit profile={profile} admin />}
					</ErrorBoundary>
					<Box sx={styles.tableContainer}>
						<ErrorBoundary>
							<Table
								columns={advisoriesColumns}
								rows={advisoriesList}
								loading={advisoriesLoading}
								title='Advisories List'
								noDataMessage='No Advisories'
								onCellClick={handleAdvisoryRowClick}
								checkboxSelection
								onSelectionModelChange={handleAdvisorySelectionModelChange}
								selectionModel={advisorySelectionModel}
								actions={
									<AdvisoryTableActions
										admin
										selectedItems={selectedAdvisories}
										afterAction={() => {
											handleAdvisorySelectionModelChange([]);
											fetchAdvisoriesList();
										}}
									/>
								}
							/>
						</ErrorBoundary>
					</Box>
					<Drawer
						anchor='right'
						open={drawerOpen}
						elevation={16}
						BackdropProps={{ invisible: true }}
						sx={styles.drawer}
						onClose={handleCloseDrawer}
					>
						<ErrorBoundary>
							{drawerOpen && !editingAdvisory && !creatingAdvisory && <AdvisoryView admin />}
							{drawerOpen && (editingAdvisory || creatingAdvisory) && <AdvisoryCreateOrEdit />}
						</ErrorBoundary>
					</Drawer>
				</>
			)}
			{!accountId && (
				<>
					<Divider />
					<Tabs value={activeTab} onChange={handleTabChange}>
						<Tab label='Accounts' />
						<Tab label='Active Advisories' />
					</Tabs>
					{activeTab === 0 && (
						<Box sx={styles.tabPanel}>
							{!accountsLoading && (
								<Box sx={styles.tableContainer}>
									<Table
										columns={accountsColumns}
										rows={accountsList}
										loading={accountsLoading}
										noDataMessage='No Accounts'
										onCellClick={handleAccountRowClick}
										localStorageFilterModelKey={ADMIN_TABLE_FILTER}
										checkboxSelection
										onSelectionModelChange={handleAccountSelectionModelChange}
										selectionModel={accountSelectionModel}
										actions={
											<AccountTableActions
												selectedItems={selectedAccounts}
												afterAction={() => {
													handleAccountSelectionModelChange([]);
													fetchAccountsList();
												}}
											/>
										}
									/>
								</Box>
							)}
							{accountsLoading && (
								<Box sx={styles.accountLoading}>
									<CircularProgress />
								</Box>
							)}
						</Box>
					)}
					{activeTab === 1 && (
						<Box sx={styles.tabPanel}>
							{!activeAdvisoriesLoading && (
								<Box sx={styles.tableContainer}>
									<Table
										columns={activeAdvisoriesColumns}
										rows={activeAdvisoriesList}
										loading={activeAdvisoriesLoading}
										noDataMessage='No Active Advisories'
									/>
								</Box>
							)}
							{activeAdvisoriesLoading && (
								<Box sx={styles.accountLoading}>
									<CircularProgress />
								</Box>
							)}
						</Box>
					)}
				</>
			)}
		</Box>
	);
};
export default AdminContainer;
