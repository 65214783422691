import axiosInstance from 'services/axiosConfig';
import { BASE_URL } from 'constants/environmentVariables';
import { handleApiResponse, handleApiException } from 'actions/api.actions';

export const updateAccount = (accountId, payload) => async dispatch => {
	try {
		const url = `${BASE_URL}/user/${accountId}`;
		const response = await axiosInstance.put(url, payload);
		return handleApiResponse({ response, dispatch, showSuccessMessage: true });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const getAllAccountsList = () => async dispatch => {
	try {
		const url = `${BASE_URL}/user`;
		const response = await axiosInstance.get(url);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};

export const getAccountById = accountId => async dispatch => {
	try {
		const url = `${BASE_URL}/user/${accountId}`;
		const response = await axiosInstance.get(url);
		return handleApiResponse({ response, dispatch });
	} catch (error) {
		handleApiException({ error, dispatch, errorMessage: true });
	}
};
